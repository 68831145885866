// 触发更新请求操作
const state = {
    addExperimentTrigger: false,
    addNoteBookTrigger: false,

    changePlatformTigger: false,    // 触发更换平台事件

    newNoteBookId: '',
}

const mutations = {
    TOGGLE_ADD_EXPERIMENT(state) {
        state.addExperimentTrigger = !state.addExperimentTrigger;
    },
    TOGGLE_ADD_NOTEBOOK(state) {
        state.addNoteBookTrigger = !state.addNoteBookTrigger;
    },
    
    TOGGLE_CHANGE_PLATFORM(state) {
        state.changePlatformTigger = !state.changePlatformTigger;
    },

    SET_NEW_NOTEBOOK_ID(state, id) {
        state.newNoteBookId = id;
    }
}

const actions = {
    toggleAddExperiment({ commit }, id) {
        commit(' SET_NEW_NOTEBOOK_ID', id);
        commit('TOGGLE_ADD_EXPERIMENT');
    },
    toggleAddNoteBook({ commit }) {
        commit('TOGGLE_ADD_NOTEBOOK')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}