/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group': {
    width: 33.333,
    height: 33.333,
    viewBox: '0 0 33.333 33.333',
    data: '<clipPath id="svgicon_group_a"><rect pid="0" width="33.333" height="33.333" rx="0"/></clipPath><g _fill="#FFF" clip-path="url(#svgicon_group_a)"><path pid="1" d="M20.65 15.817a5.732 5.732 0 001.738-4.577c-.21-2.82-2.496-5.097-5.316-5.295a5.751 5.751 0 00-4.437 9.85c.503.492.372 1.337-.272 1.62a10.653 10.653 0 00-6.365 9.753c0 .58.47 1.05 1.05 1.05h19.209c.58 0 1.05-.47 1.05-1.05 0-4.358-2.617-8.101-6.364-9.751-.631-.28-.79-1.122-.293-1.6z"/><path pid="2" d="M10.262 15.778c-2.915-5.158.318-8.745 1.461-9.785a.212.212 0 00-.086-.361 5.135 5.135 0 00-1.797-.147c-2.453.23-4.428 2.222-4.632 4.677a5.135 5.135 0 002.825 5.033C3.887 16.222.812 19.965.812 24.43v.293c0 .357.288.645.645.645H3.57c.3 0 .56-.208.623-.501.942-4.344 4.077-7.159 5.917-8.42a.507.507 0 00.152-.669zm12.863 0c2.915-5.158-.318-8.745-1.462-9.785a.212.212 0 01.087-.361 5.135 5.135 0 011.797-.147c2.453.23 4.428 2.222 4.631 4.677a5.136 5.136 0 01-2.825 5.033c4.147 1.027 7.222 4.77 7.222 9.235v.293a.644.644 0 01-.645.645h-2.113c-.3 0-.56-.208-.624-.501-.941-4.344-4.076-7.159-5.916-8.42a.507.507 0 01-.152-.669z"/></g>'
  }
})
