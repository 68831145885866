import { Modal } from 'ant-design-vue';
import cloneDeep  from 'lodash/cloneDeep';
import Vue from 'vue';
const state = {
    // 被选择的input
    inputInstance: null,
    // input实例是否聚焦，聚焦的话才可以点击时间戳事件
    isInputFocus: false,
    // 被选择的input的text元素，在table中可能存在点击text元素后才显示出input
    textInstance: null,
    // input光标选择起始点
    selectionStart: 0,
    // input光标选择终止点
    selectionEnd: 0,
    // 被选择的富文本实例
    editor: null,
    // 被选择富文本光标的起始点
    editorSelectionIndex: 0,
    // 被选择富文本的长度
    editorSelectionLength: 0,

    // 文件下载的队列
    downloadFileList: {
        /* "experiment-document-table_1684822352160": {
            name: '实验文档',
            list: [
                {
                    fileName: 'S01E01.mp4',
                    fileSize: 273967232,
                    downloaded: 2568520,
                    status: 'downloading'
                }
            ]
        },
        "experiment-document-table_1684822352162": {
            name: '实验文档',
            list: [
                {
                    fileName: 'S01E01.mp4',
                    fileSize: 2739627,
                    downloaded: 256850,
                    status: 'ready'
                }
            ]
        } */
    }
}

const mutations = {
    // 设置input实例
    SET_INPUT_INSTANCE: (state, instance) => {
        state.inputInstance = instance;
    },
    // 设置input实例聚焦
    SET_INPUT_FOCUS: (state, isFocus) => {
        state.isInputFocus = isFocus;
    },
    // 设置text实例
    SET_TEXT_INSTANCE: (state, instance) => {
        state.textInstance = instance;
    },
    // 设置input光标起始点
    SET_SELECTION_START: (state, index) => {
        state.selectionStart = index;
    },
    // 设置input光标终止点
    SET_SELECTION_END: (state, index) => {
        state.selectionEnd = index;
    },
    // 设置富文本实例
    SET_EDITOR_INSTANCE: (state, instance) => {
        state.editor = instance;
    },
    // 设置富文本起始光标点
    SET_EDITOR_SELECTION_INDEX: (state, index) => {
        state.editorSelectionIndex = index;
    },
    // 设置富文本选择文本长度
    SET_EDITOR_SELECTION_LENGTH: (state, length) => {
        state.editorSelectionLength = length;
    },

    // 清除文件下载列表
    CLEAR_DOWNLOAD_FILE_LIST: (state) => {
        state.downloadFileList = {
        }
    },

    // 更新进度
    UPDATE_DOWNLOAD_FILE_DOWNLOADED: (state,[ key, index, downloaded ]) => {
        if(!state.downloadFileList[key]) {
            return 
        }
        //Vue.set(state.downloadFileList, `${key}.list.${index}.downloaded`,downloaded )
        //Vue.set(state.downloadFileList[key].list[index], 'downloaded',downloaded )
        const diff = downloaded - state.downloadFileList[key].list[index].downloaded;
        state.downloadFileList[key].list[index].downloadRate = diff;
        state.downloadFileList[key].list[index].downloaded = downloaded;
        const allLeft = state.downloadFileList[key].list[index].fileSize - downloaded;
        state.downloadFileList[key].list[index].secondsLeft = Math.ceil(allLeft / diff);
        if(state.downloadFileList[key].list[index].status === 'ready') {
            state.downloadFileList[key].list[index].status = 'downloading';
            //Vue.set(state.downloadFileList[key].list[index], 'status', 'downloading')
            //Vue.set(state.downloadFileList, `${key}.list.${index}.status`,downloaded )
        }
        state.downloadFileList = cloneDeep(state.downloadFileList)
       // console.log(state.downloadFileList[key].list[index])
    },

    // 更新文件下载状态
    UPDATE_DOWNLOAD_FILE_STATUS: (state, [key, index, status]) => {
        if(!state.downloadFileList[key]) {
            return 
        }
        state.downloadFileList[key].list[index].status = status;
        if(status === 'error' || status === 'done') {
            state.downloadFileList[key].list[index].abortController = null;
        }
        state.downloadFileList = cloneDeep(state.downloadFileList)
    },

    // 更新固定key值的下载信息
    ADD_DOWNLOAD_COMPOENTS: (state, [ key, componentName, list]) => {
        
        state.downloadFileList[key] = {
            name: componentName,
            list: [...list]
        }
        return true;
    }
}

const actions = {
    // 设置input 实例
    setInput({ commit }, inputInstance) {
        commit('SET_INPUT_INSTANCE', inputInstance);
        commit('SET_EDITOR_INSTANCE', null);
        commit('SET_INPUT_FOCUS', true);
    },
    // 设置text 实例
    setText({ commit }, textInstance) {
        commit('SET_TEXT_INSTANCE', textInstance);
    },
    // 失焦
    lostFocus({ commit }) {
        commit('SET_INPUT_FOCUS', false);
    },
    // 设置光标起始点
    setSelectionStart({ commit }, index) {
        commit('SET_SELECTION_START', index)
    },
    // 设置光标终止点
    setSelectionEnd({ commit }, index) {
        commit('SET_SELECTION_END', index);
    },
    // 设置富文本实例
    setEditor({ commit }, instance) {
        commit('SET_EDITOR_INSTANCE', instance);
        commit('SET_INPUT_INSTANCE', null);
        commit('SET_INPUT_FOCUS', true);
    },
    // 设置富文本光标位置
    setEditorSelectionIndex({ commit }, index) {
        commit('SET_EDITOR_SELECTION_INDEX', index);
    },
    // 设置富文本选择长度
    setEditorSelectionLength({ commit }, length) {
        commit('SET_EDITOR_SELECTION_LENGTH', length);
    },

    updateProgress({ commit }, [ key, index, downloaded ]) {
        commit('UPDATE_DOWNLOAD_FILE_DOWNLOADED', [ key, index, downloaded ])
    },

    addDownloadFileList({ commit, state }, [ key, componentName, list]) {
        if(state.downloadFileList[key] && state.downloadFileList[key].list.length > 0) {
            const allStatus = state.downloadFileList[key].list.map(item => item.status);
            if(allStatus.indexOf('ready') !== -1 || allStatus.indexOf('downloading') !== -1) {
                Modal.warning({
                    content:'当前组件扔有下载任务未完成。'
                })
                return false;
            }
        }
        commit('ADD_DOWNLOAD_COMPOENTS', [key, componentName, list]);
        return true;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}