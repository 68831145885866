<template>
  <a-config-provider :locale="locale">
    <div class="form-designer-container-9136076486841527">
      <k-header v-if="showHead" :title="title" />
      <!-- 操作区域 start -->
      <operatingArea v-if="toolbarsTop" :showToolbarsText="showToolbarsText" :toolbars="toolbars"
        @handleSave="handleSave" @handlePreview="handlePreview" @handleOpenImportJsonModal="handleOpenImportJsonModal"
        @handleOpenCodeModal="handleOpenCodeModal" @handleOpenJsonModal="handleOpenJsonModal" @handleReset="handleReset"
        @handleClose="handleClose" @handleUndo="handleUndo" @handleRedo="handleRedo" :recordList="recordList"
        :redoList="redoList">
        <template slot="left-action">
          <slot name="left-action"></slot>
        </template>

        <template slot="right-action">
          <slot name="right-action"></slot>
        </template>
      </operatingArea>
      <!-- 操作区域 end -->
      <div class="content" :class="{
        'show-head': showHead,
        'toolbars-top': toolbarsTop,
        'show-head-and-toolbars-top': toolbarsTop && showHead
      }">
        <!-- 左侧控件区域 start -->
        <aside v-if="mode === 'edit'" :class="['left', isLeftAsideCollapsed ? 'left-collapsed' : 'left-opened']">
          <a-collapse @change="collapseChange" :defaultActiveKey="collapseDefaultActiveKey">
            <!-- 基础控件 start -->
            <!-- <a-collapse-panel
              v-if="basicsArray.length > 0"
              header="基础控件"
              key="1"
            >
              <collapseItem
                :list="basicsArray"
                @generateKey="generateKey"
                @handleListPush="handleListPush"
                @start="handleStart"
              />
            </a-collapse-panel> -->
            <!-- 基础控件 end -->
            <!-- 自定义控件 start -->
            <a-collapse-panel v-if="customComponents.list.length > 0" :header="customComponents.title" key="3">
              <collapseItem :list="customComponents.list" @generateKey="generateKey" @handleListPush="handleListPush"
                @start="handleStart" type="custom"/>
            </a-collapse-panel>
            <!-- 自定义控件 end -->

            <!-- 字段控件 start -->
            <a-collapse-panel v-if="textComponents.list.length > 0" :header="textComponents.title" key="4">
              <collapseItem :list="textComponents.list" @generateKey="generateKey" @handleListPush="handleListPush"
                @start="handleStart" type="text"/>
            </a-collapse-panel>
            <!-- 字段控件 end -->

            <!-- 布局控件 start -->
            <a-collapse-panel v-if="layoutArray.length > 0" header="布局控件" key="5">
              <collapseItem :list="layoutArray" @generateKey="generateKey" @handleListPush="handleListPush"
                @start="handleStart" type="grid"/>
            </a-collapse-panel>
            <!-- 布局控件 end -->
          </a-collapse>
        </aside>
        <!-- 左侧控件区域 end -->

        <!-- 收起或展开条 start-->
        <a-tooltip v-if="mode === 'edit'" arrow-point-at-center placement="right">
          <template slot="title">
            {{ isLeftAsideCollapsed ? '展开' : '收起' }}
          </template>
          <div class="collapse-bar" @click="toggoleCollapse">
            <div class="icon-container">
              <a-icon type="caret-left" class="collapse-icon" v-show="!isLeftAsideCollapsed" />
              <a-icon type="caret-right" class="collapse-icon" v-show="isLeftAsideCollapsed" />
            </div>
          </div>
        </a-tooltip>
        <!--收起或展开 end-->

        <!-- 中间面板区域 start -->
        <section>

          <body style="width: 100%;">
            <!-- 操作区域 start>
          <operatingArea
            v-if="!toolbarsTop"
            :showToolbarsText="showToolbarsText"
            :toolbars="toolbars"
            @handleSave="handleSave"
            @handlePreview="handlePreview"
            @handleOpenImportJsonModal="handleOpenImportJsonModal"
            @handleOpenCodeModal="handleOpenCodeModal"
            @handleOpenJsonModal="handleOpenJsonModal"
            @handleReset="handleReset"
            @handleClose="handleClose"
            @handleUndo="handleUndo"
            @handleRedo="handleRedo"
            :recordList="recordList"
            :redoList="redoList"
          >
            <template slot="left-action">
              <slot name="left-action"></slot>
            </template>

            <template slot="right-action">
              <slot name="right-action"></slot>
            </template>
          </operatingArea>
          <操作区域 end -->
            <k-form-component-panel :class="{ 'no-toolbars-top': !toolbarsTop }" :data="data" :selectItem="selectItem"
              :noModel="noModel" :hideModel="hideModel" :startType="startType" ref="KFCP"
              @handleSetSelectItem="handleSetSelectItem" @handleCommentClick="handleCommentClick" :mode="mode"
              :type="type" @deleteItem="handleDeleteItem" />
            <!-- 操作区域 start -->
            <k-json-modal ref="jsonModal" />
            <k-code-modal ref="codeModal" />
            <importJsonModal ref="importJsonModal" />
            <previewModal ref="previewModal" />
          </body>
        </section>
        <!-- 中间面板区域 end -->

        <!-- 右侧控件属性区域 start -->
        <aside class="right" style="min-width: 278px;">
          <a-tabs :activeKey="activeKey" @change="changeTab" :tabBarStyle="{ margin: 0 }">
            <!-- <a-tab-pane :key="1" tab="表单属性设置">
              <formProperties
                :config="data.config"
                :previewOptions="previewOptions"
              />
            </a-tab-pane>
            <a-tab-pane :key="2" tab="控件属性设置">
              <formItemProperties
                class="form-item-properties"
                :selectItem="selectItem"
                :hideModel="hideModel"
              />
            </a-tab-pane> -->
            <a-tab-pane :key="1">
              <template slot="tab">
                <a-tooltip arrow-point-at-center placement="bottom">
                  <template slot="title">
                    目录
                  </template>
                  <div class="tab-ctn">
                    <a-icon class="tab-icon" type="ordered-list" />
                  </div>
                </a-tooltip>
              </template>
              <draggable-list :mode="mode" :data="data" :selectItem="selectItem"
                @handleSetSelectItem="handleSetSelectItem" @handleListLocated="handleListLocated"></draggable-list>
            </a-tab-pane>
            <a-tab-pane v-if="type === 'experiment'" :key="2">
              <template slot="tab">
                <a-tooltip arrow-point-at-center placement="bottom">
                  <template slot="title">
                    实验设置
                  </template>
                  <div class="tab-ctn">
                    <a-icon class="tab-icon" type="highlight" />
                  </div>
                </a-tooltip>
              </template>
              <div class="exp-setting-ctn">
                <a-form-model ref="experimentForm" :model="experimentForm" size="small" layout="vertical">
                  <a-form-model-item label="实验编号">
                    <a-input :readOnly="mode !== 'edit'" :value="experimentForm.experimentNo" :disabled="true">
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item label="实验名称" prop="title"
                    :rules="[{ required: true, message: '请输入实验名称', whitespace: true },{ max: 30,  message: '不能超过30个字符' }]">
                    <a-input :readOnly="mode !== 'edit'" v-model="experimentForm.title"></a-input>
                  </a-form-model-item>
                  <a-form-model-item label="关键词" :rules="[{ max: 30,  message: '不能超过30个字符' }]" prop="keyWord">
                    <a-input :placeholder="mode === 'edit' ? `不超过30个字` : null" :readOnly="mode !== 'edit'"
                      v-model="experimentForm.keyWord" :maxLength="30">
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item label="室温(℃)" prop="roomTemperature" :rules="[{ max: 10,transform: (val) => val? val.toString() : '',  message: '长度不能超过10个字符', trigger:'change' }]">
                    <a-input-number v-show="mode === 'edit'" :placeholder="mode === 'edit' ? '请输入' : null"
                      style="width: 100%;" v-model="experimentForm.roomTemperature" allowClear></a-input-number>
                    <a-input :value="experimentForm.roomTemperature" :readOnly="mode !== 'edit'"
                      v-show="mode !== 'edit'"></a-input>
                  </a-form-model-item>
                  <a-form-model-item label="相对湿度(%)" prop="relativeHumidity" :rules="[{ max: 10, transform: (val) => val ? val.toString() : '', message: '长度不能超过10个字符', trigger:'change' }]">
                    <a-input-number v-show="mode === 'edit'" :placeholder="mode === 'edit' ? '请输入' : null"
                      style="width: 100%;" v-model="experimentForm.relativeHumidity" allowClear @change="() => {  }">
                    </a-input-number>

                    <a-input :readOnly="mode !== 'edit'" :value="experimentForm.roomTemperature"
                      v-show="mode !== 'edit'"></a-input>
                  </a-form-model-item>
                  <a-form-model-item label="结论">
                    <a-select :placeholder="mode === 'edit' ? '请输入' : null" v-model="experimentForm.conclusion"
                      :disabled="mode !== 'edit'" :readOnly="mode !== 'edit'" placehodler="请选择" allowClear>
                      <a-select-option
                        v-for="(item, index) in [{ label: '成功', value: 1 }, { label: '失败', value: 2 }, { label: '停止', value: 3 }]"
                        :key="index" :value="item.value">
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
            <a-tab-pane v-if="type === 'experiment' && !isVersionMode" :key="3">
              <template slot="tab">
                <a-tooltip arrow-point-at-center placement="bottom" @click="handleCommentTabClick">
                  <template slot="title">
                    评论
                  </template>
                  <div class="tab-ctn">
                    <a-icon class="tab-icon" type="message" />
                  </div>
                </a-tooltip>
              </template>
              <a-spin :spinning="isCommentLoading">
                <div class="comments-ctn" ref="commentCtn">
                  <a-form-model ref="commentFormRef" style="width: 95%; margin: 0 auto; margin-top: 16px;"
                    :model="commentForm" size="small" :colon="false" hideRequiredMark layout="vertical">
                    <a-form-model-item label="模块选择" prop="key" :rules="[{ required: true, message: '请选择' }]">
                      <a-select v-model="commentForm.key" placeholder="请选择" @change="handleMoudleChange">
                        <a-select-option v-for="(item, index) in data.list" :key="item.key">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="isCommentable" label="评论" prop="commentContent"
                      :rules="[{ required: true, validator: commentValidator }]">
                      <a-textarea :autoSize="{ minRows: 4 }" v-model="commentForm.commentContent" :maxLength="300"
                        placeholder="最多300字" />
                    </a-form-model-item>
                  </a-form-model>
                  <a-button type="primary" v-if="isCommentable" class="submit-btn" style="width: 95%; margin: 0 auto;" block
                    @click="handleCommentButtonClick">发表</a-button>
                  <div class="comment-list">
                    <div class="comment-list-title">
                      全部评论
                    </div>
                    <div class="comment-list-content">
                      <a-collapse v-model="activeCommentKey">
                        <a-collapse-panel v-for="(item) in data.list" :key="item.key" :header="item.label">
                          <div v-show="getComments(item.key).length > 0" class="comments">
                            <div v-for="(comment, commentIndex) in getComments(item.key)"
                              style="padding-top: 10px; padding-bottom: 5px; border-top: 1px #eeeeee solid;">
                              <comment :id="id" :commentData="comment" :key="comment.id"
                                @onCommentChange="onCommentChange" :commentable="isCommentable">
                              </comment>
                            </div>
                          </div>
                          <a-empty v-show="getComments(item.key).length === 0"></a-empty>

                        </a-collapse-panel>
                      </a-collapse>
                    </div>
                  </div>
                </div>
              </a-spin>
            </a-tab-pane>
            <a-tab-pane v-if="mode === 'edit' && type === 'experiment' && experimentDetail.experimentReadWriteAuthorization" :key="4">
              <template slot="tab">
                <a-tooltip arrow-point-at-center placement="bottom">
                  <template slot="title">
                    授权
                  </template>
                  <div class="tab-ctn">
                    <a-icon class="tab-icon" type="audit" />
                  </div>
                </a-tooltip>
              </template>
              <a-spin :spinning="rightLoading" tip="正在加载中...">
                <div class="auth-ctn">
                  <div class="view-only">
                    <div class="view-only-title">
                      仅查看
                    </div>
                    <a-select mode="multiple" v-model="readUsers" style="width: 100%" placeholder="请选择"
                      @change="handleReadChange">
                      <a-select-option v-for="(item, index) in projectUsers.userlist" :key="item.userId"
                        :value="item.userId">
                        {{ item.userName }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div class="editable">
                    <div class="editable-title">
                      可编辑
                    </div>
                    <a-select mode="multiple" v-model="readAndWriters" style="width: 100%" placeholder="请选择"
                      @change="handelWriteChange">
                      <a-select-option v-for="(item, index) in projectUsers.userlist" :key="item.userId"
                        :value="item.userId">
                        {{ item.userName }}
                      </a-select-option>
                    </a-select>
                    <a-button block type="primary" style="margin-top: 24px;" :class="['confirm']"
                      :disabled="!isAuthorChange" @click="handleAuthorClick"> 确定</a-button>
                  </div>
                </div>
              </a-spin>
            </a-tab-pane>
            <a-tab-pane v-if="type === 'template'" :key="5">
              <template slot="tab">
                <a-tooltip arrow-point-at-center placement="bottom">
                  <template slot="title">
                    实验模板设置
                  </template>
                  <div class="tab-ctn">
                    <a-icon class="tab-icon" type="highlight" />
                  </div>
                </a-tooltip>
              </template>
              <a-form-model ref="experimentTemplateForm" :model="experimentTemplateForm"
                style="width: 95%;margin: 0 auto;margin-top: 16px;" :colon="true" layout="vertical" :rules="rules">
                <a-form-model-item label="模板名称" prop="name">
                  <a-input :readOnly="mode !== 'edit'" v-model="experimentTemplateForm.name" placeholder="请输入" >
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="实验类型" prop="experimentType">
                  <a-select :disabled="mode !== 'edit'" placeholder="请选择实验类型"
                    v-model="experimentTemplateForm.experimentType" allowClear style="width: 100%;"
                    show-search
                    :filterOption="experimentTypeFilter">
                    <a-select-option v-for="(item, index) in dataList" :value="item.value" :key="index">
                      {{ item.text }}
                    </a-select-option>
                  </a-select>
                 <!--  <div v-if="mode != 'preview'" style="margin-top: 6px;">
                    <span style="font-size: 14px; color: rgba(0, 0, 0, 0.65);"> 找不到实验类型？</span>
                    <a-button type="link" size="small" @click="onAddExperimentTypeClick"> 点击添加</a-button>
                  </div> -->
                </a-form-model-item>
                <a-form-model-item label="行业领域" prop="industrySector">
                  <a-cascader :disabled="mode !== 'edit'" allowClear v-model="experimentTemplateForm.industrySector"
                    :field-names="{ label: 'name', value: 'id', children: 'children' }" :options="industrySectorList"
                    placeholder="请选择行业领域" style="width: 100%;" />
                </a-form-model-item>
                <a-form-model-item label="描述" prop="description">
                  <a-textarea :readOnly="mode !== 'edit'" :autoSize="{ minRows: 4 }"
                    v-model="experimentTemplateForm.description" placeholder="请输入">
                  </a-textarea>
                </a-form-model-item>
              </a-form-model>
            </a-tab-pane>
          </a-tabs>
        </aside>
        <!-- 右侧控件属性区域 end -->
      </div>
    </div>
  </a-config-provider>
</template>

<script>
/*
 * author kcz
 * date 2019-11-20
 * description 表单设计器
 */
import kHeader from "./module/header";
import operatingArea from "./module/operatingArea";

// import kFooter from "./module/footer";
import kFormComponentPanel from "./module/formComponentPanel";
import kJsonModal from "./module/jsonModal";
import kCodeModal from "./module/codeModal";
import collapseItem from "./module/collapseItem";
import importJsonModal from "./module/importJsonModal";
import previewModal from "../KFormPreview/index.vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import getPDF from '../core/htmlToPdf';
import outputPdf from '../core/testHtml';
import { cloneExperiment } from '../core/clone';
import { Revoke } from "../core/revoke";
import {
  basicsList,
  layoutList,
  customComponents,
  textComponents
} from "./config/formItemsConfig";
//import formItemProperties from "./module/formItemProperties";
//import formProperties from "./module/formProperties";
import draggableList from "./module/draggableList/draggableList.vue";
import comment from "./module/comment/comment.vue";
import { message } from "ant-design-vue";
import { addComment, getExperimentComment } from "../../../../api/experiment";
import PDFPanel from "../../../PDFPanel.vue";

export default {
  name: "KFormDesign",
  props: {
    title: {
      type: String,
      default: "电子试验记录"
    },
    showHead: {
      type: Boolean,
      default: false
    },
    hideResetHint: {
      type: Boolean,
      default: false
    },
    toolbarsTop: {
      type: Boolean,
      default: false
    },
    toolbars: {
      type: Array,
      default: () => [
        "save",
        "preview",
        "importJson",
        "exportJson",
        "exportCode",
        "reset",
        "close",
        "undo",
        "redo"
      ]
    },
    showToolbarsText: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => [
        "input",
        "textarea",
        "number",
        "select",
        "checkbox",
        "radio",
        "date",
        "time",
        "rate",
        "slider",
        "uploadFile",
        "uploadImg",
        "cascader",
        "treeSelect",
        "batch",
        "selectInputList",
        "editor",
        "switch",
        "button",
        "alert",
        "text",
        "html",
        "divider",
        "card",
        "tabs",
        "grid",
        "table"
      ]
    },
    hideModel: {
      // 隐藏数据字段
      type: Boolean,
      default: true
    },
    commentData: {
      type: Array,
      default: () => []
    },
    // 模式
    mode: {
      type: String,
      default: 'edit'
    },

    projectUsers: {
      type: Object,
      default: () => {
        return {
          // 只读用户
          readlist: [],
          // 全部用户
          userlist: [],
          // 可编辑用户
          writelist: []
        }
      }
    },
    id: {
      type: String | null | undefined,
      required: true
    },
    type: {
      type: String,
      default: 'experiment'
    },
    industrySectorList: {
      type: Array,
      default: () => []
    },
    dataList: {
      type: Array,
      default: () => []
    },
    rightLoading: {
      type: Boolean,
      default: false
    },
    experimentDetail: {
      type: Object,
      default: () => { }
    }
    // 实验数据
    /* experimentForm: {
      type: Object,
      default: () => {
        return {
          title: '',
          keyWord: '',
          roomTemperature: '',
          relativeHumidity: '',
          conclusion: ''
        }
      }
    } */
  },
  data() {
    return {
      locale: zhCN,
      customComponents,
      textComponents,
      layoutList,
      activeKey: 1,
      updateTime: 0,
      updateRecordTime: 0,
      startType: "",
      revoke: null,
      recordList: [],
      redoList: [],
      noModel: [
        "button",
        "divider",
        "card",
        "grid",
        "tabs",
        "table",
        "alert",
        "text",
        "html"
      ],
      isLeftAsideCollapsed: false,
      data: {
        list: [],
        config: {
          //layout: "horizontal",
          layout: "vertical",
          labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
          labelWidth: 100,
          labelLayout: "flex",
          wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
          hideRequiredMark: false,
          customStyle: ""
        }
      },
      // PDF数据
      pdfData: {
        list: [],
        config: {
          //layout: "horizontal",
          layout: "vertical",
          labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
          labelWidth: 100,
          labelLayout: "flex",
          wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
          hideRequiredMark: false,
          customStyle: ""
        }
      },
      previewOptions: {
        width: 850
      },
      selectItem: {
        key: ""
      },
      // 评论折叠面板
      activeCommentKey: [],
      // 仅查看列表
      viewOnlyList: [],
      // 可编辑成员列表
      editableList: [],
      // 实验数据
      experimentForm: {
        title: '',
        keyWord: '',
        roomTemperature: '',
        relativeHumidity: '',
        conclusion: ''
      },
      // 只读用户
      readUsers: [],
      // 读写用户
      readAndWriters: [],
      // 评论模块选择
      selectedCommentMoudle: undefined,
      // 评论
      comment: '',
      // 评论数据
      commentForm: {
        key: undefined,
        commentContent: ''
      },
      // 实验模板数据
      experimentTemplateForm: {
        name: '',
        description: '',
        experimentType: undefined,
        industrySector: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入'
        }, {
          max: 50,
          trigger: 'change',
          message: '名称长度不能超过50字'
        }],
        experimentType: [{
          required: true,
          message: '请选择'
        }],
        industrySector: [{
          required: true,
          message: '请选择'
        }],
        description: [{
          max: 200,
          trigger: 'change',
          message: '描述不能超过200字'
        }]
      },
      isCommentLoading: false,  // 是否正在加载评论
      isCommentInit: false, // 是否已初始化评论
    };
  },
  components: {
    kHeader,
    // kFooter,
    operatingArea,
    collapseItem,
    kJsonModal,
    kCodeModal,
    importJsonModal,
    previewModal,
    kFormComponentPanel,
    draggableList,
    comment
    /* formItemProperties,
    formProperties */
    ,
    PDFPanel
  },
  watch: {
    data: {
      handler(e) {
        this.$nextTick(() => {
          const deepRes = cloneExperiment(e);
          //console.log(deepRes)
          this.revoke.push(deepRes);
          this.$emit('dataChange', deepRes);
        });
      },
      deep: true,
      immediate: true
    },
    recordList: {
      handler(e) {
        this.$emit('recordChange', e.length);
      },
      deep: true
    },
    redoList: {
      handler(e) {
        this.$emit('redoChange', e.length);
      }
    },
    experimentForm: {
      handler(e) {
        this.$emit('experimentFormChange', e);
      }
    },
    projectUsers: {
      handler(e) {
        this.readUsers = e.readlist.map(element => element.userId);
        this.readAndWriters = e.writelist.map(element => element.userId);
        //console.log(e)
      },
      deep: true
    }
  },
  computed: {
    basicsArray() {
      // 计算需要显示的基础字段
      return basicsList.filter(item => this.fields.includes(item.type));
    },
    layoutArray() {
      // 计算需要显示的布局字段
      return layoutList.filter(item => this.fields.includes(item.type));
    },
    collapseDefaultActiveKey() {
      // 计算当前展开的控件列表
      /* const defaultActiveKey = window.localStorage.getItem(
        "collapseDefaultActiveKey"
      );
      if (defaultActiveKey) {
        return defaultActiveKey.split(",");
      } else {
        return ["1"];
      } */
      return ['3', '4', '5']
    },
    // 计算权限是否发生变化
    isAuthorChange() {
     // console.log(this.readUsers)
      const oReadUsers = this.projectUsers.readlist.map(element => { return element.userId });
      const readUsers = JSON.parse(JSON.stringify(this.readUsers ? this.readUsers : []));
      const isReadSame = JSON.stringify(oReadUsers.sort()) === JSON.stringify(readUsers.sort());
      const oEditors = this.projectUsers.writelist.map(element => { return element.userId });
      const readAndWriters = [].concat(this.readAndWriters ? this.readAndWriters : []);
      const isWriteSame = oEditors && JSON.stringify(oEditors.sort()) === JSON.stringify(readAndWriters.sort());
      return !(isReadSame && isWriteSame)
    },
    // 是否可以评论
    isCommentable() {
      return this.id && !this.$route.query.state;
    },
    // 是否是版本模式
    isVersionMode() {
      return this.$route.query.experimentVersionId !== undefined
    }
  },
  methods: {
    generateKey(list, index) {
      // 生成key值
      const key = list[index].type + "_" + new Date().getTime();
      this.$set(list, index, {
        ...list[index],
        key,
        model: key
      });
      if (this.noModel.includes(list[index].type)) {
        // 删除不需要的model属性
        delete list[index].model;
      }
    },
    handleListPush(item) {
      // 双击控件按钮push到list
      // 生成key值
      if (!this.selectItem.key) {
        // 在没有选择表单时，将数据push到this.data.list
        const key = item.type + "_" + new Date().getTime();
        item = {
          ...item,
          key,
          model: key
        };
        if (this.noModel.includes(item.type)) {
          // 删除不需要的model属性
          delete item.model;
        }
        const itemString = JSON.stringify(item);
        const record = JSON.parse(itemString);
        // 删除icon及compoent属性
        delete record.icon;
        delete record.component;
        this.data.list.push(record);
        this.handleSetSelectItem(record);
        const that = this;
        this.$nextTick(() => {
          
          if (this.mode === 'edit') {
            const formPanel = document.querySelector('.draggable-box');
            formPanel.scroll({
              top: document.querySelector('.list-main').childNodes[this.data.list.indexOf(this.selectItem)].offsetTop - 63,
              behavior: 'smooth'
            })
            //document.querySelectorAll('.drag-move')[this.data.list.indexOf(this.selectItem)].scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          else if (this.mode === 'preview') {
            this.selectItem;
            formPanel.scroll({
              top: document.querySelector('.preview-content').childNodes[this.data.list.indexOf(this.selectItem)].offsetTop - 63,
              behavior: 'smooth'
            })
          }
        })
        return false;
      }
      this.$refs.KFCP.handleCopy(false, item);
    },
    handleOpenJsonModal() {
      // 打开json预览模态框
      this.$refs.jsonModal.jsonData = this.data;
      this.$refs.jsonModal.visible = true;
    },
    handleOpenCodeModal() {
      // 打开代码预览模态框
      this.$refs.codeModal.jsonData = this.data;
      this.$refs.codeModal.visible = true;
    },
    handleOpenImportJsonModal() {
      // 打开json预览模态框
      this.$refs.importJsonModal.jsonData = this.data;
      this.$refs.importJsonModal.handleSetSelectItem = this.handleSetSelectItem;
      this.$refs.importJsonModal.visible = true;
    },
    handlePreview() {
      // 打开预览模态框
      this.$refs.previewModal.jsonData = this.data;
      this.$refs.previewModal.previewWidth = this.previewOptions.width;
      this.$refs.previewModal.visible = true;
    },
    handleReset() {
      // 清空
      if (this.hideResetHint) {
        // 不显示提示直接清空
        this.resetData();
        return;
      }

      this.$confirm({
        title: "警告",
        content: "是否确认清空内容?",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk: () => {
          this.resetData();
        }
      });
    },
    resetData() {
      this.data = {
        list: [],
        config: {
          layout: "horizontal",
          labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
          labelWidth: 100,
          labelLayout: "flex",
          wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
          hideRequiredMark: false,
          customStyle: ""
        }
      };
      this.handleSetSelectItem({ key: "" });
      this.$message.success("已清空");
    },
    handleSetSelectItem(record) {
      // 操作间隔不能低于100毫秒
      const newTime = new Date().getTime();
      if (newTime - this.updateTime < 100) {
        return false;
      }

      this.updateTime = newTime;

      // 设置selectItem的值
      this.selectItem = record;

      // 判断是否选中控件，如果选中则弹出属性面板，否则关闭属性面板
      if (record.key) {
        this.startType = record.type;
        //this.changeTab(2);
      } else {
        //this.changeTab(1);
      }
    },
    /**
     * @description: 切换属性设置面板
     * @param {*}
     * @return {*}
     */

    changeTab(e) {
      this.activeKey = e;
      if (e == 4) {
        this.$emit('getAuthor')
      }
      else if(e == 3) {
        this.initComment();
      }
    },
    // 当点击评论tab的时候
    handleCommentTabClick() {
      if (this.activeCommentKey.length > 0) {
        return
      }
      const keys = this.data.list.map(el => el.key);
      keys.forEach(commentKey => {
        let temp = this.getComments(commentKey);
        if (temp.length > 0) {
          this.activeCommentKey.push(commentKey);
        }
      });

    },
    /**
     * @Author: kcz
     * @description: 遍历json结构，获取所有字段
     * @param {*}
     * @return {*} Array
     */
    getFieldSchema() {
      const fields = [];
      const traverse = array => {
        array.forEach(element => {
          if (element.type === "grid" || element.type === "tabs") {
            // 栅格布局
            element.columns.forEach(item => {
              traverse(item.list);
            });
          } else if (element.type === "card") {
            // 卡片布局
            traverse(element.list);
          } else if (element.type === "batch") {
            // 动态表格内复制
            traverse(element.list);
          } else if (element.type === "table") {
            // 表格布局
            element.trs.forEach(item => {
              item.tds.forEach(val => {
                traverse(val.list);
              });
            });
          } else {
            if (element.model) {
              fields.push(element);
            }
          }
        });
      };
      traverse(this.data.list);
      return fields;
    },
    handleSetData(data) {
      // 用于父组件赋值
      try {
        if (typeof data !== "object") {
          return false;
        } else {
          this.data = data;
          // 导入json数据后，需要清除已选择key
          this.handleSetSelectItem({ key: "" });
          this.revoke = new Revoke();
          this.recordList = this.revoke.recordList;
          this.redoList = this.revoke.redoList;
        }
        return true;
      } catch (error) {
       // console.error(error);
        return false;
      }
    },
    collapseChange(val) {
      // 点击collapse时，保存当前collapse状态
      window.localStorage.setItem("collapseDefaultActiveKey", val);
    },
    handleStart(type) {
      this.startType = type;
    },

    /**
     * @description: 撤销
     * @param {*}
     * @return {*}
     */
    handleUndo() {
      const record = this.revoke.undo();
      if (!record) {
        return false;
      }
      this.data = record;

      this.handleSetSelectItem({ key: "" });
    },

    /**
     * @description: 重做
     * @param {*}
     * @return {*}
     */
    handleRedo() {
      const record = this.revoke.redo();
      if (!record) {
        return false;
      }
      this.data = record;
    },

     async handleSave(fileName) {
      this.pdfData = JSON.parse(JSON.stringify(this.data));
      try {
          await this.$nextTick( async () => {
          const PDF = document.querySelector('.pdf-panel');
          const header = document.querySelector('.pdf-header');
          const footer = document.querySelector('.pdf-footer');
          await outputPdf({ element: PDF, contentWidth: 592.28, contentHeight: 841.89, mode: 'my', filename: fileName, header: header, footer: footer, headerOnlyFirst: false, y: 20 });
          return Promise.reject(true);
        })
      } catch (error) {
        message.error(error)
        return Promise.reject(false);
      }
    },
    getValue() {
      // 获取数据
      return this.data;
    },
    handleClose() {
      this.$emit("close");
    },
    toggoleCollapse() {
      this.isLeftAsideCollapsed = !this.isLeftAsideCollapsed;
    },
    handleListLocated(item) {
      if (this.mode === 'edit') {
        const formPanel = document.querySelector('.draggable-box')
        this.selectItem = item;
        formPanel.scroll({
          top: document.querySelector('.list-main').childNodes[this.data.list.indexOf(this.selectItem)].offsetTop - 63,
          behavior: 'smooth'
        })
      }
      else if (this.mode === 'preview' || this.mode === 'pdf') {
        this.selectItem = item;
        const formPanel = document.querySelector('.preview-content');
        formPanel.scroll({
          top: document.querySelector('.preview-content').childNodes[this.data.list.indexOf(this.selectItem)].offsetTop - 63,
          behavior: 'smooth'
        })
      }
    },
    // 组件评论按钮点击事件回调
    handleCommentClick(item) {
      this.commentForm.key = item.key;
      this.activeCommentKey = [item.key];
      this.changeTab(3)
    },
    // 授权点击事件
    handleAuthorClick() {
      this.$emit('onAuthor', { readUsers: this.readUsers, readAndWriters: this.readAndWriters });
    },
    // 获取评论数据
    getComments(key) {
      const newComment = JSON.parse(JSON.stringify(this.commentData))
      const commentList = newComment.filter((element) => {
        return element.key === key;
      });
      if (commentList.length === 0) {
        return []
      }
      else {
        commentList.forEach(Element => {
          // 树结构 数组扁平化
          Element.children = this.transformTreeShapeCommentToArrayShape(Element);
        })
        return commentList;
      }
    },
    // 评论树状数据转换为二维数组
    transformTreeShapeCommentToArrayShape(comment) {
      // bfs遍历队列
      const BFSList = [];
      if (comment.children && comment.children.length) {
        BFSList.push(comment);
      }
      for (let i = 0; i < BFSList.length; ++i) {
        if (BFSList[i].children && BFSList[i].children.length) {
          BFSList.splice(BFSList.length, 0, ...BFSList[i].children);
        }
      }
      BFSList.splice(0, 1);
      return BFSList;
    },
    // 评论点击事件
    async handleCommentButtonClick() {
      this.$refs.commentFormRef.validate().then(async (valid) => {
        if (valid) {
          const res = await addComment({
            experimentId: this.id,
            ...this.commentForm
          });
          if (res.code === 200) {
            const activeId = res.data.id;
            this.commentData.splice(0, this.commentData.length, ...res.data.moduleCommentRecordOutputs)
            message.success(res.message);
            this.$nextTick(() => {
              const activeComment = document.querySelector(`.comment-${activeId}`);
              const commentPanel = document.querySelector('.ant-tabs-tabpane-active');
              commentPanel.scroll({
                top: activeComment.offsetTop - 63,
                behavior: 'smooth'
              })
              this.$nextTick(() => {
                //console.log(commentPanel.scrollTop)
              })
              
              activeComment.classList.add('blue-into-out');

            });
            this.commentForm.commentContent = '';
          }
        }
      })
    },
    // 评论校验器
    commentValidator(_rule, value, callback) {
      if (!value.toString().trim()) {
        callback(new Error('评论内容不能为空'));
      }
      else {
        callback();
      }
    },
    // 子级评论回调
    onCommentChange({ newCommentList, activeId, msg }) {
      this.commentData.splice(0, this.commentData.length, ...newCommentList);
      message.success(msg)
      this.$nextTick(() => {
        const activeComment = document.querySelector(`.comment-${activeId}`);
        const commentPanel = document.querySelector('.ant-tabs-tabpane-active');
        if(commentPanel.scrollTop + commentPanel.clientHeight < activeComment.offsetTop + activeComment.offsetHetight) {
          commentPanel.scroll({
            top: activeComment.offsetTop - 63,
            behavior: 'smooth'
          })
        }
        activeComment.classList.add('blue-into-out')
      });
    },
    // 评论模块点击选择回调
    handleMoudleChange(value) {
      this.activeCommentKey = [value];
    },
    // 授权 查看用户 改变事件
    handleReadChange(value) {
      value.forEach(element => {
        const index = this.readAndWriters.indexOf(element);
        if (index !== -1) {
          this.readAndWriters.splice(index, 1);
        }
      })
    },
    // 授权 可编辑用户 改变事件
    handelWriteChange(value) {
      value.forEach(element => {
        const index = this.readUsers.indexOf(element);
        if (index !== -1) {
          this.readUsers.splice(index, 1);
        }
      })
    },
    // 删除回调事件
    handleDeleteItem(key) {
      //console.log(key)
      if(this.commentForm.key === key) {
        this.commentForm.key = undefined
      }
    },
    // 实验类型搜索过滤
    experimentTypeFilter(input, option) {
      return option.componentOptions.children[0].text.indexOf(input.toString()) !== -1;
    },
    // 点击添加实验类型
    onAddExperimentTypeClick() {
      this.$emit('onAddExperimentTypeClick')
    },
    setExperimentType(id) {
      this.experimentTemplateForm.experimentType = id;
    },
    async initComment() {
      if(this.isCommentInit || this.isCommentLoading) {
        return ;
      }
      this.isCommentLoading = true;
      const res = await getExperimentComment({
        experimentId: this.id
      });
      if(res.success) {
        this.commentData.splice(0, this.commentData.length, ...res.data);
        this.isCommentInit = true;
      }
      this.isCommentLoading = false;
    }
  },
  created() {
    this.revoke = new Revoke();
    this.recordList = this.revoke.recordList;
    this.redoList = this.revoke.redoList;
  }
};
</script>

<style lang="less" scoped>
.left-collapsed {
  width: 0px !important;
  transform: translateX(-100%);
  overflow: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.left-opened {
  transform: translateX(0);
  opacity: 1;
  transition: all .3s ease-in-out;
  //min-width: 280px;
}

.collapse-bar {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 3px;
  background-color: #FFFFFF;
  cursor: pointer;

  .icon-container {
    position: relative;
    z-index: 100;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 45px;
      color: white;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f6F6F6;
      transform: perspective(.05em) rotateY(-2deg);
      z-index: 1;
    }

    .collapse-icon {
      position: absolute;
      z-index: 199;
      top: 17px;
      left: 5px;
    }
  }

  &:hover {
    .collapse-icon {
      opacity: 0.8;
    }
  }
}

/deep/ .ant-tabs-tab {
  padding: 1px 5px;
}

/deep/ .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0px;
}

/deep/ .ant-tabs-tab-active {
  .tab-ctn {
    background:  @srims-primary-color;
    color: #FFFFFF;
  }
}

/deep/ .ant-tabs-tab {
  margin: 0px;
  margin-top: 6px;
}

.tab-ctn {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 6px;
}

.tab-icon {
  font-size: 24px;
}

/deep/ .ant-tabs-ink-bar {
  display: none;
  width: 0px !important;
}

.pdf-panel {
  position: fixed; // 脱离文件流
  left: -1500px; // 移出可见区域
  width: 1300px; // 固定宽度
  padding: 1.5%;
  background: white;

  .pdf-inside-panel {}

  .pdf-title {
    text-align: center;
    padding: 5px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
</style>

<style lang="less" scoped>
// 实验设置样式
.exp-setting-ctn {
  padding: 24px 8px;
}

// 评论样式
.comments-ctn {
  padding: 0px;

  .make-comment {
    span {
      line-height: 22px;
    }

    .ant-select {
      width: 100%;
    }

    .make-comment-content {
      margin-top: 24px;

      .submit-btn {
        margin-top: 16px;
      }
    }
  }

  .comment-list {
    margin-top: 32px;

    .comment-list-title {
      padding-bottom: 8px;
      border-bottom: 1px solid #EBEBEB;
    }

    .comment-list-content {
      /deep/ .ant-collapse-header {
        padding: 8px;
        padding-left: 40px;
        background: white;
      }

      /deep/ .ant-collapse {
        border: none;
      }

      /deep/ .ant-collapse-content-box {
        padding: 8px 0px;
      }
    }
  }
}

// 授权
.auth-ctn {
  padding: 24px 8px;

  .editable {
    margin-top: 24px;
  }
}
</style>