<template>
    <a-modal :width="800" :visible.sync="visible" @cancel="handleCancel" :footer="null">
        <template slot="title">
            <a-tooltip title="全屏预览">
                <svg-icon name="fullscreen" class="operatin-icon" @click="haneldToggleFullScreen"></svg-icon>
            </a-tooltip>
            {{ fileName }}
        </template>
        <a-spin :spinning="!fileUrl || (fileType === 'xlsx' && !isDesinerLoad)" :delay="300" tip="正在加载中...">
            <div class="preview-content" ref="contentRef">
                <img style="max-width: 100%;max-height: 100vh;" v-if="fileType === 'image' && fileUrl" :src="fileUrl" />
                <div v-show="fileType === 'xlsx' && isDesinerLoad && !loading" ref="workbookRef" style="height:500px;width: 100%; "></div>
                <div ref="excelRef" v-show="fileType === 'xlsx' && isDesinerLoad && !loading" style="height:500px;width: 100%; "></div>
                <video v-if="fileUrl && fileType === 'mp4'" :src="fileUrl" ref="video" controls style="width: 100%;" />
                <audio v-if="fileUrl && fileType === 'mp3'" controls>
                    <source :src="fileUrl" type="audio/mpeg">
                </audio>
                <iframe v-if="fileUrl && fileType === 'txt'" :src="fileUrl" width="100%" :height="isFullscreen ? `100%` : `500px`">
                    <head>
                        <meta http-equiv="Content-Type" content="text/html; charset=gbk" />
                    </head>
                </iframe>
                <iframe v-if="fileUrl && fileType === 'pdf'" :src="fileUrl" width="100%" :height="isFullscreen ? `100%` : `500px`">
                </iframe>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { watchOnce } from '@vueuse/core';
import { message } from 'ant-design-vue';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue-demi';
import { isDesinerLoad, loadSpreadJS } from '../../../spreadjs';
import { toggleFullScreen, getElIsFullScreen } from '../../../../../utils/fullSrceen';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        fileName: {
            type: String,
            default: ''
        },
        fileType: {
            type: String,
            default: null
        },
        fileUrl: {
            type: String | null | File,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const contentRef = ref(null);
        const haneldToggleFullScreen = async () => {
            toggleFullScreen(contentRef.value);
        }
        // 视频播放元素
        const video = ref(null)

        // 设计器
        const designer = ref(null);
        // 工作簿
        const workbook = ref(null);
        // 表格元素实例
        const excelRef = ref(null);
        // 工作本元素实例
        const workbookRef = ref(null);
        // 是否正在加载excel数据
        const loading = ref(false);

        watch(() => props.fileUrl, async (newVal) => {
            if (newVal) {
                nextTick(() => {
                    if (props.fileType === 'xlsx') {
                        if (!isDesinerLoad.value) {
                            loadSpreadJS();
                            // 仅观察一次
                            watchOnce(() => isDesinerLoad.value, (newVal) => {
                                if (newVal) {
                                    loadExcel();
                                }
                            })
                        }
                        else {
                            loadExcel();
                        }
                    }
                    if(props.fileType === 'mp4') {
                        //console.log(this.fileUrl);
                    }
                })
            }
        }, { deep: true, immediate: true })
        const isFullscreen = computed(() => {
            return contentRef.value && getElIsFullScreen(contentRef.value);
        })
        // 监听是否全屏
        watch(() => isFullscreen.value, (newval) => {
            if (props.fileType === 'xlsx') {
                if (newval) {
                    excelRef.value.style['height'] = `calc(100vh - 90px)`;
                    workbookRef.value.style['height'] = `calc(100vh - 90px)`;
                    designer.value?.refresh()
                }
                else {
                    excelRef.value.style['height'] = `500px`;
                    workbookRef.value.style['height'] = `500px`;
                    designer.value?.refresh()
                }
            }
        })

        // 加载excel
        const loadExcel = () => {
            loading.value = true;
            if (!designer.value && !workbook.value) {
                if (isDesinerLoad.value && !designer.value) {
                    workbook.value = new GC.Spread.Sheets.Workbook(workbookRef.value);
                    let config = GC.Spread.Sheets.Designer.DefaultConfig;
                    designer.value = new GC.Spread.Sheets.Designer.Designer(excelRef.value, config, workbook.value);
                    const excelIO = new GC.Spread.Excel.IO();
                    excelIO.open(props.fileUrl, (json) => {
                        workbook.value.fromJSON(json);
                        workbook.value.sheets.forEach(sheet => {
                            sheet.options.isProtected = true;
                        })
                        designer.value.bind(GC.Spread.Sheets.Designer.Events.FileLoading, (event, data, cancel) => {
                            GC.Spread.Sheets.Designer.showMessageBox("仅为预览/该模式下不支持导入", "提示", GC.Spread.Sheets.Designer.MessageBoxIcon.warning);
                            data.cancel = true;
                        });
                        designer.value.refresh();
                        loading.value = false;
                    }, (error) => {
                        message.error(error)
                    })
                }
            }
        }

        // 关闭前销毁
        onBeforeUnmount(() => {
            designer.value && designer.value.destroy();
            workbook.value && workbook.value.destroy();
        })
        return {
            handleCancel,
            contentRef,
            haneldToggleFullScreen,
            designer,
            workbook,
            excelRef,
            workbookRef,
            isDesinerLoad,
            loading,
            video,
            isFullscreen
        }
    }
}
</script>

<style lang="less" scoped>
.preview-content {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.operatin-icon {
    cursor: pointer;
    margin-right: 5px;

}
</style>