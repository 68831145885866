/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-audit': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M466.963 800.723H271.398c-26.572 0-48.128-21.549-48.128-48.128V271.398c0-26.579 21.55-48.121 48.128-48.121h481.204c26.585 0 48.128 21.542 48.128 48.121v195.77L896 562.445V256c0-70.694-57.306-128-128-128H256c-70.694 0-128 57.306-128 128v512c0 70.694 57.306 128 128 128h306.24l-95.277-95.277zm417.587 28.103L772.14 715.802c-1.318-1.312-2.758-2.292-4.134-3.392 18.976-29.28 30.074-64.167 30.074-101.748 0-103.244-83.258-186.924-186.003-186.924-102.707 0-186.003 83.68-186.003 186.924 0 103.27 83.302 186.919 186.003 186.919 37.376 0 72.134-11.155 101.318-30.266 1.063 1.421 2.042 2.829 3.335 4.135l112.435 113.043c7.673 7.686 17.67 11.513 27.699 11.513 10.003 0 20.02-3.846 27.68-11.5 15.27-15.405 15.27-40.308.006-55.68m-272.467-90.279c-70.15 0-127.264-57.389-127.264-127.91 0-70.509 57.114-127.904 127.264-127.904 70.163 0 127.264 57.395 127.264 127.904-.006 70.528-57.1 127.91-127.264 127.91"/>'
  }
})
