<template>
<div>
    <a-modal :width="800" :visible="visible" title="插入模板" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
        <div class="search-ctn">
            <a-input style="width: 220px;" v-model="searchValue" placeholder="请输入模板名称/备注"></a-input>
            <a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
            <a-button class="reset-btn" @click="handleReset"> 重置 </a-button>
        </div>
        <a-table rowKey="customTemplateContentId" :loading="loading" size="small" :columns="columns" :data-source="tableData" :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps, type: selectType }">
            <template slot="action"  slot-scope="text, record, index">
                <a-button type="link" size="small" @click="handlePreviewClick(record)"> 预览 </a-button>
            </template>
        </a-table>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="total"
                show-less-items />
        </div>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button :disabled="isGettingContent" :loading="isGettingContent" class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button :disabled="selectedRowKeys.length == 0 || isGettingContent" :loading="isGettingContent" type="primary" class="confirm-btn" @click="handleConfirm">
                    确定插入
                </a-button>
            </div>
        </template>
    </a-modal>
    <a-modal :width="1200" v-model="previewModalVisible" :title="previewRecord.templateName" :footer="null">
        <a-spin :spinning="templateContent == null" tip="正在加载">
            <div style="min-height: 200px;">
                <div v-html="templateContent">

                </div>
            </div>
        </a-spin>
    </a-modal>
</div>
</template>

<style lang="less" scoped>
.search-ctn {
    margin-bottom: 24px;

    .search-btn {
        margin-left: 24px;
    }

    .reset-btn {
        background: #eeeeee;
        margin-left: 16px;
    }
}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

/deep/ .ant-table-thead {
    background: #F0F5FF;
}

/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px;
}

.default-pagination {
    text-align: center;
    margin-top: 24px;
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';
import { getCustomTemplateContentPage, getCustomTemplateContentDetail } from '../../../../../api/experiment';
const columns = [
    {
        dataIndex: 'templateName',
        title: '模板名称',
        width: '30%',
        scopedSlots: { customRender: 'templateName' }
    },
    {
        dataIndex: 'remark',
        title: '备注',
        width: '20%',
        scopedSlots: { customRender: 'remark' }
    },
    {
        dataIndex: 'createdTime',
        title: '创建时间',
        width: '20%',
        scopedSlots: { customRender: 'createdTime' }
    },
    {
        title: '操作',
        scopedSlots: { customRender: 'action' }
    }
]
const eqiupmentList = [

]
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        // 不能选中列表
        disabledList: {
            type: Array,
            default: () => []
        },
        selectType:{
            default: 'radio'
        }
    },
    setup(props, context) {
        const formData = ref({
        });
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);
        // 已选择的key值
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.fileId);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if (allKeys.indexOf(element.fileId) === -1) {
                    selectedRows.value.push(element)
                }
            })
            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        }
        const tableData = ref([
        ])
        const current = ref(1);
        const searchValue = ref('');
        const pageSize = ref(15);
        const loading = ref(false);
        const total = ref(0)
        const handlePageChange = (page, pageSize) => {
            // 将已选行中取消选择的脏数据去除
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.fileId) !== -1;
            });
            getTemplateList();
        }

        const isGettingContent = ref(false);

        const handleConfirm = async () => {
            /* selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.fileId) !== -1;
            });
            if (selectedRows.value.length !== 0) {
                context.emit('importConfirm', selectedRows.value);
            }
            context.emit('update:visible', false) */
            isGettingContent.value = true;
            const res = await getCustomTemplateContentDetail({
                customTemplateContentId: selectedRowKeys.value[0]
            });
            if(res.success) {
                context.emit('importConfirm',  res.data.templateContent)
                //templateContent.value = res.data.templateContent
                
            }
            isGettingContent.value = false
        }
        const getFileList = async () => {
            loading.value = true;
            const res = await getExperimentDocumentList({
                projectId: context.root.$route.query.projectId,
                experimentId: context.root.$route.query.id,
                searchValue: searchValue.value,
                pageNo: current.value,
                pageSize: 10
            })
            if(res.code === 200) {
                tableData.value = res.data.rows;
                total.value = res.data.totalRows;
                loading.value = false;
            }
        }
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                selectedRowKeys.value = [];
                selectedRows.value = [];
                searchValue.value = '';
                isGettingContent.value = false;
                templateContent.value = null;
                getTemplateList();
                /* getFileList(); */
            }
        })
        const getCheckboxProps = (record) => {
            return {
                props: {
                    disabled: false
                }
            }
        }
        const handleSearch = () => {
            current.value = 1;
            getTemplateList();
        }
        const handleReset = () => {
            searchValue.value = '';
        }


        // 预览对话框可见变量
        const previewModalVisible = ref(false);

        // 正在预览的模板
        const previewRecord = ref({

        })

        const templateContent = ref(null);

        // 预览点击事件
        const handlePreviewClick = (record) => {
            templateContent.value = null;
            previewRecord.value = record;
            getTemplateDetail();
            previewModalVisible.value = true;
        }

        const isListLoading = ref(false);

        const getTemplateDetail = async () => {
            loading.value = true;
            const res = await getCustomTemplateContentDetail({
                customTemplateContentId: previewRecord.value.customTemplateContentId
            });
            if(res.success) {
                templateContent.value = res.data.templateContent;
                loading.value = false;
            }
        }

        const getTemplateList = async () => {
            const res = await getCustomTemplateContentPage({
                searchValue: searchValue.value,
                pageNo: current.value,
                PageSize: pageSize.value        
            });
            if(res.success) {
                tableData.value = res.data.rows;
                total.value = res.data.totalRows
            }
        }

        return {
            formData,
            formRef,
            handleCancel,
            columns,
            selectedRowKeys,
            onSelectChange,
            tableData,
            current,
            handlePageChange,
            handleConfirm,
            getCheckboxProps,
            loading,
            searchValue,
            total,
            handleSearch,
            handleReset,
            previewModalVisible,
            handlePreviewClick,
            previewRecord,
            templateContent,
            isGettingContent,
            isListLoading
        }
    }
}
</script>