const state = {
    // 新增评论数量
    commentQuantity: 0,
    // 新增粉丝数量
    userQuantity: 0,
    // 最新点赞和收藏数量
    likeAndCollectionQuantity: 0
}

const mutations = {
    // 设置消息数量
   setMessageQuantity(state, data) {
    state.commentQuantity = data.commentQuantity;
    state.userQuantity = data.userQuantity;
    state.likeAndCollectionQuantity = data.likeAndCollectionQuantity;
   },
   // 重置评论消息数量
   resetCommentCount(state) {
    state.commentQuantity = 0
   },
   // 重置粉丝数量
   resetUserCount(state) {
    state.userQuantity = 0;
   },
   // 重置最新点赞和收藏数量
   resetLikeAndClolletionQuantity(state) {
    state.likeAndCollectionQuantity = 0;
   }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}