<template>
<a-modal :width="600" :visible="visible" :title="sampleInfo ? '修改样品信息' : '添加样品'" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
    <a-form-model ref="formRef" class="form" :model="formData" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }"  >
        <a-form-model-item label="样品名称" :rules="[{ required: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="name" >
            <a-input v-model="formData.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="所属项目" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="project" >
            <a-input v-model="formData.project" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="分类" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="category" >
            <!-- <a-select v-model="formData.category">
                <a-select-option value="1">
                    分类1
                </a-select-option>
            </a-select> -->
           <a-input v-model="formData.category" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="数量" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }"  >
            <div class="amount-item">
                <a-input v-model="formData.amount" placeholder="请输入"></a-input>
                <a-input style="margin-left: 16px; width: 64px;" v-model="formData.unit" placeholder="单位"></a-input>
            </div>
        </a-form-model-item>
        <a-form-model-item label="批号" :rules="[{ required: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="name" >
            <a-input v-model="formData.code" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="剩余可用量" :rules="[{ required: true, validator: remainingUsageValidator }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="remainingUsage" >
            <div class="amount-item">
                <a-input v-model="formData.remainingUsage" placeholder="请输入"></a-input>
                <a-input style="margin-left: 16px; width: 64px;" v-model="formData.remainingUsageUnit" placeholder="单位"></a-input>
            </div>
        </a-form-model-item>
    </a-form-model>
    <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
</a-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        sampleInfo: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        
        // 表单实例
        const formRef = ref(null);
        // 表单数据
        const formData = ref({
            name: null,
            project: null,
            category: null,
            amount: null,
            unit: null,
            code: null,
            remainingUsage: null,
            remainingUsageUnit: null
        });
        watch(() => props.visible, (newVal) => {
            // 对话框打开
            if(newVal) {
                // 存在选择的对象，则是编辑模式
                if(props.sampleInfo) {
                    formData.value = JSON.parse(JSON.stringify(props.sampleInfo));
                }
                else {
                    formData.value = {
                        name: null,
                        project: null,
                        category: null,
                        amount: null,
                        unit: null,
                        code: null,
                        remainingUsage: null,
                        remainingUsageUnit: null
                    }
                }
            }
        })
        // 处理取消关闭
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 处理确认
        const handleConfirm = () => {
            formRef.value && formRef.value.validate().then(() => {
                if(props.sampleInfo) {
                    context.emit('onModify', formData.value);
                }
                else {
                    context.emit('onAdd', formData.value);
                }
                
                context.emit('update:visible', false);
            }).catch(e => e);
            return 
        }
        // 剩余可用量校验器
        const remainingUsageValidator = ( _rule, value, callback ) => {
            if(!formData.value.remainingUsage || !formData.value.remainingUsage.toString().trim() ) {
                callback(new Error('请输入可用量'))
            }
            else if(!formData.value.remainingUsageUnit || !formData.value.remainingUsageUnit.toString().trim()) {
                callback(new Error('请输入单位'))
            }
            else {
                callback();
            }
        }
        return {
            formRef,
            formData,
            handleCancel,
            handleConfirm,
            remainingUsageValidator
        }
    }
}
</script>

<style lang="less" scoped>
.form {
    margin: 0px 60px;
    .amount-item {
        display: flex;
    }
}
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>