<template>
    <a-modal :width="1000" :visible="visible" title="挑选仪器" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
        <div class="search-ctn">
            <a-input style="width: 220px;" placeholder="请输入关键词" v-model="SearchValue" allowClear></a-input>
            <a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
            <a-button class="reset-btn" @click="handleReset"> 重置 </a-button>
            <span style="color: #FF4D4F; margin-left: 20px; font-size: 16px;"> ( 数据延时5分钟 ) </span>
        </div>
        <a-table :loading="loading" size="small" rowKey="id" :columns="columns" :data-source="tableData"
            :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }">
            <template slot="appointment" slot-scope="text, record, index">
                <a v-show="!record.appointment || (record.appointment && record.appointment.length === 0)" @click="handleSelectedAppointmentClick(record)"> 选择 </a>
                <a v-show="record.appointment && record.appointment.length" @click="handleSelectedAppointmentClick(record)">
                    <div v-for="(item,index) in record.appointment" :key="index" style="margin-top: 8px;">
                        <span> {{ item['begin_Time'] }} </span>
                        <span v-if="item['end_Time']"> <span style="margin: 0px 8px;"> —— </span> {{item['end_Time'] }}</span>
                    </div>
                </a>
            </template>
            <template slot="useRecord" slot-scope="text, record, index">
                <a v-show="!record.useRecord || (record.useRecord && record.useRecord.length === 0)" @click="handleSelectedUseRecordClick(record)"> 选择 </a>
                 <a v-show="record.useRecord && record.useRecord.length" @click="handleSelectedUseRecordClick(record)">
                    <div v-for="(item,index) in record.useRecord" :key="index" style="margin-top: 8px;">
                        <span> {{ item['open_Time'] }} </span>
                        <span v-if="item['close_Time']"> <span style="margin: 0px 8px;"> —— </span> {{item['close_Time'] }}</span>
                    </div>
                </a>
            </template>
        </a-table>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="total"
                show-less-items />
        </div>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
        <SelectAppointmentModal :visible.sync="selectAppointmentModalVisible" :disabledList="selectedAppointmentItem.appointment" :selectedAppointment="selectedAppointmentItem" @importConfirm="selectedAppointmentConfirm">
        </SelectAppointmentModal>
        <SelectUsedRecordModal :visible.sync="selectUsedRecordModalVisible" :disabledList="selectedUsedRecordItem.useRecord" :selectedUsedRecordItem="selectedUsedRecordItem" @importConfirm="handleSelectedUsedRecordConfirm">
        </SelectUsedRecordModal>
    </a-modal>
</template>

<style lang="less" scoped>
.search-ctn {
    margin-bottom: 24px;

    .search-btn {
        //background: @srims-primary-color;
        margin-left: 24px;
    }

    .reset-btn {
        background: #eeeeee;
        margin-left: 16px;
    }
}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

/deep/ .ant-table-thead {
    background: #F0F5FF;
}

/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px;
}

.default-pagination {
    text-align: center;
    margin-top: 24px;
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';
import { getExternalEquitmentData } from '../../../../../api/experiment';
import SelectAppointmentModal from './selectAppointmentModal.vue';
import SelectUsedRecordModal from './selectUsedRecordModal.vue';
const columns = [
    {
        dataIndex: 'name',
        title: '仪器名称',
        //width: '17%',
        scopedSlots: { customRender: 'name' }
    },
    {
        dataIndex: 'code',
        title: '编号',
        // width: '14%',
        scopedSlots: { customRender: 'code' }
    },
    {
        dataIndex: 'model',
        title: '型号',
        // width: '14%',
        scopedSlots: { customRender: 'model' }
    },
    {
        dataIndex: 'manufacturers',
        title: '厂商',
        //width: '20%',
        scopedSlots: { customRender: 'manufacturers' }
    },
    {
        dataIndex: 'storageLocation',
        title: '仪器位置',
        // width: '20%',
        scopedSlots: { customRender: 'storageLocation' }
    },
    {
        dataIndex: 'measurementAccuracyType',
        title: '度量精度',
        //width: '13%',
        scopedSlots: { customRender: 'measurementAccuracyType' }
    },
    {
        dataIndex: "appointment",
        title: "仪器预约记录",
        scopedSlots: { customRender: "appointment" }
    },
    {
        dataIndex: "useRecord",
        title: "上下机记录",
        scopedSlots: { customRender: "useRecord" }
    }
]
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        disabledList: {
            type: Array,
            default: () => []
        },
        selectedEquitmentList: {
            default: () => []
        }
    },
    setup(props, context) {
        const formData = ref({});
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const handleReset = () => {
            SearchValue.value = "";
        };
        const handleSearch = () => {
            current.value = 1;
            getEquitmentList();
        };
        const formRef = ref(null);
        // 已选择的key值
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.id);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if (allKeys.indexOf(element.id) === -1) {
                    selectedRows.value.push(element);
                }
            });
            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        };
        const tableData = ref([]);
        const current = ref(1);
        const total = ref(0);
        const SearchValue = ref("");
        const loading = ref(false);
        const handlePageChange = (page, pageSize) => {
            // 将已选行中取消选择的脏数据去除
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
            getEquitmentList();
        };
        const handleConfirm = () => {
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
          //  console.log(selectedRows.value);
            //cons
            context.emit("importConfirm", selectedRows.value);
           /*  if (selectedRows.value.length !== 0) {
                context.emit("importConfirm", selectedRows.value);
            }
            context.emit("update:visible", false); */
        };
        const getEquitmentList = async () => {
            loading.value = true;
            const res = await getExternalEquitmentData({
                pageSize: 10,
                pageNo: current.value,
                searchValue: SearchValue.value
            });
            if (res.code === 200) {
                total.value = res.data.totalRows;
                tableData.value = res.data.rows;
                // 存在的数据更新
                tableData.value.forEach(row => {
                    if(selectedRowKeys.value.indexOf(row.id) !== -1) {
                        //console.log('enter')
                        const originalRow = selectedRows.value.filter(item => item.id === row.id);
                        if(originalRow && originalRow.length) {
                            if(originalRow[0].appointment) {
                                const appointment = JSON.parse(JSON.stringify(originalRow[0].appointment));
                                row.appointment = appointment;
                            }
                            if(originalRow[0].useRecord) {
                                const useRecord = JSON.parse(JSON.stringify(originalRow[0].useRecord));
                                row.useRecord = useRecord;
                            }
                            if(originalRow[0].index) {
                                row.index = originalRow[0].index
                            }
                            const index = selectedRows.value.indexOf(originalRow[0]);
                           // console.log(index);
                            selectedRows.value.splice(index,1, row);
                        }
                    }
                })
                loading.value = false;
            }
        };
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                current.value = 1;
                SearchValue.value = "";
                selectedRows.value = JSON.parse(JSON.stringify(props.selectedEquitmentList));
                selectedRowKeys.value = selectedRows.value.map(row => row.id);
                await getEquitmentList();
                
               
            }
        });
        // 是否可以选择
        const getCheckboxProps = (record) => {
            return {
                props: {
                    disabled: props.disabledList.indexOf(record.id) !== -1
                }
            };
        };
        // 是否展示选择查看日期
        const getIsDisabled = (record) => {
            return props.disabledList.indexOf(record.id) !== -1;
        };

        /**********************************              选择预约记录对话框  start                **************************/
        // 选择预约记录对话框可见变量
        const selectAppointmentModalVisible = ref(false);
        // 选择中的仪器
        const selectedAppointmentItem = ref({});
        // 点击选择选中的仪器
        const handleSelectedAppointmentClick = (record) => {
            selectedAppointmentItem.value = record;
            if(!selectedAppointmentItem.value.appointment) {
                selectedAppointmentItem.value.appointment = [];
            }
            selectAppointmentModalVisible.value = true;
        }
        // 选择预约记录确定事件
        const selectedAppointmentConfirm = () => {
            if(selectedRowKeys.value.indexOf(selectedAppointmentItem.value.id) === -1) {
                selectedRowKeys.value.push(selectedAppointmentItem.value.id);
                if(selectedRows.value.filter(item => item.id === selectedAppointmentItem.value.id).length === 0) {
                   selectedRows.value.push(selectedAppointmentItem.value); 
                }
            }
            selectAppointmentModalVisible.value = false;
        }

        /**********************************              选择预约记录对话框  end                  **************************/
        /**********************************              选择上下机记录对话框  start              **************************/
        // 选择上下机记录对话框
        const selectUsedRecordModalVisible = ref(false);
        // 选择中的仪器
        const selectedUsedRecordItem = ref({});
        // 点击选择选中仪器
        const handleSelectedUseRecordClick = (record) => {
           selectedUsedRecordItem.value = record;
           if(!selectedUsedRecordItem.value.useRecord) {
                selectedUsedRecordItem.value.useRecord = [];
           }
           selectUsedRecordModalVisible.value = true;
        }
        // 选择预约记录确定时间回调
        const handleSelectedUsedRecordConfirm = () => {
             if(selectedRows.value.indexOf(selectedUsedRecordItem.value.id) === -1) {
                selectedRowKeys.value.push(selectedUsedRecordItem.value.id);
                if(selectedRows.value.filter(item => item.id === selectedUsedRecordItem.value.id).length === 0) {
                    selectedRows.value.push(selectedUsedRecordItem.value);
                }
            }
            selectUsedRecordModalVisible.value = false;
        }
        /**********************************              选择上下机记录对话框  end                **************************/
        return {
            formData,
            formRef,
            handleCancel,
            columns,
            selectedRowKeys,
            onSelectChange,
            tableData,
            current,
            handlePageChange,
            handleConfirm,
            total,
            SearchValue,
            loading,
            handleReset,
            handleSearch,
            getCheckboxProps,
            getIsDisabled,
            selectAppointmentModalVisible,
            selectedAppointmentItem,
            selectedAppointmentConfirm,
            handleSelectedAppointmentClick,
            handleSelectedUseRecordClick,
            selectUsedRecordModalVisible,
            selectedUsedRecordItem,
            handleSelectedUsedRecordConfirm
        };
    },
    components: { SelectAppointmentModal, SelectUsedRecordModal }
}
</script>