/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment-alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_comment-alt_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g _fill="#4E5969" clip-path="url(#svgicon_comment-alt_a)"><path pid="1" d="M18.667 2H5.333A3.333 3.333 0 002 5.333v10a3.333 3.333 0 003.333 3.334H7.75l3.71 3.135a.833.833 0 001.076 0l3.714-3.135h2.417A3.333 3.333 0 0022 15.333v-10A3.333 3.333 0 0018.667 2zm1.666 13.333c0 .92-.746 1.667-1.666 1.667H16.25c-.394 0-.775.14-1.076.394L12 20.075l-3.172-2.68A1.667 1.667 0 007.75 17H5.333c-.92 0-1.666-.746-1.666-1.667v-10c0-.92.746-1.666 1.666-1.666h13.334c.92 0 1.666.746 1.666 1.666v10z"/><path pid="2" d="M7.833 7.84H12a.833.833 0 100-1.667H7.833a.833.833 0 000 1.667zM16.167 9.506H7.833a.833.833 0 100 1.667h8.334a.833.833 0 000-1.667zM16.167 12.84H7.833a.833.833 0 100 1.666h8.334a.833.833 0 000-1.667z"/></g>'
  }
})
