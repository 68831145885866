/**
 * 系统功能模块配置文件
 */

import newEditor from "./newEditor/newEditor1.vue";  // 图文编辑组件
import newEditorIcon from '../../../assets/component-logo/newEditor.png';   // 图文编辑Icon
import equipmentTable from './equipmentTable/equipmentTable.vue'; // 实验仪器组件
import equipmentTableIcon from '../../../assets/component-logo/equipment-table.png';  // 实验仪器Icon
import experimentMaterialTable from './experimentMaterialTable/experimentMaterialTable.vue';  // 实验材料组件
import experimentMaterialTableIcon from '../../../assets/component-logo/experment-material.png'; // 实验材料Icon
import sampleInfoTable from './sampleInfoTable/sampleInfoTable.vue';  // 样品信息表
import sampleInfoTableIcon from '../../../assets/component-logo/sample-info.png';  //  样品信息表Icon
import experimentDocumentTable from './experimentDocumentTable/experimentDocumentTable.vue';  // 实验文档组件
import experimentDocumentTableIcon from '../../../assets/component-logo/experiment-document.png';   // 实验文档Icon
import referenceTable from './referenceTable/referenceTable.vue'; // 参考文献组件
import referenceTableIcon from '../../../assets/component-logo/references.png'; // 参考文献Icon
import excelPro from './excelPro/excelPro.vue'; // 实验电子表格组件
import excelProIcon from '../../../assets/component-logo/excelProIcon.png';   // 电子表格组件Icon
import relatedExperimentTable from './relatedExperimentTable/relatedExperimentTable.vue'; // 关联实验
import relatedExperimentTableIcon from '../../../assets/component-logo/related-experiment.png'; // 关联实验Icon
import diyTable from './diyTable/diyTable.vue'; // 自定义表格组件
import diyTableIcon from '../../../assets/component-logo/diy-table-icon.png';   // 自定义表格Icon
import diyForm from './diyForm/diyForm.vue';  // 自定义表单
import diyFormIcon from '../../../assets/component-logo/diy-form.png';  // 自定义表单Icon
const customComponentsConfig = {
  title: '系统模块',
  list: [
    {
      type: 'new-editor',
      label: '图文编辑',
      icon: newEditorIcon,
      component: newEditor,
      options: {
        /* defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null,
        /* readonly: true */
      },
      model: "",
      key: "",
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'equipment-table',
      label: '实验仪器',
      icon: equipmentTableIcon,
      component: equipmentTable,
      options: {
        defaultValue: undefined,
       /*  multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
       /*  readonly: true, */
        showColumns: [ {/* label:'编号', */ label:'设备证号', value: 'code', checked: true }, { label: '型号', value:'model', checked: true},{ /* label: '记录来源', */label: '仪器使用人', value: 'source', checked: true}, { label:'检验有效期', value: 'expirationDate', checked: true}, { label: '厂商', value: 'manufacturers',  checked: true}, { label:'仪器位置', value: 'storageLocation', checked: true }, { label: '度量精度', value: 'measurementAccuracyType', checked: true} , /* { label:'仪器预约记录',value:'appointment', checked: true} ,  */{ /* label: '上下机记录', */label: '仪器使用记录', value:'useRecord', checked: true}]
      },
      model: "",
      key: "",  // 唯一字段key
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'experiment-material-table',
      label: '实验材料',
      icon: experimentMaterialTableIcon,
      component: experimentMaterialTable,
      options: {
      /*   defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
        /* readonly: true */
      },
      model: "",
      key: "",  // 唯一字段key
     /*  rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'sample-info-table',
      label: '样品信息',
      icon: sampleInfoTableIcon,
      component: sampleInfoTable,
      options: {
        /* defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
        /* readonly: true */
      },
      model: "",
      key: "",  // 唯一字段key
     /*  rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'experiment-document-table',
      label: '实验文档',
      icon: experimentDocumentTableIcon,
      component: experimentDocumentTable,
      options: {
       /*  defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
        /* readonly: true, */
      },
      model: "",
      key: "",  // 唯一字段key
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'reference-table',
      label: '参考文献',
      icon: referenceTableIcon,
      component: referenceTable,
      options: {
      /*   defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
       /*  readonly: true */
      },
      model: "",
      key: "",  // 唯一字段key
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'excel-pro',
      label: '电子表格',
      icon: excelProIcon,
      component: excelPro,
      options: {
       /*  defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null, // 数据字段
        /* readonly: true */
      },
      model: "",
      key: "",  // 唯一字段key
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'related-experiment-table',
      label: '关联实验',
      icon: relatedExperimentTableIcon,
      component: relatedExperimentTable,
      options: {
       /*  defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
        /* readonly: true */
      },
      model: "",
      key: "",  // 唯一字段key
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'diy-table',
      label: '自定义表格',
      icon: diyTableIcon,
      component: diyTable,
      options: {
       /*  defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [], // 数据字段
       /*  readonly: true, */
        columns: [
          {
            title: '名称',
            dataIndex: 'name',
            scopedSlots: { customRender: "name" }
          },
          {
            title: '记录',
            dataIndex: 'remark',
            scopedSlots: { customRender: "remark" }
          },
          {
            title: '时间',
            dataIndex: 'time',
            scopedSlots: { customRender: "time" }
          },
        ],
        showColumns: [  { label: '记录', value:'remark', checked: true},{ label: '时间', value: 'time', checked: true}]
      },
      model: "",
      key: "",  // 唯一字段key
    /*   rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'diy-form',
      label: '自定义表单',
      icon: diyFormIcon,
      component: diyForm,
      options: {
       /*  defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        isEditForm: true,
        customData: [
          [
            {
              label: '标签-0-0',
              value: '',
              type: 'input'
            }
          ]
        ], // 数据字段
       /*  readonly: true, */
      },
      model: "",
      key: "",  // 唯一字段key
     /*  rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    }
  ]
}

export default customComponentsConfig;