<template>
    <a-modal :width="800" :visible="visible" title="我的上下机记录" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
         <div class="search-ctn">
            <a-range-picker valueFormat="YYYY-MM-DD" v-model="dateRange" style="width: 300px;" />
            <a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
            <a-button class="reset-btn" @click="handleReset"> 重置 </a-button>
        </div>
        <a-table :loading="loading" size="small" rowKey="id" :columns="columns"  :data-source="tableData" :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }">
             <template slot="date" slot-scope="text, record, index">
                <div>
                    <span> {{ record['open_Time'] }} </span>
                    <span v-if="record['close_Time']"> <span style="margin: 0px 8px;"> —— </span> {{ record['close_Time']
                    }}</span>
                </div>
            </template>
            <template slot="time" slot-scope="text, record, index">
                {{ record['total_Minute'] }}
            </template>
        </a-table>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="total"
                show-less-items />
        </div>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { getEquitmentUsedRecord } from '../../../../../api/experiment';
const columns = [
    {
        dataIndex: 'date',
        title: '上下机时间',
        //width: '17%',
        scopedSlots: { customRender: 'date' }
    },
    {
        title: '总时长(分)',
        scopedSlots: { customRender: 'time' }
    },
]
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        disabledList: {
            type: Array,
            default: () => []
        },
        selectedUsedRecordItem: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const dateRange = ref([])
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const handleReset = () => {
            dateRange.value = [];
        };
        const handleSearch = () => {
            current.value = 1;
            //getEquitmentList();
            getUseRecord();
        };
        // 已选择的key值
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.id);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if(allKeys.indexOf(element.id) === -1) {
                    selectedRows.value.push(element)
                }
            })
            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        }
        const tableData = ref([
        ])
        const current = ref(1);
        const total = ref(0);
        const loading = ref(false);
        const handlePageChange = (page, pageSize) => {
            // 将已选行中取消选择的脏数据去除
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
            getUseRecord();
        }
        const handleConfirm = () => {
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
            const res = selectedRows.value.filter(row => selectedRowKeys.value.indexOf(row.id) !== -1);
            props.disabledList.splice(0, props.disabledList.length, ...res);
            context.emit('importConfirm', selectedRows.value);
            //context.emit('update:visible', false)
        }
        // 获取已选中记录的id
        const selectedIds = computed(() => {
            if(!props.disabledList) {
                return [];
            }
            else {
                return props.disabledList.map(item => item.id);
            }
        })

        const getUseRecord = async () => {
            loading.value = true;
            const res = await getEquitmentUsedRecord({
                DeviceId: props.selectedUsedRecordItem.id,
                BeginTime: dateRange.value.length === 2 ? dateRange.value[0] + ' 00:00:00' : undefined,
                EndTime: dateRange.value.length === 2 ? dateRange.value[1] + ' 23:59:59': undefined,
                PageIndex: current.value,
                PageSize: 10
            });
            if(res.code === 200) {
                tableData.value = res.data.rows;
                total.value = res.data.totalRows;
            }
            loading.value = false;
        }

        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                current.value = 1;
                dateRange.value = [];
                await getUseRecord();
                selectedRows.value = JSON.parse(JSON.stringify(props.disabledList));
                selectedRowKeys.value = props.disabledList.map(row => row.id);
            }
        });

          // 是否可以选择
        const getCheckboxProps = (record) => {
            return {
                props: {
                    //disabled:selectedIds.value.indexOf(record.id) !== -1
                }
            };
        };
        return {
            handleCancel,
            columns,
            dateRange,
            tableData,
            handleConfirm,
            current,
            total,
            loading,
            handlePageChange,
            onSelectChange,
            selectedRowKeys,
            selectedRows,
            getCheckboxProps,
            handleReset,
            handleSearch
        }
    }
}
</script>

<style lang="less" scoped>
.search-ctn {
    margin-bottom: 24px;
    .search-btn {
        margin-left: 24px;
    }
    .reset-btn {
        background: #eeeeee;
        margin-left: 16px;
    }
}
.btns-ctn {
    text-align: center;
    .concel-btn {
        background: #eeeeee;
    }
}
/deep/ .ant-table-thead {
    background: #F0F5FF;
}

/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px;
}

.default-pagination {
    text-align: center;
    margin-top: 24px;
}
</style>