/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'interlining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_interlining_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g _fill="#4E5969" clip-path="url(#svgicon_interlining_a)"><path pid="1" d="M10 5.991h13a1 1 0 000-2.003H10a1 1 0 000 2.003zM23 10.999H10A1 1 0 0010 13h13A1 1 0 0023 11zM23 18.009H10a1 1 0 000 2.003h13a1 1 0 000-2.003zM6.088 5.991a.5.5 0 00.353-.855L4 2.693a.999.999 0 00-1.414 0L.147 5.136a.501.501 0 00.354.855h1.794V18.01H.5a.5.5 0 00-.354.855l2.44 2.443a.999.999 0 001.414 0l2.44-2.443a.501.501 0 00-.353-.855H4.295V5.99h1.793z"/></g>'
  }
})
