import cloneDeepWith from 'lodash/cloneDeepWith';
import clone from 'lodash/clone';
import cloneDeep  from 'lodash/cloneDeep';
import isArray  from 'lodash/isArray';
// 自定义复制函数，规避方法/文件内容的丢失
export const cloneExperiment = (e) => {
    return cloneDeepWith(e, function (val) {
        if (typeof (val) === 'function') {
            return val;
        }
        // 浅克隆避免获取数据getData方法丢失
        else if (val.type === 'excel-pro') {
            return clone(val);
        }
       /*  // 深度克隆避免文件丢失
        else if (['experiment-document', 'reference-table'].indexOf(val.type) != -1) {
            return cloneDeep(val);
        } */
        else if (['experiment-document-table', 'excel-pro', 'experiment-material-table', 'reference-table'].indexOf(val.type) != -1) {
            return cloneDeep(val);
        }
        else if (val.type === 'grid') {
            return cloneDeep(val);
        }
        else if(isArray(val)) {
            return cloneDeep(val);
        }
        // 对数组进行浅度克隆，深克隆造成数组丢失
        else if(typeof(val) === 'object' && Object.keys(val).indexOf('list') !== -1) {
            const newRes = { config: clone(val.config), list: clone(val.list) };
            //console.log(newRes);
            return newRes;
        }
        else {
            return cloneDeep(val);
        }
    });
}

export const myDeepClone = (record) => {
    return cloneDeepWith(record, function(value) {
        if(typeof record === 'function') {
            return value;
        }
        else {
            return cloneDeep(value);
        }
    })
}