<template>
<div v-if="mode==='edit'" class="input-number-ctn">
    <a-input-number v-model="record.options.customData"></a-input-number>
</div>
<div v-else class="input-number-ctn">
    <a-input :readOnly="true" :value="customData" style="width: 150px;" ></a-input>
</div>
</template>

<style lang="less" scoped>
.input-number-ctn {
    padding: 10px;
    padding-bottom: 5px;
    min-height: 50px;
}
</style>

<script>
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props,context) {
        const handleChange = (val) => {
            context.emit('customDataChange',val)
        }
        return {
            handleChange
        }
    }
}
</script>