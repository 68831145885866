<template>
<div v-if="mode === 'edit'" class="single-selection-ctn">
    <a-select mode="multiple" :value="customData" style="width: 100%;" @change="handleSelectionChange">
        <a-select-option v-for="(item) in record.options.options" :key="item.label" :value="item.label" >
            {{ item.label }}
        </a-select-option>
    </a-select>
</div>
<div v-else class="single-selection-ctn">
    <a-select mode="multiple" :value="customData" style="width: 100%;" @deselect="() => {}" @select="() => {}">
        <a-select-option v-for="(item) in record.options.options" :key="item.label" :value="item.label" >
            {{ item.label }}
        </a-select-option>
    </a-select>
</div>
</template>

<style lang="less" scoped>
.single-selection-ctn {
    padding: 10px;
    min-height: 50px;
    padding-bottom: 5px;
}
</style>

<script>
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    methods: {
        handleSelectionChange(value) {
            this.$emit('customDataChange',value)
        }
    },
}
</script>