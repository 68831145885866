/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete-bin-5-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><clipPath id="svgicon_delete-bin-5-fill_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath></defs><g clip-path="url(#svgicon_delete-bin-5-fill_a)"><path pid="1" d="M1.867 4.934h12.266v9.967a.767.767 0 01-.766.766H2.633a.767.767 0 01-.766-.766V4.934zm2.3-2.3V1.101c0-.424.343-.767.766-.767h6.134c.423 0 .766.343.766.767v1.533h3.834v1.533H.333V2.634h3.834zM5.7 1.867v.767h4.6v-.767H5.7zm0 6.134v4.6h1.533V8H5.7zm3.067 0v4.6H10.3V8H8.767z"/></g>'
  }
})
