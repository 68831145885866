<template>
    <div class="pdf-panel" id="pdf-panel">
        <div class="pdf-cover">
            <div style="height: 1848px; display: flex; justify-content: center; align-items: center; position: relative;">
                <div style="min-width:40%; max-width: 60%; font-weight: bold; width: fit-content;font-size: 32px; position: absolute; top: 100px; ">
                    <!-- <div style="font-size: 48px; text-align: center; padding: 20px 150px;">
                        {{ pdfData.title }}
                    </div> -->
                    <div
                                                        style="font-size: 48px; text-align: center; padding: 20px 150px; word-break: break-all;">
                                                        {{ pdfData.title }}
                                                        <img :src="qrcode" style="width: 200px; height: 200px; position: absolute; top: 0px; right: -250px;"/>
                                                    </div>
                    <div style="display: flex; margin-top: 200px;">
                        <div style="width: 200px;text-align: right;"> 实验编号：  </div> <div style="margin-left: 15px; border-bottom: 2px solid rgba(0,0,0,0.85); flex: 1; text-align: center;">{{ pdfData.experimentNo }}</div>
                    </div>
                     <div style="display: flex; margin-top: 50px;">
                        <div style="width: 200px;text-align: right;"> 相对湿度：  </div> <div style="margin-left: 15px; border-bottom: 2px solid rgba(0,0,0,0.85); flex: 1; text-align: center;"> {{ pdfData.relativeHumidity?pdfData.relativeHumidity : '' }} </div>
                    </div>
                     <div style="display: flex; margin-top: 50px;">
                        <div style="width: 200px;text-align: right;"> 室温：  </div> <div style="margin-left: 15px; border-bottom: 2px solid rgba(0,0,0,0.85); flex: 1; text-align: center;">{{ pdfData.roomTemperature ? pdfData.roomTemperature : '' }}</div>
                    </div>
                    <div style="display: flex; margin-top: 50px;">
                        <div style="width: 200px;text-align: right;"> 关键词：  </div> <div style="margin-left: 15px; border-bottom: 2px solid rgba(0,0,0,0.85); flex: 1; word-break: break-all; text-align: center;"><div style="text-align: left;">{{ pdfData.keyWord ? pdfData.keyWord : '' }}</div></div>
                    </div>
                    <div style="display: flex; margin-top: 50px;">
                        <div style="width: 200px;text-align: right;"> 结论：  </div> <div style="margin-left: 15px; border-bottom: 2px solid rgba(0,0,0,0.85); flex: 1; text-align: center;">{{ pdfData.conclusion ? ExperimentConclusionArr[pdfData.conclusion] : ''  }}</div>
                    </div>
                   <!--  <div style="position: relative; margin-top: 200px; ">
                        <img :src="qrcode" style="width: 200px; height: 200px; position: absolute; top: 0px; right: 0;"/>
                    </div> -->
                </div>
            </div>
            <div class="pdf-inside-panel">
                <k-form-component-panel :class="{ 'no-toolbars-top': false, 'KFCP-PDF': true }" :data="isManage ? data : eData" :selectItem="{}"
                    :noModel="noModel" :hideModel="true" :startType="startType" ref="KFCPP" :mode="'pdf'" />
            </div>
        </div>
        <div class="pdf-header"
            style="font-weight: bold; padding:15px 8px; width: 100%; border-bottom: 1px solid rgba(0, 0, 0, 0.85); color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
            实验编号: {{ pdfData.experimentNo }}
        </div>
        <div class="pdf-footer"
            style="font-weight: bold; padding: 15px 8px; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div style="width: 50%; flex: 1;">
                    创建时间： {{ pdfData.createdTime }} <span style="margin-left: 15px;"> 创建人： {{ pdfData.createdUserName }}
                    </span>
                </div>
                <div style="white-space: nowrap; margin-left: 50px;">
                    最后更新时间： {{ pdfData.updatedTime }} <span style="margin-left: 15px;"> 最后修改人： {{
                            pdfData.updatedUserName
                    }} </span>
                </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
                <div style="width: 50%; flex: 1;">
                    审核时间： {{ pdfData.aduitTime }} <span style="margin-left: 15px;"> 审核人： {{ pdfData.aduitUserName }}
                    </span>
                </div>
                <div style="white-space: nowrap; margin-left: 50px;">
                    报告导出时间： {{ getDate() }} <span style="margin-left: 15px;"> 报告导出人： {{
                            userName
                    }} </span>
                </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                第<div class="pdf-footer-page"></div>页 / 第<div class="pdf-footer-page-count"></div>页
            </div>
        </div>
    </div>
</template>

<script>
import { useQRCode } from '@vueuse/integrations/useQRCode';
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue-demi';
import kFormComponentPanel from './k-form-design/packages/KFormDesign/module/formComponentPanel';
import { ExperimentConclusionArr } from '@/utils/enums.js';
import store from '../store';
import dayjs from 'dayjs';
import { isDesinerLoad } from '../plugins/KFormDesign/spreadjs';
export default {
    props: {
        pdfData: {

        },
        eData: {
            default: () => {
                return {
            "list": [],
            "config": {
                "layout": "vertical",
                "labelCol": {
                    "xs": 4,
                    "sm": 4,
                    "md": 4,
                    "lg": 4,
                    "xl": 4,
                    "xxl": 4
                },
                "labelWidth": 100,
                "labelLayout": "flex",
                "wrapperCol": {
                    "xs": 18,
                    "sm": 18,
                    "md": 18,
                    "lg": 18,
                    "xl": 18,
                    "xxl": 18
                },
                "hideRequiredMark": false,
                "customStyle": ""
            }
        }
            }
        },
        isManage: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const data = ref({
            "list": [],
            "config": {
                "layout": "vertical",
                "labelCol": {
                    "xs": 4,
                    "sm": 4,
                    "md": 4,
                    "lg": 4,
                    "xl": 4,
                    "xxl": 4
                },
                "labelWidth": 100,
                "labelLayout": "flex",
                "wrapperCol": {
                    "xs": 18,
                    "sm": 18,
                    "md": 18,
                    "lg": 18,
                    "xl": 18,
                    "xxl": 18
                },
                "hideRequiredMark": false,
                "customStyle": ""
            }
        });
        const startType = ref("");
        const KFCPP = ref(null);
        const noModel = ref([
            "button",
            "divider",
            "card",
            "grid",
            "tabs",
            "table",
            "alert",
            "text",
            "html"
        ]);
        watch(() => props.pdfData, (newVal) => {
            //console.log(newVal)
            if (newVal && newVal.content) {
                if(typeof newVal.content === 'string') {
                    data.value = JSON.parse(newVal.content);
                }
                else {
                    data.value = newVal.content;
                }
            }
        }, { immediate: true, deep: true });
        const getLocation = computed(() => {
            if(props.eData) {
                const href = window.location.href;
                return href.replace('mode=edit','mode=preview');
            }
            else {
                const href = `${window.location.origin}/#/experiment?mode=preview&id=${pdfData.id}&projectId=${pdfData.proejectId}`;
                return href;
            }
        })
        const qrcode = useQRCode(getLocation);

        const componentsCount = computed(() => {
            const realData = props.isManage ? data.value : props.eData;
            let count = 0;
            function goDeep(gird) {
                gird.columns.forEach(Element => { 
                    Element && Element.list && Element.list.length && Element.list.forEach(element => {
                    if (element.type === 'grid') {
                        goDeepIfColumns(element);
                    }
                    else if(element.type === 'excel-pro') {
                         count = count + 1;
                    }
                    });
                })
            }
            realData.list.forEach(component => {
                if(component.type === 'grid') {
                    goDeep(component);
                }
                else if(component.type === 'excel-pro') {
                    count = count + 1;
                }
            })
            return count;
        });

        const available = computed(() => {
            const realData = props.isManage ? data.value : props.eData;
            let count = 0;
            function goDeep(gird) {
                gird.columns.forEach(Element => { 
                    Element && Element.list && Element.list.length && Element.list.forEach(element => {
                    if (element.type === 'grid') {
                        goDeepIfColumns(element);
                    }
                    else if(element.type === 'excel-pro') {
                         count = count + 1;
                    }
                    });
                })
            }
            //console.log(realData)
            realData.list.forEach(component => {
                if(component.type === 'grid') {
                    goDeep(component);
                }
                else if(component.type === 'excel-pro') {
                    count = count + 1;
                }
            })
            //console.log(count);
            return (count === 0 ?  true : (count === store.state.pdf.loadPdfExcelAccount && isDesinerLoad)) ;
        })
        onMounted(() => {
            //console.log(store)
            //console.log(componentsCount.value);
        })
        onBeforeMount(() => {
            store.commit('pdf/resetExcelAccount');
        })
        const userName = computed(() => {
            const localVal = window.localStorage.getItem('userInfo');
            return  store.state.userInfo.name ? store.state.userInfo.name : (localVal ? localVal.name : '');
        })
        
        const getDate = () => {
            return dayjs().format('YYYY-MM-DD  HH:mm:ss')
        }
        const originalItem = {
            "list": [],
            "config": {
                "layout": "vertical",
                "labelCol": {
                    "xs": 4,
                    "sm": 4,
                    "md": 4,
                    "lg": 4,
                    "xl": 4,
                    "xxl": 4
                },
                "labelWidth": 100,
                "labelLayout": "flex",
                "wrapperCol": {
                    "xs": 18,
                    "sm": 18,
                    "md": 18,
                    "lg": 18,
                    "xl": 18,
                    "xxl": 18
                },
                "hideRequiredMark": false,
                "customStyle": ""
            }
        }

        const getDataArr = (nowData) => {
            const temp = JSON.parse(JSON.stringify(nowData));
            const divideCount = 1;
            const arrLength = Math.ceil(temp.list.length / divideCount);
            const res = [];
            for(let i = 0; i < arrLength; ++i) {
                const newItem = JSON.parse(JSON.stringify(originalItem));
                newItem.list.push(temp.list[i]);
                res.push(newItem);
            }
            nextTick(() => {
                store.commit('pdf/resetExcelAccount');
            })
            return res;
        }
        return {
            data,
            startType,
            KFCPP,
            noModel,
            qrcode,
            ExperimentConclusionArr,
            componentsCount,
            available,
            userName,
            getDate,
            getDataArr
        }
    },
    components: { kFormComponentPanel }
}
</script>

<style lang="less" scoped>
.pdf-panel {
    position: fixed; // 脱离文件流
    left: -2500px; // 移出可见区域
    width: 1300px; // 固定宽度
    padding: 1.5%;
    background: white;
    top: 0px !important;

    .pdf-inside-panel {
        /*  padding: 5px 5px 5px 5px;
        background: #f6f6f6; */
        /deep/ .form-designer-container-9136076486841527 .content section .form-panel.no-toolbars-top {
            height: 100% !important;
        }
    }

    .pdf-title {
        text-align: center;
        padding: 5px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    /deep/ img {
        max-width: 1300px !important;
        max-height: 800px !important;
    }
}
</style>