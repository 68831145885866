<template>
<div ref="ctnRef" class="image-uploader-ctn">
    <img v-if="imgUrl" :src="imgUrl" :style="{ 'max-height': `${maxHeight}px` }" class="image"/>
    <a-upload accept="image/*" :showUploadList="false" :multiple="false" :customRequest="handleFileUpload"  v-else-if="mode === 'edit'">
        <a-button> <a-icon type="upload" /> 上传图片 </a-button>
    </a-upload>
    <transition
        enter-active-class="animate__fadeIn"
        leave-active-class="animate__fadeOut">
        <div v-show="imgUrl && !isOutside" class="mask ">
            <a-tooltip title="查看">
                <!-- <a-icon class="operation-icon" type="eye" @click="previewVisible = true"/> -->
                <a-icon class="operation-icon" type="eye" @click="handlePreview"/>
            </a-tooltip>
            <a-tooltip title="重新上传">
                <a-upload v-if="mode === 'edit'"  accept="image/*" :showUploadList="false" :multiple="false" :customRequest="handleFileUpload">
                    <a-icon style="margin-left: 10px;" class="operation-icon" type="upload" />
                </a-upload>
            </a-tooltip>
        </div>
    </transition>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="imgUrl" />
    </a-modal>
</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import { useMouseInElement } from '@vueuse/core';
import { message } from 'ant-design-vue';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
export default {
    props: {
        imgUrl: {
            type: String,
            default: ''
        },
        maxHeight: {
            type: Number,
            default: 64
        },
        mode: {
            type: String,
            default: 'edit'
        },
        record: {
            type: Object,
            required: false
        }
    },
    setup(props, context) {
        onMounted(() => {
            viewer.value = new Viewer(ctnRef.value);
        })
        const viewer = ref();
        // 自定义上传
        const handleFileUpload = ({ action, file, onSuccess, onError, onProgress }) => {
            if(file.type.indexOf('image/') === -1) {
                message.error('结构式仅支持上传图片文件');
                return ;
            }
            const previewUrl =  window.URL.createObjectURL(file);
            context.emit('update:imgUrl', previewUrl);
            props.record.File = file;
            props.record.FileName = file.name;
            props.record.FileStatu = 'ready';

        }
        // 是否覆盖
        const ctnRef = ref(null);
        // 鼠标是否在元素外面
        const { isOutside } = useMouseInElement(ctnRef);
        // 预览对话框可见变量
        const previewVisible = ref(false);
        // 取消预览
        const handleCancel = () => {
            previewVisible.value = false;
        }
        const handlePreview = () => {
            viewer.value && viewer.value.update() && viewer.value.show();
        }
        return {
            handleFileUpload,
            ctnRef,
            isOutside,
            previewVisible,
            handleCancel,
            viewer,
            handlePreview
        }
    }
}
</script>

<style lang="less" scoped>
.image-uploader-ctn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
        display: block;
        
    }
    .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        transition: all .3s ease-in-out;
        .operation-icon {
            color: white;
            cursor: pointer;
        }
    }
}
</style>