<!--
 * @Description: 将数据通过k-form-item组件解析，生成控件
 * @Author: kcz
 * @Date: 2019-12-30 00:37:05
 * @LastEditTime: 2021-05-27 15:19:02
 * @LastEditors: kcz
 * @FilePath: \k-form-design\packages\KFormDesign\module\formNode.vue
 -->
<template>
  <div
    v-if="mode === 'edit'"
    class="drag-move-box"
    @click.stop="$emit('handleSelectItem', record)"
    :class="{ active: record.key === selectItem.key }"
  >
    <div class="form-item-box">
      <kFormItem :formConfig="config" :record="record" @handleCommentClick="handleCommentClick" :mode="mode" :type="type"/>
    </div>
    <div
      v-if="!hideModel"
      class="show-key-box"
      v-text="record.label + (record.model ? '/' + record.model : '')"
    />
    <div
      class="copy"
      :class="record.key === selectItem.key ? 'active' : 'unactivated'"
      @click.stop="$emit('handleCopy')"
      v-show="mode === 'edit' && ['experiment-document-table', 'excel-pro', 'experiment-material-table', 'reference-table'].indexOf(record.type) === -1"
    >
      <a-icon type="copy" />
    </div>
    <div
      class="delete"
      :class="record.key === selectItem.key ? 'active' : 'unactivated'"
      @click.stop="$emit('handleDelete')"
      v-show="mode === 'edit'"
    >
      <a-icon type="delete" />
    </div>
  </div>
  <div v-else-if="mode === 'preview' || mode === 'pdf'">
    <kFormItem :formConfig="config" :record="record" @handleCommentClick="handleCommentClick" :mode="mode" :type="type"/>
  </div>
</template>
<script>
/*
 * author kcz
 * date 2019-11-20
 * description 通过json生成的单个表单节点
 */
import kFormItem from "../../KFormItem/index";
export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    selectItem: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      required: true
    },
    hideModel: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  components: {
    kFormItem
  },
  methods: {
    // 评论点击事件
    handleCommentClick(item) {
      //console.log(item)
      this.$emit('handleCommentClick', item)
    }
  }
};
</script>
