<template>
    <div class="table-container">
        <div class="btns">
            <div class="left-btns">
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleAddButtonClick"> 新增 </a-button>
                <!--  <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 导入 </a-button> -->
                <!-- <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 云盘导入 </a-button> -->
            </div>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick" :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="columns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode === 'edit' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null">
            <template v-for="(col, columIndex) in ['remark']" :slot="col" slot-scope="text, record, index">
                <div :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'" :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="($event) => handleHiddenInputClick(col, columIndex, index, $event)">
                        {{ customData[index][col] }}
                    </div>
                    <div v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <a-input autoFocus @contextmenu.prevent="getConextMenu" v-model="customData[index][col]" :ref="`${col}${index}`" @focus="hiddenInputFocus" @blur="handleBlur">
                        </a-input>
                    </div>
                </div>
            </template>
             <template slot="name" slot-scope="text, record, index">
                <a @click.prevent> {{ record.name }}  </a>
            </template>
        </a-table>
         <ImportRelatedExperimentModal :visible.sync="importRelatedExperimentModalVisible" @importConfirm="handleImportConfirm" :disabledList="experimentIds"></ImportRelatedExperimentModal>
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;
        .ant-btn {
            min-width: 58px;
        }
        .operation-btn {
            background: #eeeeee;
        }
        .left-btns {
            width: 0px;
            flex-grow: 1;
            display: flex;
            .upload {
                margin-right: 8px;
            }
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
import ImportRelatedExperimentModal from './components/importRelatedExperimentModal.vue';
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            columns: [
                {
                    dataIndex: "experimentNo",
                    title: "实验编号",
                    width: "20%",
                    scopedSlots: { customRender: "experimentNo" }
                },
                {
                    dataIndex: "title",
                    title: "实验名称",
                    width: "25%",
                    scopedSlots: { customRender: "title" }
                },
                {
                    dataIndex: "createdUserName",
                    title: "创建人",
                    width: "10%",
                    scopedSlots: { customRender: "createdUserName" }
                },
                {
                    dataIndex: "createdTime",
                    title: "创建时间",
                    width: "20%",
                    scopedSlots: { customRender: "createdTime" }
                },
                {
                    dataIndex: "remark",
                    title: "备注",
                    width: "25%",
                    scopedSlots: { customRender: "remark" }
                }
            ],
            isEditting: false,
            rowIndex: null,
            colIndex: null,
            selectedRowKeys: [], // 选中行的key 列表
            importRelatedExperimentModalVisible: false  // 导入对话框
        };
    },
    methods: {
        // 其他隐藏组件点击事件
        handleTextClick(col, columIndex, index) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            //const that = this;
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 隐藏的输入框点击事件
        handleHiddenInputClick(col, columIndex, index, e) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            this.$store.dispatch("experiment/setText", e.target);
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 输入框隐藏事件
        isInputShow(index, columIndex) {
            return this.isEditting && this.rowIndex === index && this.colIndex === columIndex;
        },
        // 输入框失焦失焦， 恢复label形式
        handleBlur() {
            this.rowIndex = null;
            this.colIndex = null;
            this.isEditting = false;
            inputBlur();    // 时间戳不可选
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        // 导入点击事件
        handleImportClick() {
            this.importEquipmentModalVisible = true;
        },
        // 导入确认事件
        handleImportConfirm(rows) {
            const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for(let i = 0; i < rows.length; ++ i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(rows[i])),
                    remark: ''
                });
            }
        },
        // 新增按钮点击事件
        handleAddButtonClick() {
            this.importRelatedExperimentModalVisible = true;
        }
    },
    computed: {
        experimentIds() {
            return this.customData.map(Element => Element.experimentId)
        }
    },
    components:  {
        ImportRelatedExperimentModal
    }
};
</script>