/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shutdown': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_shutdown_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_shutdown_a)" style="mix-blend-mode:passthrough"><path pid="1" d="M12 1a1 1 0 011 1v10a1 1 0 11-2 0V2a1 1 0 011-1zM6.337 5.933a1 1 0 010 1.414 8 8 0 1011.316 0 1 1 0 111.414-1.414 10 10 0 11-14.144 0 1 1 0 011.414 0z" fill-rule="evenodd" _fill="#4E5969"/></g>'
  }
})
