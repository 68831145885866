<!--
 * @Descripttion: 
 * @Author: kcz
 * @Date: 2021-05-02 16:04:02
 * @LastEditors: kcz
 * @LastEditTime: 2021-05-14 21:24:50
-->
<template>
  <a-form-item :label-col="
    formConfig.layout === 'horizontal'
      ? formConfig.labelLayout === 'flex'
        ? { style: `width:${formConfig.labelWidth}px` }
        : formConfig.labelCol
      : {}
  " :wrapper-col="
  formConfig.layout === 'horizontal'
    ? formConfig.labelLayout === 'flex'
      ? { style: 'width:auto;flex:1' }
      : formConfig.wrapperCol
    : {}
" :style="
  formConfig.layout === 'horizontal' && formConfig.labelLayout === 'flex'
    ? { display: 'flex' }
    : {}
" ref="itemInstance" style="background: #fff;" >
    <template slot="label">
      <a-tooltip :title="record.type === 'new-editor'  && mode === 'edit' ? '该组件暂不支持拖拽，请在右侧目录进行位置调整' : ''">
      <div :class="[`label-container`, record.type === 'new-editor' ? 'editor-label' : '']" :style="mode === 'edit' && record.type !== 'new-editor' ? {cursor: `move`} : { cursor: record.type === 'new-editor' && mode === 'edit' ? 'not-allowed': ''  }" :title=" record.type === 'new-editor'  && mode === 'edit' ? '该组件无法拖拽' : ''">
        <div class="title-container">
          <div :class="[`label-text`, mode === 'edit' ? 'edit-text' : '']" v-show="!labelEditable || mode !== 'edit'"
            @click="handleLabelClick($event)" style="word-break: break-all;">{{ record.label }}
          </div>
          <a-input ref="labelInput" autoFocus v-model="record.label" v-show="labelEditable && mode === 'edit'"
            style="max-width:600px;" @blur="handleBlur($event)" @contextmenu.prevent="getConextMenu"
            @focus="hiddenInputFocus" :maxLength="30"></a-input>
        </div>
        <div class="operations">
          <a-tooltip v-if="mode === 'edit' && ( record.type === 'diy-table')" title="编辑列" v-show="mode === 'edit' && record.options.columns" arrow-point-at-center
            placement="bottom">
            <a-icon type="form" class="operatin-icon" @click="handelEditCloumnClick" />
          </a-tooltip>
          <a-popover v-if="mode !== 'pdf' && (record.type === 'equipment-table' || record.type === 'diy-table')" placement="bottom" trigger="click">
              <a-tooltip arrow-point-at-center placement="top" title="展示列">
                <a-icon class="operatin-icon" type="unordered-list" />
              </a-tooltip>
              <template slot="content">
                <div v-for="(item, index) in record.options.showColumns" :key="index">
                  <a-checkbox :disabled="item.disabled" :checked.sync="item.checked"
                    @change="(e) => handleColoumnsCheckedToggle(e, index)">
                    {{ item.label }}
                  </a-checkbox>
                </div>
              </template>
          </a-popover>
          <a-tooltip title="收起" v-show="mode !== 'pdf' && !record.options.hideCollapsed"
            arrow-point-at-center placement="bottom">
            
          </a-tooltip>
          <a-tooltip title="收起" v-show="!isCollapsed && mode !== 'pdf' && !record.options.hideCollapsed"
            arrow-point-at-center placement="bottom">
            <a-icon type="up-circle" class="operatin-icon" @click="toggoleCollapsed" />
          </a-tooltip>
          <a-tooltip title="展开" v-show="isCollapsed && mode !== 'pdf' && !record.options.hideCollapsed"
            arrow-point-at-center placement="bottom">
            <a-icon type="down-circle" class="operatin-icon" @click="toggoleCollapsed" />
          </a-tooltip>
          <a-tooltip title="全屏" v-show="itemInstance &&  !getElIsFullScreen(itemInstance.$el) && mode !== 'pdf' && !record.options.hideFullscreen"
            arrow-point-at-center placement="bottom">
            <!--a-icon type="fullscreen"  class="operatin-icon" @click="toggleFullScreen"/-->
            <svg-icon name="fullscreen" class="operatin-icon" @click="handleFullScreen"></svg-icon>
          </a-tooltip>
          <a-tooltip title="退出全屏" v-show="itemInstance && getElIsFullScreen(itemInstance.$el) && mode !== 'pdf' && !record.options.hideFullscreen"
            arrow-point-at-center placement="bottom">
            <!--a-icon type="fullscreen-exit"  class="operatin-icon" @click="toggleFullScreen"/-->
            <svg-icon name="fullscreen-exit" class="operatin-icon" @click="handleFullScreen"></svg-icon>
          </a-tooltip>
          <a-tooltip title="编辑" v-show="mode === 'edit' && record.options.options" arrow-point-at-center
            placement="bottom">
            <a-icon type="form" class="operatin-icon" @click="handelEditClick" />
          </a-tooltip>
          <a-tooltip title="评论" v-show="mode !== 'pdf' && type !== 'template' && !isVersionMode" arrow-point-at-center placement="bottom">
            <svg-icon name="comment-alt" class="operatin-icon comment-icon" @click="handleCommentClick" ></svg-icon>
          </a-tooltip>
        </div>
      </div>
    </a-tooltip>
    </template>
    <component @mousedown.stop v-show="!isCollapsed" :record="record" :style="`width:${record.options.width}`"
      @change="handleChange" @customDataChange="handleCustomDataChange" :disabled="disabled" :dynamicData="dynamicData"
      :height="
        typeof record.options.height !== 'undefined'
          ? record.options.height
          : ''
      " v-decorator="[
  record.model,
  {
    initialValue: 1,
    rules: record.rules
  }
]" :is="customComponent" :customData.sync="record.options.customData" :mode="mode" :type="type" :isFullscreen="itemInstance && getElIsFullScreen(itemInstance.$el)" class="custom-component" style="user-select: auto !important;">
    </component>
    <!-- 收起占空 -->
    <div v-show="isCollapsed" class="collapsed-content">
    </div>
    <!-- <div v-show="mode === 'preview' " class="preview-mask"></div> -->
    <!-- 编辑选项对话框 -->
    <a-modal v-if="mode === 'edit' && record.options.options" v-model="editModalVisible" :title="`编辑${record.label}选项`">
      <a-form-model ref="editFormRef" size="small" :model="editFormData" :colon="false" :labelCol="{
        xs: { span: 24 },
        sm: { span: 4 },
      }">
        <a-form-model-item size="small" v-for="(option, index) in editFormData.options" :key="index"
          :label="`选项${index + 1}`" :prop="`options.${index}.label`" :rules="{
            required: true,
            validator: checkOption,
            trigger: 'blur'
          }" v-bind="{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
          },
        }">
          <div class="option-form-item">
            <div class="option-input-ctn">
              <a-input v-model="option.label" @blur="handleOptionBlur(`options.${index}.label`)"></a-input>
            </div>
            <div class="option-operations">
              <a-tooltip title="删除该选项">
                <a-icon type="delete" class="operation-icon" @click="handleOptionDelete(index)"/>
              </a-tooltip>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label=" ">
          <a @click="handleAddOptionClick">
            + 新增选项
          </a>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <div style="text-align:center;">
          <a-button @click="editModalVisible = false">
            取消
          </a-button>
          <a-button type="primary" @click="handleConfirm">
            确定
          </a-button>
        </div>
      </template>
    </a-modal>
    <!-- 编辑列 -->
    <a-modal v-if="mode === 'edit' && record.type === 'diy-table' && columnsEditModalVisible" v-model="columnsEditModalVisible" :title="`编辑${record.label}列`"  v-bind="{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
          },
        }">
      <a-form-model ref="editColumnFormRef" size="small" :model="editColumnsForm" :colon="false" :labelCol="{
        xs: { span: 24 },
        sm: { span: 4 },
      }">
        <a-form-model-item size="small" v-for="(col, index) in editColumnsForm.columns" :key="index"
          :label="`第${index + 1}列`" :prop="`columns.${index}.title`" :rules="{
            required: true,
            validator: checkColumns,
            trigger: 'blur'
          }" v-bind="{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
          },
        }">
          <div class="option-form-item">
            <div class="option-input-ctn">
              <a-input v-model="col.title" @blur="handleColumnBlur(`columns.${index}.title`)"></a-input>
            </div>
            <div class="option-operations">
              <a-tooltip title="删除该选项">
                <a-icon type="delete" class="operation-icon" v-if="editColumnsForm.columns.length > 1" @click="handleColumnDelete(index)"/>
              </a-tooltip>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label=" ">
          <a @click="handleAddCloumnClick">
            + 新增选项
          </a>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <div style="text-align:center;">
          <a-button @click=" columnsEditModalVisible = false;">
            取消
          </a-button>
          <a-button type="primary" @click="handleEditColumnConfirm">
            确定
          </a-button>
        </div>
      </template>
    </a-modal>
  </a-form-item>
</template>

<style lang="less" scoped>
.label-container {
  padding: 10px;
  background: #FFFFFF;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;

  .title-container {
    width: 0px;
    flex-grow: 1;
    padding-right: 20px;
    min-width: 80px;
    .label-text {
      padding: 5px 12px;
      //height: 32px;
      width: fit-content;
      border: 1px solid transparent;
      cursor: text;
      border-radius: 4px;
      transition: .2s all ease;
      font-weight: bold;
    }

    .edit-text {
      &:hover {
        border-color: #e4e4e4;
        background: #ffffff;
      }
    }
  }

  .operations {
    margin-right: 80px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 30px;

    .operatin-icon {
      margin-left: 20px;
    }

    .operatin-icon {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.collapsed-content {
  height: 25px;
  background: #ffffff;
  margin-top: -10px;
}

.preview-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 199;
  background: transparent;
  top: 0;
  left: 0;
}
</style>

<style lang="less" scoped>
.option-form-item {
  display: flex;

  .option-input-ctn {
    flex-grow: 1;
  }

  .option-operations {
    margin-left: 25px;
    color: rgba(0, 0, 0, 0.65);
    fill: rgba(0, 0, 0, 0.65);
    .operation-icon {
      cursor: pointer;
    }
  }
}
</style>

<script>
import { ref, computed, nextTick, watch, onMounted } from '@vue/composition-api';
import { useDebounceFn, useFullscreen, useResizeObserver } from '@vueuse/core';
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../../../plugins/KFormDesign/utils';
import { toggleFullScreen, getElIsFullScreen } from '../../../../utils/fullSrceen';
export default {
  name: "customComponent",
  props: ["record", "formConfig", "disabled", "dynamicData", "mode", "type"],
  setup(props, context) {
    onMounted(() => {
      //console.log(props.type)
    })
    // 组件label的ant-input实例，用于点击后进行聚焦
    const labelInput = ref(null)
    // 组件label是否可以编辑
    const labelEditable = ref(false)
    // 组件是否收起
    const isCollapsed = ref(false)
    // 处理label的输入框失焦
    const handleBlur = () => {
      labelEditable.value = false;
      inputBlur();
    }
    // 组件内容 展开或收起的切换收件
    const toggoleCollapsed = () => {
      isCollapsed.value = !isCollapsed.value;
    }
    // label点击事件
    const handleLabelClick = (e) => {
      labelEditable.value = true;
      //console.log(this.$refs.labelInput)
      //this.$refs.labelInput.focus();
      context.root.$store.dispatch('experiment/setText', e.target)
      nextTick(() => {
        // console.log(labelInput.value)
        labelInput.value && labelInput.value.focus()
      })
    }

    const customComponent = computed(() => {
      // 计算需要显示的组件
      const customComponentList = {};
      if (window.$customComponentList) {
        // 将数组映射成json
        window.$customComponentList.forEach(item => {
          customComponentList[item.type] = item.component;
        });
      }
      return customComponentList[props.record.type];
    })

    const handleChange = (value, key) => {
      context.emit("change", value, key);
    }
    // 更新自定义数据
    const handleCustomDataChange = (value) => {
      context.emit("customDataChange", value);
    }

    // 组件ref实例
    const itemInstance = ref(null);
  
    // 全屏切换
    const handleFullScreen = () => {
      toggleFullScreen(itemInstance.value.$el);
    }

    // 评论点击事件
    const handleCommentClick = () => {
      context.emit('handleCommentClick', props.record)
    }

    // 编辑点击事件
    const handelEditClick = () => {
      editModalVisible.value = true;
    }
    // 编辑对话框
    const editModalVisible = ref(false);
    // 编辑form
    const editFormRef = ref(null);
    // 编辑表单的数据
    const editFormData = ref({ options: [] })
    // 每次打开同步option
    watch(() => editModalVisible.value, (newVal) => {
      if (newVal) {
        editFormData.value.options = JSON.parse(JSON.stringify(props.record.options.options))
      }
    })
    // option校验器
    const checkOption = (rule, value, callback) => {
      if (!value.toString().trim()) {
        callback(new Error('选项不能为空'))
      }
      else {
        const sames = editFormData.value.options.filter((element) => {
          return element.label.trim() === value.trim();
        });
        if (sames.length > 1) {
          callback(new Error('选项不能重复'))
        }
        else {
          callback();
        }
      }
      callback();
    }
    // 新增选项
    const handleAddOptionClick = () => {
      if (editFormData.value.options.length > 10) {
        return
      }
      editFormData.value.options.push({ label: '' })
    }
    // 删除选项
    const handleOptionDelete = (index) => {
      editFormData.value.options.splice(index, 1);
    }
    // 输入框失焦
    const handleOptionBlur = (filed) => {
      editFormRef.value.validateField(filed)
    }
    // 选择框确定
    const handleConfirm = () => {
      editFormRef.value.validate().then((valid) => {
        if(!valid) {
          return;
        }
        props.record.options.options.splice(0, props.record.options.options.length, ...JSON.parse(JSON.stringify(editFormData.value.options)));
        editModalVisible.value = false;
        if (props.record.type === 'e-multi-selection' || props.record.type === 'e-checkbox') {
          props.record.options.customData = []
        }
        else {
          props.record.options.customData = null;
        }

      }).catch(() => {

      })
    }
    // excel 加载成功
    const onExeclLoad = () => {
      //console.log(`load`)
    }

    // 展示行
    const handleColoumnsCheckedToggle = (e, index) => {
      const newTemp = JSON.parse(JSON.stringify(props.record.options.showColumns[index]));
			newTemp.checked = e.target.checked;
			props.record.options.showColumns.splice(index, 1, newTemp);
    }

    /*********************************         编辑列 start           ***********************************/
    const columnsEditModalVisible = ref(false);

    const editColumnFormRef = ref(null);
    const editColumnsForm = ref({
      columns: []
    })
    const handelEditCloumnClick = () => {
      editColumnsForm.value.columns.splice(0,editColumnsForm.value.columns.length, ...JSON.parse(JSON.stringify(props.record.options.columns)) ) ;
      columnsEditModalVisible.value = true;
    }

    const checkColumns = (rule, value, callback) => {
      if (!value.toString().trim()) {
        callback(new Error('列名不能为空'))
      }
      else {
        const sames = editColumnsForm.value.columns.filter((element) => {
          return element.title.trim() === value.trim();
        });
        if (sames.length > 1) {
          callback(new Error('列名不能重复'))
        }
        else {
          callback();
        }
      }
      callback();
    }
    
    const handleColumnBlur = (filed) => {
      editColumnFormRef.value && editColumnFormRef.value.validateField(filed)
    }

    const handleAddCloumnClick = () => {
      const dataIndex = `dataIndex${new Date().getTime()}`
      editColumnsForm.value.columns.push({
        title: '',
        dataIndex: dataIndex,
        scopedSlots: { customRender: dataIndex }
      });
    }

    const handleColumnDelete = (index) => {
      editColumnsForm.value.columns.splice(index, 1);
    }

    const handleEditColumnConfirm = () => {
      editColumnFormRef.value.validate().then((valid) => {
        if(valid) {
          const dataIndexs = editColumnsForm.value.columns.map(col => col.dataIndex);
          const deleteCloumns = props.record.options.columns.filter(col => dataIndexs.indexOf(col.dataIndex) === -1);
          const deleteKeys = deleteCloumns.map(col => col.dataIndex);
          props.record.options.columns.splice(0, props.record.options.columns.length, ...JSON.parse(JSON.stringify(editColumnsForm.value.columns)));
          const newDataIndexs = editColumnsForm.value.columns.map(col => col.dataIndex);
          const addKeys = newDataIndexs.map(col => col.dataIndex);
          const newData = props.record.options.customData.map(item => {
            const newItem = JSON.parse(JSON.stringify(item));
            for(let i = 0; i < deleteKeys.length; ++i) {
              delete newItem[deleteKeys[i]]
            }
            for(let i = 0; i < addKeys.length; ++i) {
              newItem[addKeys[i]] = "";
            }
            return newItem;
          });
        
          props.record.options.customData.splice(0,props.record.options.customData.length, ...newData);
          const newShowColumns = editColumnsForm.value.columns.map(col => {
            const origins =  props.record.options.showColumns.filter(sCol => sCol.value === col.dataIndex);
            return {
              label: col.title,
              value: col.dataIndex,
              checked: origins.length === 1 ? origins[0].checked : true
            }
          });
          newShowColumns.splice(0,1);
          props.record.options.showColumns.splice(0, props.record.options.showColumns.length, ...newShowColumns);
          columnsEditModalVisible.value = false;
        }
      })
    }

    // 是否是正在查看版本详情
    const isVersionMode = computed(() => {
      return context.root.$route.query.experimentVersionId !== undefined;
    })

    /*********************************         编辑列 end             ***********************************/
    return {
      customComponent,
      isCollapsed,
      labelEditable,
      handleBlur,
      handleLabelClick,
      handleChange,
      handleCustomDataChange,
      toggoleCollapsed,
      handleFullScreen,
      itemInstance,
      handleCommentClick,
      labelInput,
      editModalVisible,
      handelEditClick,
      editFormRef,
      editFormData,
      checkOption,
      handleAddOptionClick,
      handleOptionDelete,
      handleOptionBlur,
      handleConfirm,
      getConextMenu,
      hiddenInputFocus,
      onExeclLoad,
      getElIsFullScreen,
      //showColumns
      handleColoumnsCheckedToggle,
      handelEditCloumnClick,
      columnsEditModalVisible,
      checkColumns,
      editColumnFormRef,
      handleColumnBlur,
      editColumnsForm,
      handleAddCloumnClick,
      handleColumnDelete,
      handleEditColumnConfirm,
      isVersionMode
    }
  }
}
</script>


<!--script>

export default {
  name: "customComponent",
  props: ["record", "formConfig", "disabled", "dynamicData"],
  data: () => {
    return {
      labelEditable: false,
      isCollapsed: false
    }
  },
  computed: {
    customComponent() {
      // 计算需要显示的组件
      const customComponentList = {};
      if (window.$customComponentList) {
        // 将数组映射成json
        window.$customComponentList.forEach(item => {
          customComponentList[item.type] = item.component;
        });
      }
      return customComponentList[this.record.type];
    }
  },
  methods: {
    handleChange(value, key) {
      this.$emit("change", value, key);
    },
    handleCustomDataChange(value) {
      this.$emit("customDataChange", value);
    },
    handleBlur() {
      this.labelEditable = false;
    },
    handleLabelClick() {
      this.labelEditable = true;
      //console.log(this.$refs.labelInput)
      //this.$refs.labelInput.focus();
      this.$nextTick(() => {
        this.$refs.labelInput.focus()
      })
    },
    toggoleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script-->