/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save-version': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><clipPath id="svgicon_save-version_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#svgicon_save-version_a)"><path pid="1" d="M13.587 6.83a.8.8 0 00.799-.8v-.799a.8.8 0 00-1.598 0v.8c0 .438.36.799.799.799z" _fill="#4E5969"/><path pid="2" d="M22.141 5.07l-3.199-3.197a.788.788 0 00-.56-.24H3.994a2.404 2.404 0 00-2.398 2.398v15.987a2.404 2.404 0 002.398 2.398H19.98a2.404 2.404 0 002.397-2.398V5.63a.783.783 0 00-.237-.56zM8.391 3.232h7.195v4.277a.53.53 0 01-.52.52H8.91a.53.53 0 01-.52-.52V3.232zm8.393 17.585h-9.59v-5.515c0-.48.4-.879.878-.879h7.833c.48 0 .879.399.879.88v5.514zm3.998-.799a.8.8 0 01-.8.8h-1.558v-5.516a2.489 2.489 0 00-2.477-2.477H8.072a2.489 2.489 0 00-2.477 2.477v5.515H3.996a.8.8 0 01-.799-.799V4.031a.8.8 0 01.8-.799h2.798v4.277c0 1.16.958 2.12 2.118 2.12h6.155c1.16 0 2.119-.96 2.119-2.12V3.232h.879l2.718 2.719v14.067h-.002z" _fill="#4E5969"/></g>'
  }
})
