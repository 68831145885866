import { computed, ref } from "@vue/composition-api";
import { useScriptTag } from "@vueuse/core";

// 是否加载了
const isExcelCSSLoad = ref(false);
// 是否加载了CSS
const isCssImported = ref(false);

// all.js 是否加载
const isAllJSLoad = ref(false);

// charts 是否加载
const isChartLoad = ref(false);

// shapes是否加载
const isShapeLoad = ref(false);

// print是否加载
const isPrintLoad = ref(false);

// barcode是否加载
const isBarcodeLoad = ref(false);

// pdf是否加载
const isPDFLoad = ref(false);

// pivot是否加载
const isPivotLoad = ref(false);

// tableSheet是否加载
const isTableSheet = ref(false);

// isExcelIO 是否加载
export const isExcelIOLoad = ref(false);

// zh-resource 是否加载
const isZhResourceLoad = ref(false);

// cn-resource 是否加载
const isCNResourceLoad = ref(false);

// designer是否加载
export const isDesinerLoad = ref(false);

export const isDoneLoad = computed(() => {
    return isExcelCSSLoad.value && isCssImported.value && isAllJSLoad.value && isChartLoad.value && isShapeLoad.value && isPrintLoad.value && isBarcodeLoad.value && isPDFLoad.value && isPivotLoad.value && isTableSheet.value && isExcelCSSLoad.value && isZhResourceLoad.value && isCNResourceLoad.value && isDesinerLoad.value;
})

export const loadSpreadJS = () => {
    if (!isExcelCSSLoad.value) {
        (() => import(`./css/gc.spread.sheets.excel2013white.15.1.1.css`))();
        isExcelCSSLoad.value = true;
    }
    if (!isCssImported.value) {
        (() => import(`./css/gc.spread.sheets.designer.15.1.1.min.css`))();
        isCssImported.value = true;
    }
    if (!isAllJSLoad.value) {
        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.all.15.1.1.min.js`, (el) => {
            isAllJSLoad.value = true;
            if (!isChartLoad.value) {
                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.charts.15.1.1.min.js`, (el) => {
                    isChartLoad.value = true;
                    //console.log('ok2')
                    if (!isShapeLoad.value) {
                        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.shapes.15.1.1.min.js`, (el) => {
                            isShapeLoad.value = true;
                            //console.log('ok3')
                            if (!isPrintLoad.value) {
                                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.print.15.1.1.min.js`, (el) => {
                                    isPrintLoad.value = true;
                                    //console.log('ok4')
                                    if (!isBarcodeLoad.value) {
                                        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.barcode.15.1.1.min.js`, (el) => {
                                            isBarcodeLoad.value = true;
                                            //console.log('ok5')
                                            if (!isPDFLoad.value) {
                                                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.pdf.15.1.1.min.js`, (el) => {
                                                    isPDFLoad.value = true;
                                                    //console.log('ok6')
                                                    if (!isPivotLoad.value) {
                                                       /*  useScriptTag(`http://cdn.grapecity.com/spreadjs/hosted/scripts/plugins/gc.spread.pivot.pivottables.15.1.1.min.js`, (el) => { */
                                                       useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.pivot.pivottables.15.1.1.min.js`, (el) => {
                                                            isPivotLoad.value = true;
                                                            //console.log('ok7')
                                                            if (!isTableSheet.value) {
                                                                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.tablesheet.15.1.1.min.js`, (el) => {
                                                                    isPivotLoad.value = true;
                                                                    //console.log('ok8')
                                                                    if (!isExcelIOLoad.value) {
                                                                        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.excelio.15.1.1.min.js`, (el) => {
                                                                            isExcelIOLoad.value = true;
                                                                            //console.log('ok9')
                                                                            if (!isZhResourceLoad.value) {
                                                                                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.resources.zh.15.1.1.min.js`, (el) => {
                                                                                    isZhResourceLoad.value = true;
                                                                                    //console.log('ok10')
                                                                                    if (!isCNResourceLoad.value) {
                                                                                        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.designer.resource.cn.15.1.1.min.js`, (el) => {
                                                                                            isCNResourceLoad.value = true;
                                                                                            //console.log('ok11')
                                                                                            if (!isDesinerLoad.value) {
                                                                                                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.designer.all.15.1.1.min.js`, (el) => {
                                                                                                    GC.Spread.Sheets.LicenseKey = sheetsKey;
                                                                                                    GC.Spread.Sheets.Designer.LicenseKey = spreadKey;
                                                                                                    isDesinerLoad.value = true;
                                                                                                    //console.log(isDesinerLoad.value)
                                                                                                   
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                 })
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

   /*  if (!isAllJSLoad.value) {
        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.all.15.1.1.min.js`, (el) => {
            isAllJSLoad.value = true;
            if (!isDesinerLoad.value) {
                useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.designer.resource.cn.15.1.1.min.js`, (el) => {
                    isDesinerLoad.value = true;
                })
            }
        })
    }
 */
    /* if (!isPrintLoad.value) {
        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.print.15.1.1.min.js`, (el) => {
            isPrintLoad.value = true;
        })
    } */
    /* if (!isBarcodeLoad.value) {
        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.barcode.15.1.1.min.js`, (el) => {
            isBarcodeLoad.value = true;
        })
    } */
    /* if (!isPDFLoad.value) {
        useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.pdf.15.1.1.min.js`, (el) => {
            isPDFLoad.value = true;
        })
    } */
    /*    if (!isPivotLoad.value) {
           useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.pivot.pivottables.15.1.1.min`, (el) => {
               isPivotLoad.value = true;
           })
       } */
    /*   if (!isTableSheet.value) {
          useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.tablesheet.15.1.1.min.js`, (el) => {
              isPivotLoad.value = true;
          })
      } */
    /*  if (!isExcelIOLoad.value) {
         useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.excelio.15.1.1.min.js`, (el) => {
             isExcelIOLoad.value = true;
         })
     } */
    /*  if (!isZhResourceLoad.value) {
         useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.resources.zh.15.1.1.min.js`, (el) => {
             isZhResourceLoad.value = true;
         })
     } */
    /*  if (!isCNResourceLoad.value) {
         useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.designer.resource.cn.15.1.1.min.js`, (el) => {
             isCNResourceLoad.value = true;
         })
     } */
    /*   if (!isDesinerLoad.value) {
          useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.sheets.designer.resource.cn.15.1.1.min.js`, (el) => {
              isDesinerLoad.value = true;
          })
      } */
}

//export const spreadKey = `saims.natapp1.cc,E337171343232415#B04Y5SxBFV5g5czY7aWhGR4FHNHJkVMZjbPZGZqpmdJNldKFUT6JWZwI5bldWOodXbuRlVZlXMwp7ca9WUFRVSVxUUoFUNENWSphUN9RleVtUSN9EOrEGUiNDT4sScvIHbVpGZzYnMxpFdjNWR0h5T6kFbnhmWSpGUklzbl3ybvkUQvYES74UOmdmZnB7aGd4N5g7L9ZlbJNVeFNVZHlXOXBTb7R5daZ4UZBzQSNFUwtkePlEOOlXQV5ERapGVRR5UKFEN8xEZZV7aQVEO9U7RrVkMRNWNC9GOZlzcXJUWUhXSwIUazMncYtyQwBlYKV5YIN6S0N7KrEXR4MmUrg4UThUbXpGa0JHZ9cmUCpGOjRlewFHZiojITJCLiQDOCNERxEDNiojIIJCLxUTOxMDM5gjN0IicfJye35XX3JSS42UUiojIDJCLiUTMuYHIu3GZkFULyVmbnl6clRULTpEZhVmcwNlI0IiTis7W0ICZyBlIsIyNwYTMyADI5EDOwIjMwIjI0ICdyNkIsICNxkDMyIDMyIiOiAHeFJCLiM6YuEDcwFGdh9mLz5WahNnI0IyctRkIsICuPWOrFWOkZmeicaOgKaekneOktWetUeeq3iOkimekneug8Wun7W+v9WuI0ISYONkIsUWdyRnOiwmdFJCLiUTM4IzMyMDNzEzNxczMzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7JmUZRWMzNGbi3UYTFzQS3Scp34dJBTTjJGbaxUQ53UWJZ7VrYjeMp5YphnSrljR6x6YD9GTtFXdMFlW6AnV3VXV`;
//export const sheetsKey = `saims.natapp1.cc,E676685648342569#B0DVDmdTRu3kRFF4KiZ4T5VWb6R6cXdGOMZnTTJlTE5EcYtUToBXVpBHOrp7aPZFbpxUcq3UT7hlMr8mezFlUxYGdoVVSPVENaRVdDVjNLhmbJ3yajZkcOBVWyk5da3UQH3kQnVVNVZEd95mTud7b7Azb4oFODpHaFR4QNhnb8BTeXplcOlTMxhXN4l4UVRURstGdxIXYtpmUjhUOFdEbzRERhJFUEJ7cwwEehhVVMZFeQZXetVlSxQWNwg4UxsSUjd6cHRlVOFlWUtkTKdGZpp6RxZmW8RVYXJkeapGW7N4ZlhTcHhFNqJ7Z5InT9F6YEhTZDhzQThzSrETMCRFNvskNjpEZnJlaDVmR5Q5aUF4U6pHSiojITJCLiETNGNEOGNkNiojIIJCLyUzMxkjM7UjM0IicfJye&Qf35VfiwUQRNlI0IyQiwiI5EjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsISOzUTMyADI5EDOwIjMwIjI0ICdyNkIsICNxkDMyIDMyIiOiAHeFJCLiM6YuEDcwFGdh9mLz5WahNnI0IyctRkIsICuPWOrFWOkZmeicaOgKaekneOktWetUeeq3iOkimekneug8Wun7W+v9WuI0ISYONkIsUWdyRnOiwmdFJCLikjN5IDNzgDN6UDO6YzN6IiOiQWSiwSfdJSZsJWYUR7b6lGUislOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7FjNHN6Z64kU7MkSa3GVaV4LGN7TQR5QBFjWmdTOStiZpZUTaFXbV3iR5tmURV5NKtkdY36MvxGV5VGOr4WSroDrO`;
export const spreadKey = ``;
export const sheetsKey = ``;

export const loadExcelIO = async () => {
    const { scriptTag, load, unload } = useScriptTag(`${process.env.BASE_URL}scripts/gc.spread.excelio.15.1.1.min.js`, (el) => {
        isExcelIOLoad.value = true;
    },
    { manual: true });
    await load();
}