<template>
    <a-modal :width="800" :visible="visible" title="导入文档" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
        <div class="search-ctn">
            <a-input style="width: 220px;" v-model="searchValue" placeholder="请输入关键词"></a-input>
            <a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
            <a-button class="reset-btn" @click="handleReset"> 重置 </a-button>
        </div>
        <a-table rowKey="fileId" :loading="loading" size="small" :columns="columns" :data-source="tableData"
            :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps, type: selectType }">
            <template slot="fileSize" slot-scope="text, record, index">
                <div v-if="record.fileSize != ''">
                    {{ transferBitSize(record.fileSize)  }}
                </div>
            </template>
        </a-table>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="total"
                show-less-items />
        </div>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.search-ctn {
    margin-bottom: 24px;

    .search-btn {
        margin-left: 24px;
    }

    .reset-btn {
        background: #eeeeee;
        margin-left: 16px;
    }
}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

/deep/ .ant-table-thead {
    background: #F0F5FF;
}

/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px;
}

.default-pagination {
    text-align: center;
    margin-top: 24px;
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';
import { getExperimentDocumentList } from '../../../../../api/experiment';
import { transferBitSize } from '@/utils/utils';
const columns = [
    {
        dataIndex: 'fileName',
        title: '文件名',
        width: '35%',
        scopedSlots: { customRender: 'fileName' }
    },
    {
        dataIndex: 'fileExtensionName',
        title: '文件格式',
        width: '15%',
        scopedSlots: { customRender: 'fileExtensionName' }
    },
    {
        dataIndex: "fileSize",
        title: "文件大小",
        with: "15%",
        scopedSlots: { customRender: "fileSize" }
    },
    {
        dataIndex: 'source',
        title: '文件来源',
        width: '20%',
        scopedSlots: { customRender: 'model' }
    },
    {
        dataIndex: 'projectName',
        title: '项目名称',
        width: '25%',
        scopedSlots: { customRender: 'projectName' }
    }
]
const eqiupmentList = [

]
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        // 不能选中列表
        disabledList: {
            type: Array,
            default: () => []
        },
        selectType: {
            default: 'checkbox'
        }
    },
    setup(props, context) {
        const formData = ref({
        });
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);
        // 已选择的key值
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.fileId);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if (allKeys.indexOf(element.fileId) === -1) {
                    selectedRows.value.push(element)
                }
            })
            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        }
        const tableData = ref([
        ])
        const current = ref(1);
        const searchValue = ref('');
        const loading = ref(false);
        const total = ref(0)
        const handlePageChange = (page, pageSize) => {
            // 将已选行中取消选择的脏数据去除
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.fileId) !== -1;
            });
            getFileList();
        }
        const handleConfirm = () => {
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.fileId) !== -1;
            });
            if (selectedRows.value.length !== 0) {
                context.emit('importConfirm', selectedRows.value);
            }
            context.emit('update:visible', false)
        }
        const getFileList = async () => {
            loading.value = true;
            const res = await getExperimentDocumentList({
                projectId: context.root.$route.query.projectId,
                experimentId: context.root.$route.query.id,
                searchValue: searchValue.value,
                pageNo: current.value,
                pageSize: 10
            })
            if (res.code === 200) {
                tableData.value = res.data.rows;
                total.value = res.data.totalRows;
                loading.value = false;
            }
        }
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                selectedRowKeys.value = [];
                selectedRows.value = [];
                searchValue.value = '';
                getFileList();
            }
        })
        const getCheckboxProps = (record) => {
            return {
                props: {
                    disabled: props.disabledList.indexOf(record.fileId) !== -1
                }
            }
        }
        const handleSearch = () => {
            current.value = 1;
            getFileList();
        }
        const handleReset = () => {
            searchValue.value = '';
        }
        return {
            formData,
            formRef,
            handleCancel,
            columns,
            selectedRowKeys,
            onSelectChange,
            tableData,
            current,
            handlePageChange,
            handleConfirm,
            getCheckboxProps,
            loading,
            searchValue,
            total,
            handleSearch,
            handleReset,
            transferBitSize
        }
    }
}
</script>