<template>
    <div class="table-container diy-table">
        <div class="btns">
            <div class="left-btns">
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增 </a-button>
            </div>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick" :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="showColumns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode === 'edit' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null" style="width: 100%;">
            <template v-for="(col, columIndex) in showColumns.map(col => col.dataIndex)" :slot="col" slot-scope="text, record, index">
                <div :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'" :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="($event) => handleHiddenInputClick(col, columIndex, index, $event)">
                        <div v-for="(item, index) in handleSplitText(customData[index][col])" class="split-text-item" style="word-break: break-all;">
                            {{ item }}
                        </div>
                    </div>
                    <div style="max-width: 400px;" v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <a-input   :autoSize="true" autoFocus type="textarea" @contextmenu.prevent="getConextMenu" v-model="customData[index][col]" :ref="`${col}${index}`" @focus="hiddenInputFocus" @blur="handleBlur" >
                        </a-input>
                    </div>
                </div>
            </template>
        </a-table>
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;
        .ant-btn {
            width: 58px;
        }
        .operation-btn {
            background: #eeeeee;
        }
        .left-btns {
            width: 0px;
            flex-grow: 1;
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
        word-break: break-all;
        .split-text-item + .split-text-item {
            margin-top: 8px;
        }
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    // props: {
    //   record: {
    //     type: Object,
    //     require: true
    //   },
    //   value: {
    //     type: String,
    //     default: ''
    //   }
    // },
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            isEditting: false,
            rowIndex: null,
            colIndex: null,
            selectedRowKeys: [], // 选中行的key 列表
            datePickerOpen: false,
        };
    },
    methods: {
        handleChange() {
            const keys = this.record.options.columns.map(col => col.dataIndex);
            const newItem = { 
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                key: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].key + 1,
            };
            for(let i = 0; i < keys.length; ++i) {
                newItem[keys[i]] = "";
            }
            this.customData.push(newItem);
        },
        // 其他隐藏组件点击事件
        handleTextClick(col, columIndex, index) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            //const that = this;
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 隐藏的输入框点击事件
        handleHiddenInputClick(col, columIndex, index, e) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            this.$store.dispatch("experiment/setText", e.target);
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 输入框隐藏事件
        isInputShow(index, columIndex) {
            return this.isEditting && this.rowIndex === index && this.colIndex === columIndex;
        },
        // 输入框失焦失焦， 恢复label形式
        handleBlur() {
            this.rowIndex = null;
            this.colIndex = null;
            this.isEditting = false;
            inputBlur();    // 时间戳不可选
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            /* this.$confirm({
                title: '是否确认删除？',
                onOk() {
                    return new Promise((resolve, reject) => {
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    }).catch(() => console.log('Oops errors!'));
                },
                onCancel() { },
            }); */
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        handleOpenChange(status) {
            this.datePickerOpen = status;
            if(!this.datePickerOpen) {
                this.rowIndex = null;
                this.colIndex = null;
                this.isEditting = false;
                inputBlur();
            }
        },
        handleDatePickerBlur() {
            if(!this.datePickerOpen) {
                this.rowIndex = null;
                this.colIndex = null;
                this.isEditting = false;
                inputBlur();
            } 
        },
        // 拆分str        
        handleSplitText(text) {
            if(!text) {
                return []
            }
            const arr = text.split('\n');
            return arr;
        }
     },
    computed: {
        showColumns() {
            const showColumns = this.record.options.showColumns.filter(col => col.checked);
            const showKeys = showColumns.map(col => col.value);
            return this.record.options.columns.filter(col => {
                return col.dataIndex === 'name' || showKeys.indexOf(col.dataIndex) !== -1;
            })
        },
        // 已导入的仪器
        usedEquiments() {
            const importedList = this.customData.filter(imp => imp.id);
            return importedList.map(imp => imp.id);
        }
    }
};
</script>