const getters = {
    inputInstance: state => state.experiment.inputInstance,
    textInstance: state => state.experiment.textInstance,
    isInputFocus: state => state.experiment.isInputFocus,
    editorInstance: state => state.experiment.editor,
    editorSelectionIndex: state => state.experiment.editorSelectionIndex,
    editorSelectionLength: state => state.experiment.editorSelectionLength,
    addExperimentTrigger: state => state.trigger.addExperimentTrigger,
    experimentDownloadFileList: state => state.experiment.downloadFileList
}

export default getters;