/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'template': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_template_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_template_a)"><path pid="1" d="M7.556 9.778V22H3.11A1.111 1.111 0 012 20.889V9.778h5.556zm14.444 0v11.11c0 .614-.497 1.112-1.111 1.112H9.778V9.778H22zM20.889 2C21.503 2 22 2.497 22 3.111v4.445H2V3.11C2 2.497 2.497 2 3.111 2H20.89z" _fill="#4E5969"/></g>'
  }
})
