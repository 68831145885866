/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<clipPath id="svgicon_folder_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath><g clip-path="url(#svgicon_folder_a)" style="mix-blend-mode:passthrough"><path pid="1" d="M2.667 2.667A.667.667 0 002 3.333v9.334a.667.667 0 00.667.666h10.666a.667.667 0 00.667-.666V5.333a.667.667 0 00-.667-.666h-6a.667.667 0 01-.554-.297L5.643 2.667H2.667zm-1.415-.748a2 2 0 011.415-.586H6c.223 0 .431.112.555.297L7.69 3.333h5.643a2 2 0 012 2v7.334a2 2 0 01-2 2H2.667a2 2 0 01-2-2V3.333a2 2 0 01.585-1.414z" fill-rule="evenodd" _fill="#2F54EB"/></g>'
  }
})
