/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'authored-experiment': {
    width: 13.379,
    height: 14.666,
    viewBox: '0 0 13.379 14.666',
    data: '<path pid="0" d="M11.624 8.88L9.37 4.196V1.333h1.32a.667.667 0 100-1.333h-8a.667.667 0 100 1.333h1.34v2.86l-2.347 4.85.002.001L.18 12.141q-.315.662-.076 1.346.237.682.887 1.003.367.176.775.176h9.854q.726 0 1.242-.514.518-.515.518-1.246 0-.388-.179-.761l-1.57-3.26-.006-.004zm-1.931-.938L8.036 4.5V1.333H5.362V4.5L3.144 9.082q.764.417 1.446.417.86 0 1.675-.679 1.186-.987 2.53-.987.445 0 .898.11zm-8.315 4.782l1.186-2.44q1.032.549 2.026.549 1.344 0 2.529-.988.815-.68 1.676-.68.834 0 1.789.62l1.413 2.935q.049.1.049.186 0 .177-.126.302-.125.125-.301.125H1.765q-.105 0-.184-.038-.161-.08-.22-.247-.057-.167.017-.324z" fill-rule="evenodd" _fill="#2F54EB"/>'
  }
})
