// 从index规整过来的接口， 统一使用request预处理
import  request from "../utils/request";

// 返回我的云盘文件夹树状结构
export const returnfolderpath = () => {
    return request({
        method: 'get',
        url: '/mycloud/returnfolderpath'
    })
}

//删除云盘文件夹
export const deletefoldername = (params) => {
	//return axios.post('/mycloud/deletefoldername' + data)
    return request({
        method: 'post',
        url: '/mycloud/deletefoldername',
        params
    })
}

// 返回云盘参与项目
export const  returnmyproject = () => {
	//return axios.get('/projectcloud/returnmyproject')
    return request({
        method: 'get',
        url: '/projectcloud/returnmyproject'
    })
}

// 移动文件路径
export const movefilepath = (params) => {
	//return axios.post('/mycloud/movefilepath'+data)
    return request({
        method: 'post',
        url: '/mycloud/movefilepath',
        params
    })
}

// 我的云盘查询文件
export const returnmycloudfile = (params) => {
	//return axios.get('/mycloud/returnmycloudfile' + data)
    return request({
        method: 'get',
        url: '/mycloud/returnmycloudfile',
        params
    })
}

//查询云盘容量
export const returnmycloudcapacity = () => {
    return request({
        method: 'get',
        url: '/mycloud/returnmycloudcapacity'
    })
	//return axios.get('/mycloud/returnmycloudcapacity') 
}

//删除云盘文件
export function deletefile(params) {
	return request({
        method: 'post',
        url: '/mycloud/deletefile',
        params
    })
    //return axios.post('/mycloud/deletefile'+data) 
}

//文件重命名
export const editfilename = (params) =>  {
	//return axios.post('/mycloud/editfilename'+data)
    return request({
        method: 'post',
        url: '/mycloud/editfilename',
        params
    })
}

//重命名项目组云盘文件
export const edititemfilename = (params) => {
	//return axios.post('/projectcloud/editfilename'+data)
    return request({
        method: 'post',
        url: '/projectcloud/editfilename',
        params
    })
}

// 返回项目云盘中的文件
export const returnprojectfiles = (params) => {
    return request({
        method: 'get',
        url: '/projectcloud/returnprojectfiles',
        params
    })
}

// 删除项目云盘的文件
export const deleteitemfile = (params) => {
    return request({
        method: 'post',
        url: '/projectcloud/deletefile',
        params
    })
}

// 分享文件到项目组
export const addprojectfile = (params) => {
    return request({
        method: 'post',
        url: '/mycloud/addprojectfile',
        params
    })
	//return axios.post('/mycloud/addprojectfile'+data) 
}

// 复制项目组文件到个人文件夹
export const copyFileToMyCloud = (params) => {
    return request({
        method: 'post',
        url: '/projectcloud/uploadfile',
        params
    })
	//return axios.post('/projectcloud/uploadfile'+data)
}

//云盘扩容
export const ApplyDilatation = (params) => {
    return request({
        method: 'post',
        url: '/mycloud/ApplyDilatation',
        params
    })
}

// 新增云盘文件夹
export const addfolder = (params) => {
    return request({
        method: 'post',
        url: '/mycloud/addfolder',
        params
    })
	//return axios.post('/mycloud/addfolder' + data) 
}

// 文件夹重命名
export const editfoldername = (params) => {
    return request({
        method: 'post',
        url: '/mycloud/editfoldername',
        params
    })
	//return axios.post('/mycloud/editfoldername' + data) 
}

// 邮箱手机号登录
export const login = (data) => {
    return request({
        method: 'post',
        url: '/user/login',
        data
    })
	//return axios.post('/user/login', data) 
}

// 验证码登录，获取短信验证码
export const getCode = (data) => {
    return request({
        method: 'post',
        url: '/user/getCode',
        data
    })
	//return axios.post('/user/getCode', data) 
}

// 手机号登录
export const phoneCodeLogin = (data) =>  {
    return request({
        method: 'post',
        url: '/user/phoneCodeLogin',
        data
    })
	//return axios.post('/user/phoneCodeLogin', data) 
}

// 获取rsa公钥
export const getPublicRsaKey = () => {
    return request({
        method: 'post',
        url: '/user/getPublicRsaKey'
    })
	//return axios.post('/user/getPublicRsaKey') 
}

// 个人中心查看用户信息
export const getUserInfo = (data) => {
    return request({
        method: 'get',
        url: '/user/getUserInfo'
    })
	//return axios.get('/user/getUserInfo', data) 
}

// 修改头像
export const changePhoto = (data) => {
    return request({
        method: 'post',
        url: ''
    })
	//return axios.post('/user/changePhoto', data) 
}

// 注销
export const logout = () => {
    return request({
        method: 'get',
        url: '/user/logout'
    })
	//return axios.get('/user/logout', data) 
}

/* // 查询所有项目
export function getmycreateprogect(data) {
	return axios.get('/project/getmycreateproject'+data) 
}
 */

// 工作台查询所有参与新建项目
export const getmyworkcreateprogect = (params) => {
    return request({
        method: 'get',
        url: '/workbench/getmycreateprogect',
        params
    })
}

//工作台查询项公告
export const querynoticepageworklist = (params) => {
    return request({
        method: 'get',
        url: '/workbench/querynoticepagelist',
        params
    })
}

// 工作台实验查询
export const getMyExperimentList = (params) => {
    return request({
        method: 'get',
        url: '/workbench/getMyExperimentList',
        params
    })
}

//个人中心修改密码
export const changePwd = (data) => {
    return request({
        method: 'post',
        url: '/user/changePwd',
        data
    })
}

// 个人中心发送更改密码验证码
export const sendPhoneCodebyChangePwd = () => {
    return request({
        method: 'post',
        url: '/user/sendPhoneCodebyChangePwd'
    })
}

// 登录日志
export const pageList = (params) => {
    return request({
        method: 'get',
        url: '/loginLog/queryloginlogpagelist',
        params
    })
}

// 修改职位
export const updatePos = (data) => {
	return request({
		method: 'post',
		url: '/user/updatePos',
		params:data
	}) 
}

// 初始化职位信息
export const posInfoInit = () => {
    return request({
        method: 'get',
        url: '/user/posInfoInit'
    })
	//return axios.get('/user/posInfoInit') 
}

// 重置密码
export const ResetPwd = (data) => {
    return request({
        method: 'post',
        url: '/user/ResetPwd',
        data
    })
	//return axios.post('/user/ResetPwd', data) 
}

// 邮箱发送验证码
export const sendCodeFromEmail = (params) => {
    return request({
        method: 'get',
        url: '/user/sendCodeFromEmail',
        params
    })
	//return axios.get('/user/sendCodeFromEmail' + data) 
}

// 修改邮箱
export const updateEmail = (data) =>  {
    return request({
        method: 'post',
        url: '/user/updateEmail',
        data
    })
	//return axios.post('/user/updateEmail', data) 
}

// 分页查询通知公告
export const querynoticepagelist = (params) => {
    return request({
        method: 'get',
        url: '/Notification/querynoticepagelist',
        params
    })
	//return axios.get('/Notification/querynoticepagelist'+data) 
}

// 查询通告详情
export const getsignnotice = (params) => {
    return request({
        method: 'get',
        url: '/Notification/getsignnotice',
        params
    })
	//return axios.get('/Notification/getsignnotice'+data) 
}


// 获取工作台待审核实验
export const getWorkbenchMyAuditExperimentPageList = () => {
    return request({
        method: 'get',
        url: '/workbench/myaduitexperiment'
    })
}

// 获取我的待审核项目
export const getMyAuditProjectList = () => {
    return request({
        method: 'get',
        url: '/workbench/getmyaduitproject'
    })
}

// 待审核实验查询
export const getMyAuditExperimentList = (data) => {
    return request({
        method: 'post',
        url: '/workbench/workbenchmyaduitexperimentpagelist',
        data
    })
}