/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<clipPath id="svgicon_notebook_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath><g clip-path="url(#svgicon_notebook_a)"><path pid="1" d="M2 2q0-.551.391-.942.391-.391.942-.391h9.334q.55 0 .942.39Q14 1.45 14 2v12q0 .551-.391.942-.391.391-.942.391H3.333q-.55 0-.942-.39Q2 14.55 2 14V2zm4 12h6.667V2H6v12zM4.667 2H3.333v12h1.334V2zM8 3.333h2.667a.667.667 0 110 1.334H8a.667.667 0 010-1.334zM10.667 6H8a.667.667 0 000 1.333h2.667a.667.667 0 000-1.333z" fill-rule="evenodd" _fill="#4E5969"/></g>'
  }
})
