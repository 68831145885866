<template>
<div v-if="mode === 'edit'" class="checkbox-ctn">
    <!-- <a-checkbox v-for="(item,index) in record.options.options" :checked="item.checked" :key="index" @change="(e) => handleChange(e,index, item)">
        {{ item.label }}
    </a-checkbox> -->
    <a-checkbox-group
      :value="customData"
      @change="handleChange"
    >
        <a-checkbox v-for="(item,index) in record.options.options" :key="index" :value="item.label">
        {{ item.label }}
        </a-checkbox>
    </a-checkbox-group>
</div>
<div v-else class="checkbox-ctn read-only">
    <a-checkbox-group
      :value="customData"
      @change="() => { }"
    >
        <a-checkbox v-for="(item,index) in record.options.options" :key="index" :value="item.label">
        {{ item.label }}
        </a-checkbox>
    </a-checkbox-group>
</div>
</template>

<style lang="less" scoped>
.checkbox-ctn {
    padding: 10px;
    min-height: 50px;
}
.read-only {
    /deep/ .ant-checkbox, .ant-checkbox-input {
        cursor: not-allowed;
    }
}
</style>

<script>
import { computed } from '@vue/composition-api';
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props, context) {
        const handleChange = (checkedValues) => {
            props.customData.splice(0,props.customData.length, ...JSON.parse(JSON.stringify(checkedValues)) );
        }
        const options = computed(() => {
            return props.record.options.options.map((element) => {
                return element.label;
            })
        })
        return {
            handleChange,
            options
        }
    }
}
</script>