// 实验记录接口服务
import  request from "../utils/request";

// 实验管理，试验记录
export const getExperimentList = (data) => {
    return request({
        method: 'post',
        url: `/experiment/pageList`,
        data
    })
}

// 获取展示的字段
export const getFieldList = (params) => {
    return request({
        method: 'get',
        url: `/experiment/fieldsDisplay`,
        params
    })
}

// 设置展示字段
export const setFieldList = (params) => {
    return request({
        method: 'get',
        url: `/experiment/setFieldsDisplay`,
        params
    })
}

// 获取新增实验初始化数据
export const getAddExperimentInitData = (params) => {
    return request({
        method: 'post',
        url: `/experiment/addExperimentInit`,
        params
    })
}

// 新增实验
export const addNewExperiment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/add`,
        data
    })
}

// 更新试验记录
export const editExperiment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/edit`,
        data
    })
}

// 最近打开的实验
export const getRecentExperimentHistoryList = (params) => {
    return request({
        method: 'get',
        url: `/experiment/experimentRecordHistory`,
        params
    })
}

// 授权的实验
export const getAuthorizationExperiment = () => {
    return request({
        method: 'get',
        url: `/experiment/authorizationExperiment`
    })
}

// 获取收藏的实验
export const getCollectionExperimentList = () => {
    return request({
        method: 'get',
        url: `/experiment/myCollectionExperiment`
    })
}

// 收藏实验
export const addMyCollection = (params) => {
    return request({
        method: 'get',
        url: `/experiment/addMyCollection`,
        params
    })
}

// 取消收藏
export const cancleMyCollection = (params) => {
    return request({
        method: 'get',
        url: `/experiment/cancelMyCollection`,
        params
    })
}

// 获取项目下的其他用户
export const getProjectUser = (params) => {
    return request({
        method: 'get',
        url: `/experiment/projectUser`,
        params
    })
}

// 获取试验记录授权的用户
export const authorizationUser = (data) => {
    return request({
        method: 'post',
        url: `/experiment/authorizationUser`,
        data
    })
}

// 克隆实验初始化数据
export const getCopyExperimentInitData = (params) => {
    return request({
        method: 'get',
        url: `/experiment/copyExperimentInit`,
        params
    })
}

// 克隆实验
export const copyExperiment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/copyExperiment`,
        data
    })
}

// 获取实验详情
export const getExperimentDetail = (params) => {
    return request({
        method: `get`,
        url: '/experiment/detail',
        params
    })
}

// 获取实验操作记录
export const getExperimentOperationRecord = (params) => {
    return request({
        method: 'get',
        url: `/experiment/experimentOperationRecordPageList`,
        params
    })
}

// 获取实验版本记录
export const getExperimentVersionRecord = (params) => {
    return request({
        method: 'get',
        url: `/experiment/experimentVersionRecordPageList`,
        params
    })
}

// 实验记录回滚
export const experimentRollBackVerion = (data) => {
    return request({
        method: 'post',
        url: `/experiment/rollBackExperiment`,
        data
    })
}

// 实验版本详情
export const getExperimentVersionDetail = (params) => {
    return request({
        method: 'get',
        url: '/experiment/getExperimentVersionDetail',
        params
    })
}

// 申请归档
export const requeStarchiving = (data) => {
    /* if(data.passWord) {
        data.passWord = setEncrypt(data.passWord)
    } */
    return request({
        method: 'post',
        url: `/experiment/requestarchiving`,
        data
    })
}

// 申请归档 - 密码校验
export const verifyPassword = (data) => {
    /* if(data.passWord) {
        data.passWord = setEncrypt(data.passWord)
    } */
    return request({
        method: 'post',
        url: `/experiment/verifypassword`,
        data
    })
}

// 撤销申请
export const retractarchivingExperiment = (params) => {
    /* if(data.passWord) {
        data.passWord = setEncrypt(data.passWord)
    } */
    return request({
        method: 'post',
        url: `/experiment/retractarchiving`,
        params
    })
}

// 审核
export const auditExperiment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/adultExperiment`,
        data
    })
}

// 实验报告初始化数据
export const getReportInitData = (params) => {
    return request({
        method: 'get',
        url: `/experiment/experimentReport`,
        params
    })
}

// 添加对实验模块的评论
export const addComment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/addComment`,
        data
    })
}

// 添针对评论的评论
export const addCommentToComment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/addCommentToCommnet`,
        data
    })
}

// 获取实验流程状态
export const getExperimentStatusFlow = (data) => {
    return request({
        method: 'post',
        url: `/experiment/getExperimentStatusFlow`,
        data
    })
}

// 上传文件
export const uploadFileInExperiment = (params,data, onUploadProgress) => {
    return request({
        method: 'post',
        url: `/experiment/uploadFile`,
        params,
        data,
        timeout: 3600000,
        onUploadProgress: onUploadProgress
    })
}

// 下载文件
export const downloadExperimentFile = (params, onDownloadProgress) => {
    return request({
        method: 'get', 
        url: `/experiment/downloadFile`,
        params,
        responseType: 'blob',
        timeout: 3600000,
        onDownloadProgress: onDownloadProgress
    })
}

// 获取文件路径
export const getFileUrl = (params) => {
    return request({
        method: 'post',
        url: `/experiment/getFileUrl`,
        params
    })
}

// 导入后复制文件
export const copyFileIntoExperiment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/copyFile`,
        data,
        timeout: 3600000
    })
}

// 获取文件路径
export const getExperimentFilePath = (params) => {
    return request({
        method: 'post',
        url: `/experiment/getFileUrl`,
        params
    })
}

// 实验在线编辑实验心跳
export const onlineEditExperimentHeartBeat = (params) => {
    return request({
        method: 'get',
        url: `/experiment/onlineEditExperimentHeartBeat`,
        params
    })
}

// 停止心跳
export const closeOnlineEditExperiment = (data) => {
    return request({
        method: 'post',
        url: `/experiment/closeOnlineEditExperiment`,
        data
    })
}

// 检测是否有人在线编辑
export const getAntEditExperiment = (params) => {
    return request({
        method: 'get',
        url: `/experiment/anyEditExperiment`,
        params
    })
}

// 实验仪器列表
export const getExperimentAppraratus = (data) => {
    return request({
        method: 'post',
        url: `/experimentalapparatus/query`,
        data
    })
}

// 实验材料列表
export const getExperimentMaterials = (data) => {
    return request({
        method: 'post',
        url: `/experimentalmaterials/query`,
        data
    })
}

// 导入实验文档列表
export const getExperimentDocumentList = (data) => {
    return request({
        method: 'post',
        url: `/experiment/cloudDiskFileList`,
        data
    })
}

// 关联实验列表
export const getRelatedExperimentList = (data) => {
    return request({
        method: 'post',
        url: `/experiment/relationExperiment`,
        data
    })
}

// 审核 - 查看诚信条款下拉
export const getGoodfaithclauseList = () => {
    return request({
        method: 'get',
        url: `/experiment/getGoodFaithClauseLst`
    })
}

// 生成报告- 操作记录接口
export const makeExperimentReport = (params) => {
    return request({
        method: 'post',
        url: `/experiment/exportExperimentOperation`,
        params
    })
}

// 根据记录本id获取项目指定的实验模板列表
export const getExperimentTemplateByNotebookId = (params) => {
    return request({
        method: 'post',
        url: `/experiment/getExperimentTemplateByProjectId`,
        params
    })
}

// 获取批阅信息
export const getReviewInfo = (params) => {
    return request({
        method: 'get',
        url: `/experiment/getReviewInformation`,
        params
    })
}

// 获取申请备注
export const getApplyRemark = (params) => {
    return request({
        method: 'get',
        url: '/experiment/getRequestSaveFile',
        params
    })
}

// 管理员方式 获取实验详情
export const getExperimentDetailFromBG = (params) => {
    return request({
        method: 'get',
        url: '/BgExperiment/get',
        params
    })
}

// 获取实验权限校验
export const getExperimentPermission = (params) => {
    return request({
        method: 'get',
        url: '/experiment/GetExperimentPermission',
        params
    })
}

// 查询实验仪器记录
export const getExternalEquitmentData = (params) => {
    return request({
        method: 'get',
        url: '/experiment/getExternalEquipmentData',
        params
    })
}

// 获取仪器预约记录
export const getEquitmentAppointmentData = (params) => {
    return request({
        method: 'get',
        url: `/experiment/getExternalAppointmentData`,
        params
    })
}

// 获取仪器上下机记录
export const getEquitmentUsedRecord = (params) => {
    return request({
        method: 'get',
        url: `/experiment/getExternalRecordData`,
        params
    })
}

// 保存版本
export const saveVersion = (data) => {
    return request({
        method: 'post',
        url: `/experiment/SaveExperimentVersion`,
        data
    })
}

// 实验报告pdf
export const getExperimentReport = (params) => {
    return request({
        method: 'get',
        timeout: 360000,
        url: '/experiment/ExportPdf',
        params,
        responseType: 'blob',
    })
}

// 删除实验
export const cancelExperiment = (params) => {
    return request({
        method: 'get',
        url: '/experiment/cancelExperiment',
        params
    })
}

// 增加实验类型接口服务
export const customExperimentType = (data) => {
    return request({
        method: 'post',
        url: '/customExperimentType/add',
        data
    })
}

// 获取图文编辑模板列表
export const getCustomTemplateContentPage = (params) => {
    return request({
        method: 'post',
        url: '/experiment/customTemplateContentPage',
        params
    })
}

// 获取图文编辑模板详情
export const getCustomTemplateContentDetail = (params) => {
    return request({
        method: 'post',
        url: '/experiment/customTemplateContentDetail',
        params
    })
}

// 获取实验评论
export const getExperimentComment = (params) => {
    return request({
        method: 'get',
        url: '/experiment/getExperimentModuleComments',
        params
    })
}