/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bbs-star': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><clipPath id="svgicon_bbs-star_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath></defs><g clip-path="url(#svgicon_bbs-star_a)"><path pid="1" d="M.885 8.267L3.258 10l-.901 2.792c-.3.889.022 1.869.789 2.408a2.118 2.118 0 002.533-.012L8 13.48l2.321 1.706a2.151 2.151 0 003.322-2.394L12.742 10l2.373-1.733a2.151 2.151 0 00-1.266-3.888h-2.916l-.884-2.757a2.151 2.151 0 00-4.098 0l-.884 2.757H2.154A2.151 2.151 0 00.887 8.267H.885z"/></g>'
  }
})
