<template>
    <div class="table-container">
        <div class="btns">
            <div class="left-btns">
                <!-- <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增 </a-button> -->
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleAddReferenceButtonClick"> 新增 </a-button>
            </div>
            <a-button size="small" v-show="mode === 'edit'" :disabled="!(selectedRowKeys.length == 1)" @click="handleModifyReferenceButtonClick"> 编辑 </a-button>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick" :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="columns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode === 'edit' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null">
            <template slot="fileName" slot-scope="text, record, index">
                <a-tooltip v-if="!getFileTypeSupported(record)" title="暂不支持该类型文件预览">
                    <a style="cursor: not-allowed;"> {{ record.fileName }}{{ record.fileExtensionName  }} </a>
                </a-tooltip>
                <a-tooltip v-else @click.prevent="handlePreviewClick(record, index)" title="点击预览">
                    <a v-if="mode === 'edit'"  target="_blank"> {{ record.fileName }}{{ record.fileExtensionName }} </a>
                    <a v-else >{{ record.fileName }} {{ record.fileExtensionName  }}</a>
                </a-tooltip>
            </template>
        </a-table>
        <!-- <addOrModifySampleModal :visible.sync="addOrModifySampleModalVisible" :sampleInfo="edittingSample" @onAdd="handleAddSample" @onModify="handleModifySample"></addOrModifySampleModal> -->
        <AddOrModifyRefrenceModal :visible.sync="addOrModifyReferenceModalVisible" :referenceInfo="edittingReference" @onAdd="handleAddReference" @onModify="handleModifyReference"></AddOrModifyRefrenceModal>
        <FilePreviewModal v-if="filePreviewModalVisible" :visible.sync="filePreviewModalVisible"
            :fileType="filePreviewType" :fileUrl="filePreviewUrl" :fileName="filePreviewName"></FilePreviewModal>
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;
        .ant-btn {
            min-width: 58px;
        }
        .operation-btn {
            background: #eeeeee;
        }
        .left-btns {
            width: 0px;
            flex-grow: 1;
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { getConextMenu, hiddenInputFocus } from '../../utils';
import AddOrModifyRefrenceModal from './components/addOrModifyRefrenceModal.vue';
import { getFileTypeBySuffix } from '../../../../utils/enums';
import cloneDeep from 'lodash/cloneDeep';
import { getExperimentFilePath } from '../../../../api/experiment';
import FilePreviewModal from '../experimentDocumentTable/components/filePreviewModal.vue';
import { Base64 } from 'js-base64';
import axios from 'axios';
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            columns: [
                {
                    dataIndex: "auther",
                    title: "作者",
                    width: "10%",
                    scopedSlots: { customRender: "auther" }
                },
                {
                    dataIndex: "name",
                    title: "论文名称",
                    width: "23%",
                    scopedSlots: { customRender: "name" }
                },
                {
                    dataIndex: "periodical",
                    title: "期刊名称",
                    width: "15%",
                    scopedSlots: { customRender: "periodical" }
                },
                {
                    dataIndex: "school",
                    title: "学校",
                    width: "13%",
                    scopedSlots: { customRender: "school" }
                },
                {
                    dataIndex: "year",
                    title: "年份",
                    width: "8%",
                    scopedSlots: { customRender: "year" }
                },
                {
                    dataIndex: "issue",
                    title: "期",
                    width: "10%",
                    scopedSlots: { customRender: "issue" }
                },
                {
                    dataIndex: "page",
                    title: "起止页码",
                    width: "12%",
                    scopedSlots: { customRender: "page" }
                },
                /* {
                    dataIndex: "fileName",
                    title: "名称",
                    width: "30%",
                    scopedSlots: { customRender: "fileName" }
                }, */
                {
                    dataIndex: "fileName",
                    title: '附件',
                    width: "10%",
                    scopedSlots: { customRender: "fileName" }
                }
            ],
            selectedRowKeys: [],
            addOrModifyReferenceModalVisible: false,
            edittingReference: null, // 正在编辑的样品,
            filePreviewModalVisible: false, // 文件预览对话框
            filePreviewUrl: '', // 文件预览路径 或 excelfile
            filePreviewType: '', // 文件预览类型
            filePreviewName: '', // 文件预览名
            importEquipmentModalVisible: false,  // 导入对话框
        };
    },
    methods: {
        handleChange() {
            this.customData.push({
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                key: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].key + 1,
                name: "",
                code: "",
                model: "",
                date: "",
                company: "",
                location: "",
                precision: ""
            });
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            /* this.$confirm({
                title: '是否确认删除？',
                onOk() {
                    return new Promise((resolve, reject) => {
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    }).catch(() => console.log('Oops errors!'));
                },
                onCancel() { },
            }); */
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        // 导入点击事件
        handleImportClick() {
            this.importExperimentMaterialModalVisible = true;
        },
        // 导入确认事件
        handleImportConfirm(rows) {
            const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for (let i = 0; i < rows.length; ++i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(rows[i]))
                });
            }
        },
        // 添加样品点击
        handleAddReferenceButtonClick() {
            this.edittingReference = null;
            this.addOrModifyReferenceModalVisible = true;
        },
        // 添加样品回调
        handleAddReference(reference) {
            const newRef =  cloneDeep(reference);
            newRef.index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1;
            this.customData.push(newRef);
        },
        // 修改样品按钮点击
        handleModifyReferenceButtonClick() {
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) !== -1;
            });
            this.edittingReference = temp[0];
            this.addOrModifyReferenceModalVisible = true;
        },
        // 修改样品回调
        handleModifyReference(reference) {
            this.customData[this.customData.indexOf(this.edittingReference)] = cloneDeep(reference);
        },
         /******************                 预览 start              ********************/
        // 预览点击事件
        async handlePreviewClick(file, index) {
            this.filePreviewUrl = null;
            // 获取预览文件类型
            this.filePreviewType = getFileTypeBySuffix(file.fileExtensionName);
            // 获取文件名
            const fileOriginName = file.FileName ? file.FileName : file.fileName + file.fileExtensionName;
            this.filePreviewName = fileOriginName;
            /* if (this.filePreviewType === 'xlsx' && this.mode === 'edit') {
                this.excelItem = file;
                this.excelItemIndex = index;
                this.excelEditModalVisible = true;
            }
            else  */if(this.filePreviewType === 'pdf' || this.filePreviewType === 'xlsx') {
                // 不做处理
            }
            else if(this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {

            }
            else {
                this.filePreviewModalVisible = true;
            }
            // 如果是云盘导入的文件进行预览
            if (file.bucketName && file.code && !file.isCopy) {
                const res = await getExperimentFilePath({
                    filePath: `${file.bucketName}/${file.code}`,
                    isPreview: true
                });
                if (res.code === 200) {
                   // console.log(res.data)
                    // excel文件转化问file文件
                    if (this.filePreviewType === 'xlsx') {
                       /*  const xlsxRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = new File([xlsxRes.data], this.filePreviewName); */
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if(this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if(this.filePreviewType === 'txt') {
                        const txtRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = window.URL.createObjectURL(new Blob([txtRes.data], { type: 'text/plain,charset=UTF-8' }));
                    }
                    else {
                        this.filePreviewUrl = res.data;
                    }

                }
            }
            // 如果是实验上传的文件则获取该类型
            else if (file.code) {
                const res = await getExperimentFilePath({
                    filePath: file.code,
                    isPreview: true
                });
                if (res.code === 200) {
                    
                    if (this.filePreviewType === 'xlsx') {
                      /*   const xlsxRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = new File([xlsxRes.data], this.filePreviewName); */
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if(this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {
                        console.log(process.env)
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if(this.filePreviewType === 'txt') {
                        const txtRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = window.URL.createObjectURL(new Blob([txtRes.data], { type: 'text/plain,charset=UTF-8' }));
                    }
                    else {
                        this.filePreviewUrl = res.data;
                    }
                }
            }
            // 如果是本地上传的文件
            else if (file.File) {
                if (this.filePreviewType === 'xlsx') {
                    this.filePreviewUrl = file.File;
                    //console.log(this.filePreviewUrl)
                    this.filePreviewModalVisible = true;
                }
                else if(this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {
                    message.info({
                        content: '该类型本地文件暂不支持预览'
                    })
                }
                /* else if(this.filePreviewType === 'txt') {
                    this.readTxtFile(file.File);
                } */
                else {
                    this.filePreviewUrl = window.URL.createObjectURL(file.File);
                }
            }
            // 不知道哪来的文件
            else {
                message.error(`文件‘${fileOriginName}’无法预览`);
            }
            if((this.filePreviewType === 'pdf') && this.filePreviewUrl) {
                let win =  window.open(this.filePreviewUrl, '_blank');
                win.onload = () => {
                    setTimeout(() => {
                        win.document.title = this.filePreviewName;
                    })
                }
            }
        },
        // 获取是否支持预览类型
        getFileTypeSupported(record) {
            return getFileTypeBySuffix(record.fileExtensionName) !== 'undefined';
        },
        // 读取txt文件
        readTxtFile(file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (result) => {
                this.filePreviewUrl = result.target.result;
            }
            reader.onerror = (error) => {
                message.error(`读取文件出错，错误信息：${error}`)
            }
        },
        test(item) {
            //console.log(item)
        },
        /******************                 预览 start              ********************/
    },
    components: { 
        AddOrModifyRefrenceModal,
        FilePreviewModal  
    }
};
</script>