<!--  excel 在线编辑 组件 -->
<template>
    <a-modal :width="800" :visible.sync="visible" @cancel="handleCancel" :maskClosable="false" destroyOnClose>
        <template slot="title">
            <a-tooltip title="全屏">
                <svg-icon name="fullscreen" class="operatin-icon" @click="handletoggleFullScreen"></svg-icon>
            </a-tooltip>
            {{ fileName }}
        </template>
        <a-spin :spinning="!fileUrl || !isDesinerLoad" :delay="300" tip="正在加载中...">
            <body :class="['edit-content', isFullscreen ? 'fullscreen' : '']" ref="contentRef">
                <div v-show="isDesinerLoad && !loading" ref="workbookRef" style="height:500px;width: 100%;"></div>
                <div ref="excelRef" v-show="isDesinerLoad && !loading" style="height:500px;width: 100%; ">
                </div>
            </body>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消更改
                </a-button>
                <a-button :disabled="loading || !isDesinerLoad" type="primary" class="confirm-btn"
                    @click="handleConfirmClick">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { useFullscreen, watchOnce } from '@vueuse/core';
import { message } from 'ant-design-vue';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue-demi';
import { isDesinerLoad, loadSpreadJS } from '../plugins/KFormDesign/spreadjs';
import { toggleFullScreen, getElIsFullScreen } from '../utils/fullSrceen';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        fileName: {
            type: String,
            default: ''
        },
        fileUrl: {
            type: String | null | File,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const contentRef = ref(null);
        const handletoggleFullScreen = async () => {
            toggleFullScreen(contentRef.value)
        }
        // 设计器
        const designer = ref(null);
        // 工作簿
        const workbook = ref(null);
        // 表格元素实例
        const excelRef = ref(null);
        // 工作本元素实例
        const workbookRef = ref(null);
        // 是否正在加载excel数据
        const loading = ref(false);
        // 初始数据
        const initJSON = ref({});
        // sheet 是否发生更改
        const sheetChanged = ref(false);
        // data(cell) 是否发生更改
        const dataChanged = ref(false);
        // 区域 是否发生更改
        const rangeChange = ref(false);

        watch(() => props.fileUrl, async (newVal) => {
            if (newVal) {
                nextTick(() => {
                    if (!isDesinerLoad.value) {
                        loadSpreadJS();
                        // 仅观察一次
                        watchOnce(() => isDesinerLoad.value, (newVal) => {
                            if (newVal) {
                                loadExcel();
                            }
                        })
                    }
                    else {
                        loadExcel();
                    }
                })
            }
        }, { deep: true, immediate: true })

        const isFullscreen = computed(() => {
            return contentRef.value && getElIsFullScreen(contentRef.value);
        })

        // 监听是否全屏
        watch(() => isFullscreen.value, (newval) => {
            if (newval) {
                /* if(!(excelRef.value && workbookRef.value && designer.value)) */
                excelRef.value.style['height'] = `calc(100vh - 90px)`;
                workbookRef.value.style['height'] = `calc(100vh - 90px)`;
                designer.value?.refresh()
            }
            else {
                excelRef.value.style['height'] = `500px`;
                workbookRef.value.style['height'] = `500px`;
                designer.value?.refresh()
            }
        })

        // 加载excel
        const loadExcel = () => {
            loading.value = true;
            if (!designer.value && !workbook.value) {
                if (isDesinerLoad.value && !designer.value) {
                    workbook.value = new GC.Spread.Sheets.Workbook(workbookRef.value);
                    let config = GC.Spread.Sheets.Designer.DefaultConfig;
                    designer.value = new GC.Spread.Sheets.Designer.Designer(excelRef.value, config, workbook.value);
                    // excel导入导出对象
                    const excelIO = new GC.Spread.Excel.IO();
                    excelIO.open(props.fileUrl, (json) => {
                        workbook.value.fromJSON(json);
                        // 初始化json
                        /*  workbook.value.sheets.forEach(sheet => {
                             sheet.options.isProtected = true;
                         })
                         designer.value.bind(GC.Spread.Sheets.Designer.Events.FileLoading, (event, data, cancel) => {
                             GC.Spread.Sheets.Designer.showMessageBox("仅为预览/该模式下不支持导入", "提示", GC.Spread.Sheets.Designer.MessageBoxIcon.warning);
                             data.cancel = true;
                         }); */
                        designer.value.refresh();
                        workbook.value.refresh();
                        loading.value = false;
                        setTimeout(() => {
                            initJSON.value = workbook.value.toJSON();
                            // 绑定 表格变更事件
                            workbook.value.bind(GC.Spread.Sheets.Events.EditChange, function (sender, args) {
                                sheetChanged.value = true;
                            });
                            // 为每个表格增加变更事件
                            workbook.value.sheets.forEach(sheet => {
                                sheet.bind(GC.Spread.Sheets.Events.EditChange, function (sender, args) {
                                    dataChanged.value = true;
                                });
                                sheet.bind(GC.Spread.Sheets.Events.RangeChanged, function (sender, args) {
                                    rangeChange.value = true;
                                });
                            });
                        })
                        /*   console.log(JSON.stringify(workbook.value.toJSON()).length); */
                    }, (error) => {
                        message.error(error)
                    })
                }
            }
        }

        // 关闭前销毁
        onBeforeUnmount(() => {
            designer.value && designer.value.destroy();
            workbook.value && workbook.value.destroy();
        })

        // 点击确认
        const handleConfirmClick = () => {
            const newJSON = workbook.value.toJSON();
            if (!(dataChanged.value || sheetChanged.value || rangeChange.value) && JSON.stringify(newJSON) === JSON.stringify(initJSON.value)) {
                handleCancel();
            }
            else {
                const excelIO = new GC.Spread.Excel.IO();
                // 保存为文件
                excelIO.save(newJSON, (blob) => {
                    const file = new File([blob], props.fileName);
                    context.emit('onExcelEdit', file);
                }, (error) => {
                    message.error(error);
                })
            }
        }

        return {
            handleCancel,
            contentRef,
            handletoggleFullScreen,
            designer,
            workbook,
            excelRef,
            workbookRef,
            isDesinerLoad,
            loading,
            handleConfirmClick,
            isFullscreen
        }
    }
}
</script>

<style lang="less" scoped>
.edit-content {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}

.operatin-icon {
    cursor: pointer;
    margin-right: 5px;

}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
.fullscreen {
    position: fixed;
    z-index: 19999;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0;
}
</style>