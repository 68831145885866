import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import Antd from 'ant-design-vue'
//import 'ant-design-vue/dist/antd.css'
import './plugins/antd/component';
import { Button, message, info } from 'ant-design-vue'
import animate from 'animate.css'
import axios from 'axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
import KFormDesign from './plugins/KFormDesign/index';
//import 'k-form-design/lib/k-form-design.css'
import SvgIcon from 'vue-svgicon';
import "./assets/components/index";
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
import Contextmenu from "vue-contextmenujs"
import './style/global.less';
//import { handle401 } from './utils/request'
import NProgress from './utils/progress';

message.config({
  top: '60px'
})
/* import 'highlight.js/styles/monokai-sublime.css'
//引入库 
import his from 'highlight.js'
//创建指令
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll(' pre code');
  blocks.forEach((block) => {
    his.highlightAll(block)
  })
}) */



Vue.use(Contextmenu);
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
Vue.use(animate)
//Vue.use(Antd)
Vue.use(KFormDesign)
//Vue.config.productionTip = false
Vue.prototype.$http = axios
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
  config => {
	  // if(config.url.split('?')[0]!='/mycloud/returnmycloudfile'&&config.url!='/mycloud/returnmycloudcapacity'){
		  //store.commit('updateSpin',true)
  // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = localStorage.getItem('Authorization')
    const xToken = localStorage.getItem('X-Authorization');
    if (!config.headers.hasOwnProperty('Authorization') && token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    if(xToken) {
      config.headers['X-Authorization'] = 'Bearer '+ xToken;
   }
    return config;
  },
  error => {
    return Promise.reject(error);
  });


/* axios.interceptors.response.use(response => {
  if(response.data.code === 401) {
    handle401(response.data.message || '登录失效，请重新登录');
  }
  if (response.headers['access-token'] && response.headers['access-token'] != `invalid_token`) {
    localStorage.setItem('Authorization',  response.headers['access-token']);
  }
  if (response.headers['x-access-token']) {
    localStorage.setItem('X-Authorization', response.headers['x-access-token']);
  }
  store.commit('updateSpin', false)
  return response
}, error => {
  var config = error.config;
  console.log(config)
  store.commit('updateSpin', false)
  message.error('' + error)
  return Promise.reject(error)
})
 */
const whiteList = [
  'registerIndtroduction',
  'userAgreement',
  'privacy',
  'disclaimer',
  'companyIntroduction',
  'downLoadPage'
]
// 在开发环境下，增加一个测试页面
if(process.env.NODE_ENV === 'development') {
  router.addRoute('/', {
		path: '/test',
		name: 'test',
		component: () => import('@/views/test.vue')
	})
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.path == "/login" || to.path == "/register" || to.path == "/updatePass" || (to.name && (whiteList.indexOf(to.name) !== -1)) || window.localStorage.getItem('Authorization') ) {
    document.title = '科研诚信管理系统'
    next();
  } else {
    // Vue.prototype.$message = message;
   /*  message.error("无权访问：用户未登录，已跳转登录，请登录后再访问"); */
   /*  setTimeout(function () {
      router.push({ path: '/login' })
    }, 100) */
    router.replace({ path: '/login' })
    next();
  }
})

router.afterEach((to, from) => {
  if(to.meta && to.meta.title) {
    // 预留路由标签名称
    document.title = to.meta.title;
  }
  NProgress.done();
})
const globalVueThis = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default globalVueThis;