<template>
<div v-if="mode==='edit'" class="input-ctn">
    <a-input placeholder="请输入" type="textarea" @contextmenu.prevent="getConextMenu" @focus="inputFocus" @blur="inputBlur" v-model="record.options.customData"> </a-input>
</div>
<div v-else class="input-ctn">
    <a-input placeholder="请输入" type="textarea" :value="record.options.customData" :readOnly="true" > </a-input>
</div>
</template>

<style lang="less" scoped>
.input-ctn {
    padding: 10px 10px 15px 10px;
}
</style>

<script>
import { getConextMenu, inputFocus, inputBlur } from '../../utils';
export default {
    name: 'singleLineText',
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props,context) {
        return {
            getConextMenu,
            inputFocus,
            inputBlur
        }
    }
}
</script>