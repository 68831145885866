/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'redo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_redo_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_redo_a)"><path pid="1" d="M16 7h-5a6 6 0 100 12h9v2h-9a8 8 0 110-16h5V1l6 5-6 5V7z" _fill="#4E5969"/></g>'
  }
})
