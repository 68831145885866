/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'experment-material': {
    width: 41.641,
    height: 40,
    viewBox: '0 0 41.641 40',
    data: '<clipPath id="svgicon_experment-material_a"><rect pid="0" width="41.641" height="40" rx="0"/></clipPath><g clip-path="url(#svgicon_experment-material_a)"><path pid="1" d="M17 22.883a1.667 1.667 0 00-2.35-.083 18.633 18.633 0 00-6.317 13.867 1.667 1.667 0 103.334 0 15.25 15.25 0 015.25-11.434c.67-.626.707-1.677.083-2.35zm16.95 7.734a18.101 18.101 0 00-2.667-5A23.85 23.85 0 0025.1 20c3.733-2.383 9.9-7.667 9.9-16.667a1.667 1.667 0 00-3.333 0c0 9-7.65 13.617-10 14.817A22.366 22.366 0 0117.183 15h4.484a1.667 1.667 0 100-3.333H14.25a15.65 15.65 0 01-1.667-3.334h14.084a1.667 1.667 0 100-3.333h-14.9c-.068-.553-.101-1.11-.1-1.667a1.667 1.667 0 10-3.334 0c0 12.617 12.167 17.984 12.684 18.2A21.55 21.55 0 0126.167 25h-4.5a1.667 1.667 0 100 3.333h7.383a15.115 15.115 0 011.667 3.334h-14.05a1.667 1.667 0 000 3.333h14.9c.066.553.1 1.11.1 1.667a1.667 1.667 0 103.333 0 17.436 17.436 0 00-.367-3.65c-.162-.817-.39-1.62-.683-2.4z" _fill="#42526E"/></g>'
  }
})
