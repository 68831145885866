<template>
    <div class="diy-form">
        <a-form-model :colon="true">
            <a-row v-for="(row, rowIndex) in customData" style="min-width: 1000px;">
                <a-col  v-for="(col, colIndex) in customData[rowIndex]" :span="12" style="min-width: 310px;">
                    <a-form-model-item  style="display: flex; ">
                        <template slot="label">
                            <div style=" word-break: break-all; padding: 4px 12px; max-width: 140px;">
                                <div style="display: flex; align-items: center;" v-show="mode == 'edit' && edittingColIndex == colIndex && edittingRowIndex == rowIndex">
                                    <a-input style="margin-top: 6px;"  v-model="customData[rowIndex][colIndex].label " :placeholder="`请输入`"  @contextmenu.prevent="getConextMenu" @blur="handleBlur" @focus="hiddenInputFocus"></a-input>
                                    <span style="margin-left:4px;">:</span>
                                </div>
                                <div style="word-break: break-all; display: flex; "  v-show="!(mode == 'edit' && edittingColIndex == colIndex && edittingRowIndex == rowIndex)">
                                    <a-tooltip :title="mode == 'edit' ? '编辑' : ''" >
                                        <div class="form-label"  @click="handleEidt(rowIndex, colIndex)">
                                            {{  customData[rowIndex][colIndex].label }}
                                        </div>
                                    </a-tooltip>
                                    <span  style="margin-left: 4px;margin-top: 6px;">:</span>
                                </div>
                                
                            </div>
                        </template>
                        <div style=" word-break: break-all; flex: 1; width: 100%; display: flex; align-items: center; margin-top: 8px;" >
                            
                            <div v-if="mode != 'edit'">
                                {{  customData[rowIndex][colIndex].value  }}
                            </div>
                            <div style="width: 100%; display: flex; align-items: center;" v-else>
                                    <a-input style="width: 100%;" v-model="customData[rowIndex][colIndex].value " :ref="`label-[${rowIndex}]-$[${colIndex}]`" :placeholder="`请输入${customData[rowIndex][colIndex].label}`" @contextmenu.prevent="getConextMenu" @focus="hiddenInputFocus" @blur="handleBlur"></a-input>
                            
                              <!--   <div v-if="customData[rowIndex][colIndex].type == 'input'">

                                </div> -->
                                <a-tooltip :style="{ 'margin-right': mode == 'edit' && customData[rowIndex].length > 1 ? '32px' : 0 }" v-if="mode == 'edit' && record.options.isEditForm" :title="mode == 'edit' ? '删除列' : ''"  @click=" mode == 'edit' ? delCol(rowIndex, colIndex) : () => {}">
                                    <span style="margin: 0 6px; font-size: 16px;"> <a-icon type="minus-circle" class="operation-icon" /> </span>
                                </a-tooltip>
                                <a-tooltip v-if="mode == 'edit' && customData[rowIndex].length <= 1 && record.options.isEditForm"  :title="mode == 'edit' ? '新增列' : ''" >
                                    <span style="margin: 0 8px; font-size: 16px;" @click="addCol(rowIndex, colIndex)"> <a-icon type="plus-circle" class="operation-icon"/> </span>
                                </a-tooltip>
                               <!--  <a-tooltip v-if="mode == 'edit' && record.options.isEditForm" :title="mode == 'edit' ? '切换' : ''">
                                    <a-popover trigger="click" placement="bottomLeft" @click="handleTypePopoverClick(rowIndex, colIndex)">
                                        
                                            <span style="margin: 0 8px; font-size: 16px;" > <a-icon type="swap"  class="operation-icon" />  </span>
                                    
                                        <template slot="content">
                                            <div v-for="(option, optionIndex) in typeList" style="cursor: pointer; " class="mode-value" @click="handleTypeChange(option.value)"> {{  option.label  }} </div>
                                        </template>
                                    </a-popover>
                                 </a-tooltip> -->
                              <!--   <a-tooltip v-if="mode == 'edit' && record.options.isEditForm" :title="mode == 'edit' ? '设置' : ''">
                                    <span style="margin: 0 8px; font-size: 16px;" > <a-icon type="setting" class="operation-icon" @click="handleSettingButtonClick(rowIndex, colIndex)"/>  </span>
                                </a-tooltip> -->
                            </div>
                        </div>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-form-model-item v-if="mode == 'edit' && record.options.isEditForm" >
                <a-button type="dashed" style="width: calc(100% - 24px) ; margin: 12px 12px;" @click="addRows">
                    <a-icon type="plus" /> 新增一行
                </a-button>
            </a-form-model-item>
            <a-form-model-item v-if="mode == 'edit' && !record.options.isEditForm" >
                <a-button type="primary"  style="width: calc(100% - 12px) ; margin: 12px 12px;" @click="record.options.isEditForm = true">
                    编辑行列
                </a-button>
            </a-form-model-item>
            <a-form-model-item v-if="mode == 'edit' && record.options.isEditForm" >
                <a-button type="primary"  style="width: calc(100% - 24px) ; margin: 12px 12px;" @click="record.options.isEditForm = false">
                    完成编辑
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <!-- 
            增加修改
            <a-drawer
        title="列设置"
        :width="300"
        placement="right"
        :closable="false"
        :visible="settingDrawerVisible"
        @close="settingDrawerVisible = false; edittingColIndex = -1; edittingRowIndex = -1;"
        >
           <a-form-model v-if="settingDrawerVisible" layout="vertical">
                <a-form-model-item label="名称">
                    <a-input :placeholder="`请输入}`" v-model="customData[edittingRowIndex][edittingColIndex].label"></a-input>
                </a-form-model-item>
                <a-form-model-item label="值">
                    <a-input :placeholder="`请输入${customData[edittingRowIndex][edittingColIndex].label}`" v-model="customData[edittingRowIndex][edittingColIndex].value"></a-input>
                </a-form-model-item>
                <a-form-model-item >
                    <div style="font-size: 18px; font-weight: bold; padding-bottom: 8px; border-bottom: 1px solid #ebebeb;">
                        布局
                    </div>
                </a-form-model-item>
                <a-form-model-item label="宽度">
                    <a-select >
                        <a-select-option :value="8"> 一列 </a-select-option>
                    </a-select>
                </a-form-model-item>
           </a-form-model>
        </a-drawer> -->
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue-demi'
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
const allTypes = Object.freeze([
    {
        label: '单行文本',
        value: 'input'
    },
    {
        label: '多行文本',
        value: 'textarea'
    },
    {
        label: '单选下拉',
        value: 'single-select'
    }
])
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props, context) {
        const addRows = () => {
            props.customData.push([
                {
                    label: `标签-${props.customData.length }-0`,
                    value: ''
                }
            ])
        }

        const addCol = (rowIndex, colIndex) => {
            props.customData[rowIndex].splice(colIndex + 1, 0, { label: `标签-${rowIndex}-${colIndex + 1}`, value:  '' })
        }

        const delCol = (rowIndex, colIndex) => {
            if(props.customData[rowIndex].length == 1) {
                props.customData.splice(rowIndex,1);
                return 
            }
            props.customData[rowIndex].splice(colIndex,1);
        }

        const edittingRowIndex = ref(-1);

        const edittingColIndex = ref(-1);

        const handleEidt = (rowIndex, colIndex) => {
            if(props.mode != 'edit') {
                return ;
            }
            edittingColIndex.value = colIndex;
            edittingRowIndex.value = rowIndex
        }

        const handleBlur = () => {
            edittingColIndex.value = -1;
            edittingRowIndex.value = -1;
            inputBlur();
        }

        onMounted(() => {
            //console.log(context)
        })


        const settingDrawerVisible = ref(false);    // 设置抽屉可见变量

        const handleSettingButtonClick = (rowIndex, colIndex) => {
            edittingRowIndex.value = rowIndex;
            edittingColIndex.value = colIndex;
            settingDrawerVisible.value = true;
        }

        const typeList = computed(() => {
            if(edittingColIndex.value == -1 || edittingRowIndex.value == -1) {
                return []
            }
            return allTypes.filter(option => {
                return option.value != props.customData[edittingRowIndex.value][edittingColIndex.value].type;
            })
        })

        
        const handleTypePopoverClick = (rowIndex, colIndex) => {
            edittingColIndex.value = colIndex;
            edittingRowIndex.value = rowIndex;
        }
        
        const handleTypeChange = (value) => {
            props.customData[edittingRowIndex.value][edittingColIndex.value].type = value;
        }
        return {
            addRows,
            addCol,
            edittingRowIndex,
            edittingColIndex,
            handleEidt,
            getConextMenu,
            handleBlur,
            delCol,
            settingDrawerVisible,
            handleSettingButtonClick,
            typeList,
            handleTypePopoverClick,
            handleTypeChange,
            hiddenInputFocus,

        }
    }
}
</script>

<style lang="less" scoped>
.diy-form {
    overflow-x: auto;
    padding-right: 16px;
    padding-bottom: 16px;
}
/deep/ .ant-col .ant-form-item-label {
    //margin-top: 8px;
}
 /deep/ .ant-col .ant-form-item-control-wrapper {
    flex: 1;
   // padding: 4px 12px 4px 0px;
}
/deep/ .ant-col .ant-form-item-control-wrapper{
    .form-label {
        width: 132px; 
        padding: 0px 4px;
        cursor: pointer;
        &:hover {
            background: #fff;
            transition: .3s ease-in-out all;
        }
        
    }
}
.form-label {
        //width: 132px; 
        width: 108px;
        text-align: right;
        padding: 6px 10px;
        border-radius: 6px;
        cursor: pointer;
        border:  1px solid transparent;
        &:hover {
            background: #fff;
            border: 1px solid #1890ff;
            transition: .3s ease-in-out all;
        }
        
    }
.operation-icon {
    cursor: pointer;
}
.mode-value {
    padding: 4px 16px;
    &:hover {
        background: #f7f7f7;
    }
}
</style>