const state = {
    // 已经加载好的pdf excel 数量
    loadPdfExcelAccount: 0,
    // 需要加载的总数
}

const mutations = {
    // 自增长
   increstExcelAccount(state) {
    state.loadPdfExcelAccount = state.loadPdfExcelAccount + 1;
   },
   // 归0
   resetExcelAccount(state) {
    state.loadPdfExcelAccount = 0;
   }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}