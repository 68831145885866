import Vue from 'vue'
import Vuex from 'vuex'
import experiment from './modules/experiment';
import getters from './getters'
import trigger from './modules/trigger';
import pdf from './modules/pdf';
import bbs from './modules/bbs';
import { getUserInfo } from '../api/anotherIndex';
import sysNotice from './modules/sysNotice'
Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		userInfo: window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem(
			'userInfo')) : {},
		spinnings:false,
		loadingClose:false,
		loginTimeStamp: localStorage.getItem('loginTimeStamp'),
		changePlatformTimeStamp: localStorage.getItem('changePlatformTimeStamp')
	},
	mutations: {
		setUserInfo(state, data) {
			state.userInfo = data
			window.localStorage.setItem('userInfo', JSON.stringify(data))
		},
		updateSpin(state,num){
				  state.spinnings=num
		},
		updateClose(state,num){
				  state.loadingClose=num
		},
		setMsgCount(state, count) {
			state.userInfo.noReadCount = count;
			window.localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
		},
		// 注销
		logout(state) {
			state.userInfo = {};
			localStorage.removeItem('loginTimeStamp')
		},
		setLoginTimeStamp(state) {
			const timeStamp = (new Date()).getTime();
			localStorage.setItem('loginTimeStamp', timeStamp);
			state.loginTimeStamp = timeStamp;
		},
		setChangePlatformTimeStamp(state) {
			const timeStamp = (new Date()).getTime();
			localStorage.setItem('changePlatformTimeStamp', timeStamp);
			state.changePlatformTimeStamp = timeStamp;
		}
		
	},
	actions: {
		async callForUserInfo({ commit }) {
			const res = await getUserInfo();
			if(res.success) {
				commit('setUserInfo', res.data)
			}
		}
	},
  modules: {
    experiment,
	trigger,
	pdf,
	sysNotice,
	bbs
  },
  getters

});

export default store;