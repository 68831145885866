<template>
<div v-if="mode === 'edit'" class="input-ctn">
    <a-input placeholder="请输入" @contextmenu.prevent="getConextMenu" @focus="inputFocus" @blur="inputBlur"  v-model="record.options.customData"> </a-input>
</div>
<div v-else class="input-ctn">
    <a-input placeholder="请输入" :readOnly="true" v-model="record.customData"> </a-input>
</div>
</template>

<style lang="less" scoped>
.input-ctn {
    padding: 10px;
}
</style>

<script>
import { getConextMenu, inputFocus, inputBlur } from '../../utils';
export default {
    name: 'singleLineText',
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props, context) {
        const testFun = (e) => {
            console.log(e)
        }
        return {
            getConextMenu,
            inputFocus,
            inputBlur,
            testFun
        }
    }
}
</script>