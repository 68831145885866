/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'intro-edit': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<defs><clipPath id="svgicon_intro-edit_a"><rect pid="0" width="14" height="14" rx="0"/></clipPath></defs><g style="mix-blend-mode:passthrough" clip-path="url(#svgicon_intro-edit_a)"><path pid="1" d="M11.667 1.68a.654.654 0 00-.463.19L5.777 7.299l-.309 1.234 1.234-.309 5.427-5.427a.654.654 0 00-.462-1.117zm-1.288-.634a1.82 1.82 0 112.575 2.575L7.412 9.163a.583.583 0 01-.27.153L4.807 9.9a.583.583 0 01-.707-.707l.583-2.333a.584.584 0 01.154-.271l5.541-5.542zM1.096 2.263a1.75 1.75 0 011.237-.513h4.084a.583.583 0 110 1.167H2.333a.583.583 0 00-.583.583v8.167a.583.583 0 00.583.583H10.5a.583.583 0 00.583-.583V7.583a.583.583 0 011.167 0v4.084a1.75 1.75 0 01-1.75 1.75H2.333a1.75 1.75 0 01-1.75-1.75V3.5c0-.464.185-.91.513-1.237z" fill-rule="evenodd"/></g>'
  }
})
