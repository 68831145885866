/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'history': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_history_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_history_a)"><path pid="1" d="M16.198 1.504l5.248 5.247v14.705c0 .574-.467 1.04-1.042 1.04H3.596a1.05 1.05 0 01-1.042-1.041V2.545c0-.575.467-1.041 1.042-1.041h12.602zM13.05 8.851h-2.1v6.298h5.248v-2.1H13.05V8.852z" _fill="#4E5969"/></g>'
  }
})
