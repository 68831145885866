import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	/* {
		path: '/test',
		name: 'test',
		component: () => import('@/views/test.vue')
	}, */
	{
		path: '/downLoadPage',
		name: 'downLoadPage',
		component: () => import('@/views/main/downLoadPage.vue')
	},
	{
		path: '/pdfPage',
		name:'pdfPage',
		component: () => import('@/views/pdfPage/pdfPage.vue')
	},
	{
		path: '/experiment',
		name: 'experiment',
		component: () => import('@/views/experiment/experiment.vue')
	},
	{
		path: '/experimentTemplate',
		name: 'experimentTemplate',
		component: () => import('@/views/experimentTemplate/experimentTemplate.vue')
	},
	{
		path: '/experimentTemplate/newExperimentTemplate',
		name: 'newExperimentTemplate',
		component: () => import(`@/views/experimentTemplate/newExperimentTemplate.vue`)
	},
	{
		path: '/introduction',
		name: 'introduction',
		component: () => import(`@/views/Instructions/introductionLayout.vue`),
		children: [
			{
				path: 'register',
				name: 'registerIndtroduction',
				component: () => import('@/views/Instructions/pages/registerIntroduction.vue')
			},
			{
				path: 'userAgreement',
				name: 'userAgreement',
				component: () => import('@/views/Instructions/pages/userAgreement.vue')
				
			},
			{
				path: 'privacy',
				name: 'privacy',
				component: () => import('@/views/Instructions/pages/privacy.vue')
			},
			{
				path: 'disclaimer',
				name: 'disclaimer',
				component: () => import('@/views/Instructions/pages/Disclaimer.vue')
			},
			{
				path: 'companyIntroduction',
				name: 'companyIntroduction',
				component: () => import('@/views/Instructions/pages/companyIntroduction.vue')
			}
		]
	},
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/index'),
		redirect: '/workbench',
		children: [{
				path: '/workbench',
				name: 'workbench',
				meta: {
					title: '工作台 | 科研诚信管理系统'
				},
				component: () => import('@/views/main/workbench'), //工作台
			}, {
				path: '/experManage',
				name: 'experManage',
				meta: {
					title: '实验记录管理 | 科研诚信管理系统'
				},
				component: () => import('@/views/main/experManage'), //实验管理
			},/*  {
				path: '/cageAppoint',
				name: 'cageAppoint',
				component: () => import('@/views/main/cageAppoint'), //笼位预约
			},
			{
				path: '/cageLog',
				name: 'cageLog',
				component: () => import('@/views/main/cageLog'), //笼位预约记录
			}, */
			// {
			// 	path: '/itemCenter',
			// 	name: 'itemCenter',
			// 	component: () => import('@/views/main/itemCenter'), //项目中心

			// },
			{
				path: '/systemNotice',
				name: 'systemNotice',
				meta: {
					title: '系统公告 | 科研诚信管理系统'
				},
				component: () => import('@/views/main/systemNotice'), //系统公告
			},
			{
				path: '/systemNoticeDetail',
				name: 'systemNoticeDetail',
				component: () => import('@/views/main/systemNoticeDetail'), //系统公告
			},
			{
				path: '/search',
				name: 'search',
				meta: {
					title: '搜索 | 科研诚信管理系统'
				},
				component: () => import('@/views/main/search'), //搜索页
			},
			{
				path: '/core',
				name: 'core',
				meta: {
					title: '工作台 | 科研诚信管理系统'
				},
				component: () => import('@/views/peopleCenter/index'), //个人中心
				redirect: '/core/basicInformation',
				children: [{
						path: '/core/basicInformation',
						name: 'basicInformation',
						meta: {
							title: '基本资料 | 科研诚信管理系统'
						},
						component: () => import('@/views/peopleCenter/core/basicInformation'), //基本资料
					},
					{
						path: '/core/loginLog',
						name: 'loginLog',
						meta: {
							title: '登录日志 | 科研诚信管理系统'
						},
						component: () => import('@/views/peopleCenter/core/loginLog'), //登录日志
					},
					{
						path: '/core/myTemplate',
						name: 'myTemplate',
						meta: {
							title: '我的模板 | 科研诚信管理系统'
						},
						component: () => import('@/views/peopleCenter/core/myTemplate'), //我的模板
					},
					{
						path: '/core/templateCenter',
						name: 'templateCenter',
						meta: {
							title: '模板中心 | 科研诚信管理系统'
						},
						component: () => import('@/views/peopleCenter/core/templateCenter'), //模板中心
					},
					{
						path: '/core/myGoodFaithBahavior',
						name: 'myGoodFaithBahavior',
						meta: {
							title: '诚信行为 | 科研诚信管理系统'
						},
						component: () => import('@/views/peopleCenter/core/myGoodFaithBahavior'), // 诚信行为
					},
					/* {
						path: '/core/cageLog',
						name: 'peopleCenterCageLog',
						meta: {
							title: '笼位预约记录 | 科研诚信管理系统'
						},
						component: () => import('@/views/main/cageLog'), //笼位预约记录
					}, */
					{
						path: '/core/myResearchTeam',
						name: 'myResearchTeam',
						meta: {
							title: '我的课题组 | 科研诚信管理系统'
						},
						component: () => import('@/views/peopleCenter/core/myResearchTeam'),	// 我的课题组
					}
				]
			},
			{
				path: '/cloudDisk',
				name: 'cloudDisk',
				component: () => import('@/views/cloudDisk/index'), //云盘
				redirect: '/cloudDisk/own',
				children: [{
						path: '/cloudDisk/own',
						name: 'own',
						meta: {
							title: '个人云盘 | 科研诚信管理系统'
						},
						component: () => import('@/views/cloudDisk/disk/own'), //个人云盘
					},
					{
						path: '/cloudDisk/group',
						name: 'group',
						meta: {
							title: '项目云盘 | 科研诚信管理系统'
						},
						component: () => import('@/views/cloudDisk/disk/group'), //群云盘
					},
				]
			},
			{
				path: '/experimentRecord',
				name: 'experimentRecord',
				component: () => import('@/views/experimentRecord/experimentRecord.vue')
			},
			{
				path: '/itemCenter',
				name: 'itemCenter',
				component: () => import('@/views/itemCenter/index'), //项目中心
				redirect: '/itemCenter/index',
				children: [{
						path: '/itemCenter/index',
						name: 'item',
						meta: {
							title: '项目管理 | 科研诚信管理系统'
						},
						component: () => import('@/views/itemCenter/item/index'), //项目列表
					},
				]
			},
			{
				path: '/itemCenter/experimentAuditList',
				name: 'experimentAuditList',
				meta: {
					title: '待审核实验 | 科研诚信管理系统'
				},
				component: () => import('@/views/itemCenter/views/experimentAuditList/experimentAuditList'), //项目列表
			},
			{
				path: '/itemCenter/member',
				name: 'projectMember',
				component: () => import('@/views/projectMember/projectMember.vue'),
				//redirect: ()
				children: [
					{
						path: 'manage',
						name: 'projectMemberManage',
						meta: {
							title: '项目成员管理 | 科研诚信管理系统'
						},
						component: () => import('@/views/projectMember/views/projectMemberManage.vue')
					},
					{
						path: 'serviceChargeRecord',
						name: 'serviceChargeRecord',
						meta: {
							title: '劳务费发放记录 | 科研诚信管理系统'
						},
						component: () => import('@/views/projectMember/views/serviceChargeRecord.vue')
					}
				]
			},
			{
				path: '/itemCenter/goodFaithAudit',
				name: 'goodFaithAudit',
				meta: {
					title: '诚信审核-项目 | 科研诚信管理系统'
				},
				component: () => import('@/views/itemCenter/goodFaithAudit/goodFaithAudit.vue')	// 诚信审核
			},
			{
				path: '/researchGroup',
				name: 'researchGroup',
				meta: {
					title: '课题组 | 科研诚信管理系统'
				},
				component: () => import('@/views/researchTeam/researchTeam.vue')	// 课题组
			},
			{
				path: '/researchGroup/userNoteBook',
				name: 'researchGroupUserNoteBook',
				meta: {
					title: '个人电子记录 | 科研诚信管理系统'
				},
				component: () => import('@/views/researchTeam/views/userNoteBook/userNoteBook.vue')	// 课题组个人电子记录
			},
			{
				path: '/researchGroup/userChargeProject',
				name: 'researchGroupUserChargeProject',
				meta: {
					title: '个人项目统计 | 科研诚信管理系统'
				},
				component: () => import('@/views/researchTeam/views/userChargeProject/userChargeProject.vue')	// 查看个人项目统计详情
			}
		]
	},
	{
		path: '/bbs/drafts/:id?',
		name: 'bbsDrafts',
		meta: {
			title: '草稿'
		},
		component: () => import('@/views/BBS/drafts/drafts.vue')
	},
	{
		path: '/linkIntercept',
		name: 'linkIntercept',
		meta: {
			title: '跳转提示'
		},
		component: () => import('@/views/BBS/linkIntercept/linkIntercept.vue')
	},
	{
		path: '/bbs',
		component: () => import('@/views/BBS/layout/layout.vue'),
		redirect: '/bbs/index',
		children: [
			{
				path: 'search',
				name: 'bbsSearch',
				meta: {
					title: '搜索 - 论坛'
				},
				component: () => import('@/views/BBS/search/search.vue')
			},
			{
				path: 'post/:id',	// 帖子页面
				name: 'bbsPost',
				component: () => import('@/views/BBS/post/post.vue')
			},
			{
				path: 'index',
				name: 'bbsIndex',
				meta: {
					title: '首页 - 论坛'
				},
				component: () => import('@/views/BBS/index/index.vue')
			},
			{
				path: 'topicPost',
				name: 'topicPost',
				meta: {
					title: '# 话题'
				},
				component: () => import('@/views/BBS/topicPost/topicPost.vue')
			},
			{
				path: 'researchTeamPost',
				name: 'researchTeamPost',
				meta: {
					title: '课题组 - '
				},
				component: () => import('@/views/BBS/researchTeamPost/researchTeamPost.vue')
				
			},
			{
				path: 'message',
				name: 'bbsMessage',
				meta: {
					title: '消息 - 论坛'
				},
				component: () => import('@/views/BBS/message/message.vue'),
				redirect: '/bbs/message/likeAndCollect',
				children: [
					{
						name: 'bbsMessageLikeAndCollect',
						path: 'likeAndCollect',
						meta: {
							title: '点赞与收藏 - 论坛'
						},
						component: () => import('@/views/BBS/message/views/likeAndCollect.vue')
					},
					{
						name: 'bbsMessageComment',
						path: 'comment',
						meta: {
							title: '评论消息 - 论坛'
						},
						component:  () => import('@/views/BBS/message/views/comments.vue')
					},
					{
						name: 'bbsMessageNewFollowers',
						path: 'newFollowers',
						meta: {
							title: '新增粉丝 - 论坛'
						},
						component: () => import('@/views/BBS/message/views/followers.vue')
					}
				]
			},
			{
				path: 'user/:id',
				name: 'bbsUser',
				meta: {
					title: '个人主页'
				},
				component: () => import('@/views/BBS/user/layout/layout.vue'),
				redirect: { name: 'bbsUserPosts' },
				/* beforeRouteUpdate(to, from) {
					console.log('trick')
					if(to.params&& from.params && to.params.id != to.params.id) {
						store.commit('trigger/TOGGLE_CHANGE_PLATFORM')
					}
				},
				beforeEach: (to, from) => {
					if(to.params&& from.params && to.params.id != to.params.id) {
						store.commit('trigger/TOGGLE_CHANGE_PLATFORM')
					}
				}, */
				children: [
					{
						path: 'posts',
						name: 'bbsUserPosts',
						component: () => import('@/views/BBS/user/views/posts.vue')
					},
					{
						path: 'replyList',
						name: 'bbsUserReplyList',
						component: () => import('@/views/BBS/user/views/replyList.vue')
					},
					{
						path: 'followers',
						name: 'bbsUserFollowerList',
						component: () => import('@/views/BBS/user/views/followers.vue')
					},
					{
						path: 'follows',
						name: 'bbsUserFollow',
						component:() => import('@/views/BBS/user/views/follows.vue')
					},
					{
						path: 'collection',
						name: 'bbsUserPostCollection',
						component: () => import('@/views/BBS/user/views/myCollection.vue')
					},
					{
						path: 'drafs',
						name: 'bbsUserDrafts',
						meta: {
							title: '草稿箱 | 论坛'
						},
						component: () => import('@/views/BBS/user/views/drafts.vue')
					}
				]
			},
			/* {
				path: 'draftPublishSuccess',
				name: 'bbsDraftPublishSuccess',
				meta: {
					title: '帖子发布成功 | 论坛'
				},
				component: () => import('@/views/BBS/drafts/views/publishSuccess.vue')
			} */
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/user/login/index'), //登录
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/user/register/index'), //注册
	},
	{
		path: '/updatePass',
		name: 'updatePass',
		component: () => import('@/views/user/updatePass/index')
	}
]

const router = new VueRouter({
	routes
})

export default router
