/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bbs-sort-arrow-down': {
    width: 6.651,
    height: 4.27,
    viewBox: '0 0 6.651 4.27',
    data: '<path pid="0" d="M.081.527l3.016 3.638c.126.14.347.14.472 0L6.571.527A.316.316 0 006.335 0H.316a.316.316 0 00-.235.527z"/>'
  }
})
