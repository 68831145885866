/* eslint-disable */
require('./apply-dist')
require('./audit-experiment')
require('./author')
require('./authored-experiment')
require('./bbs-sort-arrow-down')
require('./bbs-sort-arrow-up')
require('./bbs-star')
require('./chat-1-fill')
require('./check-audit')
require('./comment-alt')
require('./copy')
require('./delete-bin-5-fill')
require('./experment-material')
require('./folder')
require('./fullscreen-exit')
require('./fullscreen')
require('./group')
require('./history')
require('./interlining')
require('./intro-edit')
require('./notebook')
require('./open-record')
require('./redo')
require('./regular-comment')
require('./report')
require('./sample-info')
require('./save-version')
require('./settings-4-fill')
require('./shutdown')
require('./star')
require('./statu-check')
require('./template')
require('./test-tube')
require('./thumbs-up')
require('./time')
require('./trash-2')
require('./undo')
