/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'statu-check': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M14.663 2.78A9 9 0 1020 11.004v-.92a1 1 0 012 0v.92A11.002 11.002 0 017.188 21.319 11 11 0 1115.477.952a1 1 0 11-.814 1.827zm7.044-.482a1 1 0 010 1.414l-10 10.01a1 1 0 01-1.414 0l-3-3A1 1 0 118.707 9.31L11 11.6 20.293 2.3a1 1 0 011.414 0z" fill-rule="evenodd" _fill="#2F54EB"/>'
  }
})
