import Compressor from 'compressorjs';
// 对本地上传/外链图片进行压缩
export const compressImage = async (file, fileName="temp.jpeg", fileType='image/jpeg') => {
    console.log({
        file: file,
        message: 'before compressing'
    })
    return new Promise((resolve, reject) => {
        const compresser = new Compressor(file, {
            convertSize: 2000000,
            success(file) {
                console.log({
                    message: '压缩成功',
                    file: file
                })
                return resolve({
                    success: true,
                    file: new File([file], fileName, { type: fileType  })
                })
            },
            error(error) {
                return resolve({
                    success: false,
                    message: typeof error === 'string' ? error : JSON.stringify(error)
                })
            }
        })
    })
}

// 转化单位
export const transferBitSize = (limit) => {
    if(!limit) {
        return ''
    }
    let size = "";
    if(limit < 0.1 * 1024){                            //小于0.1KB，则转化成B
        size = limit.toFixed(2) + "B"
    }else if(limit < 0.1 * 1024 * 1024){            //小于0.1MB，则转化成KB
        size = (limit/1024).toFixed(2) + "KB"
    }else if(limit < 1 * 1024 * 1024 * 1024){        //小于0.1GB，则转化成MB
        size = (limit/(1024 * 1024)).toFixed(2) + "MB"
    }else{                                            //其他转化成GB
        size = (limit/(1024 * 1024 * 1024)).toFixed(2) + "GB"
    }

    let sizeStr = size + "";                        //转成字符串
    let index = sizeStr.indexOf(".");                    //获取小数点处的索引
    let dou = sizeStr.substr(index + 1 ,2)            //获取小数点后两位的值
    if(dou == "00"){                                //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
    }
    return size;
}

export const transferSecondToOther = (seconds) => {
    if(seconds == '') {
        return ;
    }
    if(seconds < 60) {
        return `${seconds} 秒`;
    }
    else if(seconds < 60 * 60) {
        return `${ Math.ceil((seconds / (60)) ) } 分钟`
    }
    else if(seconds < 60 * 60 * 24) {
        return `${ Math.ceil((seconds / (60 * 60))) } 小时`
    }
    else {
        return `${ Math.ceil((seconds / (60 * 60 * 24))) } 天`
    }
}