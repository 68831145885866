<template>
    <div v-if="mode === 'edit'" class="radio-button-ctn">
        <a-radio-group :options="options" :checked="customData" :value="customData" key="label"
            @change="handleChange($event.target.value)">
        </a-radio-group>
    </div>
    <div v-else class="radio-button-ctn">
        <a-radio-group :options="options" :checked="customData" :value="customData" key="label"
            @change="() => {}">
        </a-radio-group>
    </div>
</template>

<style lang="less" scoped>
.radio-button-ctn {
    padding: 10px;
    min-height: 50px;
}
</style>

<script>
import { computed } from '@vue/composition-api';
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props, context) {
        const handleChange = (val) => {
            context.emit('customDataChange', val);
        }
        const options = computed(() => {
            return props.record.options.options.map((element) => {
                return element.label;
            })
        })
        return {
            handleChange,
            options
        }
    }
}
</script>