<template>
    <div class="single-comment-ctn">
        <div :class="[`comment-${commentData.id}`, 'inner-content']">
            <div :class="[`single-comment-header`]">
                <a-avatar v-if="commentData.commentUserPhotoMedia" :src="`${commentData.commentUserPhotoMedia}`" alt="user"
                    size="small" />
                <DefaultAvatar v-else :name="commentData.commentUserName"></DefaultAvatar>
                <span class="comment-username"> {{ commentData.commentUserName }} </span>
                <a-icon v-if="commentData.toUserName" type="caret-right" class="icon" />
                <span v-if="commentData.toUserName">{{ commentData.toUserName }}</span>
            </div>
            <div class="single-comment-content" style="word-break: break-all;">
                <p>{{ commentData.commentContent }}</p>
            </div>
            <div class="single-comment-footer">
                <div class="comment-date">
                    {{ commentData.createdTime }}
                </div>
                <a-tooltip v-if="commentable" placement="left" title="回复" @click="handleCommentButtonClick">
                    <svg-icon name="regular-comment" class="comment-icon" title="评论"></svg-icon>
                </a-tooltip>
                <!-- <svg-icon v-else name="regular-comment" class="comment-icon" title="评论"></svg-icon> -->
            </div>
        </div>
        <a-form-model style="width:95%; margin: 5px; auto; " v-show="isCommentClick || isSubmitting"
            ref="commentFormRef" :model="commentForm" size="small" :colon="false" hideRequiredMark layout="vertical">
            <a-form-model-item ref="commentFormItem" prop="commentContent"
                :rules="[{ required: true, validator: commentValidator }]">
                <a-textarea :autoSize="{ minRows: 4 }" ref="inputRef" v-model="commentForm.commentContent"
                    placeholder="请输入" @blur="handleBlur" :maxLength="300"></a-textarea>
            </a-form-model-item>
            <div class="comment-buttons">
                <a @click="handleSubmitReply"> 回复 </a>
            </div>
        </a-form-model>

        <div v-if="!isChild && commentData.children && commentData.children.length" class="reply-list">
            <div v-for="(item, index) in commentData.children" :key="index" class="reply-comment">
                <comment :id="id" :commentData="item" :isChild="true" @onCommentChange="onCommentChange" :commentable="commentable"></comment>
            </div>
        </div>
    </div>
</template>

<script>
import { useThrottleFn } from '@vueuse/shared';
import { nextTick, ref } from 'vue-demi'
import { addCommentToComment } from '../../../../../../api/experiment';
import DefaultAvatar from '../../../../../defaultAvatar.vue';

export default {
    name: "comment",
    props: {
        commentData: {
            type: Object,
            required: true
        },
        // 是否为子级评论
        isChild: {
            type: Boolean,
            default: false
        },
        id: {
            type: String | null | undefined,
            required: true
        },
        // 是否能评论
        commentable: {
            default: true
        }
    },
    setup(props, context) {
        const inputRef = ref(null);
        // 是否点击了评论按钮, 评论输入框是否聚焦
        const isCommentClick = ref(false);
        // 是否正在提交评论
        const isSubmitting = ref(false);
        // 评论点击
        const handleCommentButtonClick = () => {
            isCommentClick.value = !isCommentClick.value;
            if (isCommentClick.value) {
                nextTick(() => {
                    inputRef.value && inputRef.value.focus();
                    commentFormRef.value && commentFormRef.value.clearValidate();
                });
            }
        };
        // 表单实例
        const commentFormRef = ref(null);
        // 表单评论项实例
        const commentFormItem = ref(null);
        // 评论表单数据
        const commentForm = ref({
            commentContent: ""
        });
        // 失焦
        const handleBlur = () => {
            commentFormItem.value && commentFormItem.value.onFieldBlur();
            setTimeout(() => {
                isCommentClick.value = false;
            }, 500);
        };
        // 回复点击
        const handleSubmitReply = useThrottleFn(async () => {
            isSubmitting.value = true;
            commentFormRef.value.validate(async (valid) => {
                if (valid) {
                    const res = await addCommentToComment({
                        experimentId: props.id,
                        experimentContentModuleCommentId: props.commentData.id,
                        commentContent: commentForm.value.commentContent
                    });
                    if (res.code === 200) {
                        const newCommentList = [];
                        /* res.data.moduleCommentRecordOutputs.forEach(element => {
                            newCommentList.splice(newCommentList.length, 0, ...element.commentRecordOutputs);
                        }); */
                        const activeId = res.data.id;
                        context.emit("onCommentChange", { newCommentList: res.data.moduleCommentRecordOutputs, activeId: activeId, msg: res.message });
                        commentForm.value.commentContent = "";
                        isCommentClick.value = false;
                        isSubmitting.value = false;
                    }
                }
            });
        }, 500);
        // 监听子级评论事件
        const onCommentChange = (data) => {
            context.emit("onCommentChange", data);
        };
        // 评论校验器
        const commentValidator = (_rule, value, callback) => {
            if (!value.toString().trim()) {
                callback(new Error("评论内容不能为空"));
            }
            else {
                callback();
            }
        };
        return {
            inputRef,
            isCommentClick,
            isSubmitting,
            handleCommentButtonClick,
            handleBlur,
            handleSubmitReply,
            commentFormRef,
            commentFormItem,
            commentForm,
            onCommentChange,
            commentValidator
        };
    },
    components: { DefaultAvatar }
}
</script>

<style lang="less" scoped>
.single-comment-ctn {
    .inner-content {
        padding: 8px 8px;
    }

    .single-comment-header {
        display: flex;
        align-items: center;
        font-size: 14px;

        .comment-username {
            margin-left: 3px;
        }

        .icon {
            margin-left: 3px;
            font-size: 12px;
        }
    }

    .single-comment-content {
        margin-top: 8px;
        font-size: 12px;
    }

    .single-comment-footer {
        margin-top: 4px;
        font-size: 12px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.45);
        fill: rgba(0, 0, 0, 0.45);

        .comment-date {
            flex-grow: 1;
            white-space: nowrap;
        }

        .comment-icon {
            font-size: 14px;
            cursor: pointer;
            color: #4E5969;
            fill: #4E5969;
        }
    }


    .comment-buttons {
        width: 100%;
        display: flex;
        padding-top: 5px;
        justify-content: flex-end;
        padding-bottom: 5px;
    }

    .reply-list {
        padding-left: 26px;

        .reply-comment {
            padding-top: 11px;
            border-top: 1px solid #EBEBEB;
            margin-top: 3px;
        }
    }
}
</style>

<style scoped>
@keyframes comment {
    0% {
        background: transparent;
    }

    50% {
        background: rgba(47, 84, 235, 0.6);
    }

    100% {
        background: transparent;
    }
}

.blue-into-out {
    animation-name: comment;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    background-size: 120% 120%;
    animation-timing-function: ease-in-out;
}
</style>