/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'author': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_author_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_author_a)"><path pid="1" d="M11.641 14.116v7.666H2.95c0-4.65 4.078-8.248 8.692-7.666zm-.966-1.026A5.793 5.793 0 014.88 7.295 5.793 5.793 0 0110.675 1.5a5.793 5.793 0 015.795 5.795 5.793 5.793 0 01-5.795 5.795zm5.595 6.678l3.415-3.415 1.366 1.366-4.78 4.781-3.416-3.415 1.367-1.366 2.047 2.049h.001z" _fill="#4E5969"/></g>'
  }
})
