import { useTextSelection } from '@vueuse/core';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import globalVueThis from '../../main';

const state = useTextSelection();   // 闭包变量
// 输入框右击菜单事件
export const getConextMenu = (event, ins) => {
    // 光标起始索引
    const selectionStartIndex = event.target.selectionStart;
    // 光标终止索引
    const selectionEndStartIndex = event.target.selectionEnd;
    // 目前input的值
    const inputVal = event.target.value;
    // 光标前的字符串
    const frontStr = inputVal.substring(0, selectionStartIndex);
    // 光标后的字符串
    const endStr = inputVal.substring(selectionEndStartIndex);
    /* navigator.permissions.query({ name: 'copy' }).then((reasult) => {
        //console.log(reasult);
    }) */
    // 隐藏型input右键插入时间戳后处理
    const afterHiddenInputInsert = (str, inputInstance) => {
        // 获取插入新值后 光标的新位置
        const newIndex =  selectionEndStartIndex + str.length;
        // 获取text 实例
        const textInstance = globalVueThis.$store.getters.textInstance;
        // 如果实例存在则证明是隐藏的input
        if(textInstance) {
            const clickEvent = new CustomEvent('click');
            textInstance.dispatchEvent(clickEvent);

        }
        // 自定义input事件，用于触发vue的更新
        const inputEvent = new CustomEvent('input');
        // 触发事件 -> 根据 视图更新数据
        inputInstance.dispatchEvent(inputEvent)
        // 聚焦
        inputInstance.focus();
        // 设置光标新位置
        inputInstance.setSelectionRange(newIndex, newIndex);
    }
    globalVueThis.$contextmenu({
        items: [
            {
                label: '复制（ Ctrl + C ）',
                disabled: state.text.value === '',
                onClick: () => {
                    event.target.focus();
                    document.execCommand("Copy");
                }
            },
            {
                label: '剪切（ Ctrl + X ）',
                disabled: state.text.value === '',
                onClick: () => {
                    event.target.focus();
                    document.execCommand("Cut");
                }
            },
            {
                label: '粘贴（ Ctrl + V ）',
                onClick: () => {
                    navigator.permissions.query({ name: 'clipboard-read' }).then((reasult) => {
                        if(reasult.state === 'granted') {
                            navigator.clipboard.readText().then((res) => {
                                if(res) {
                                    // 组装成新的字符串
                                    event.target.value = `${frontStr}${res}${endStr}`;
                                    // 自定义input事件，用于触发vue的更新
                                    const inputEvent = new CustomEvent('input');
                                    // 触发事件 -> 根据 视图更新数据
                                    event.target.dispatchEvent(inputEvent)
                                }

                            })
                        }
                        else {
                            message.open({
                                type: 'info',
                                content: '请先点击右上角允许，授权网站使用粘贴功能。或使用 Ctrl + V 进行粘贴。',
                                duration: 5
                            })
                            navigator.clipboard.readText();
                        }
                    })
                }
            },
            {
                label: '插入时间戳',
                children: [
                    {
                        label: '年月日 时分秒',
                        onClick: () => {
                            const str = dayjs().format('YYYY-MM-DD  HH:mm:ss');
                            // 组装成新的字符串
                            event.target.value = `${frontStr}${str}${endStr}`;
                            // 自定义input事件，用于触发vue的更新
                            const inputEvent = new CustomEvent('input');
                            // 触发事件 -> 根据 视图更新数据
                            event.target.dispatchEvent(inputEvent)
                            // 聚焦
                            event.target.focus();
                            afterHiddenInputInsert(str, event.target)
                        }
                    },
                    {
                        label: '年月日 时分',
                        onClick: () => {
                            const str = dayjs().format('YYYY-MM-DD HH:mm');
                            // 组装成新的字符串
                            event.target.value = `${frontStr}${str}${endStr}`;
                            // 自定义input事件，用于触发vue的更新
                            const inputEvent = new CustomEvent('input');
                            // 触发事件 -> 根据 视图更新数据
                            event.target.dispatchEvent(inputEvent)
                            // 聚焦
                            event.target.focus();
                        }
                    },
                    {
                        label: '年月日',
                        onClick: () => {
                            const str = dayjs().format('YYYY-MM-DD');
                            // 组装成新的字符串
                            event.target.value = `${frontStr}${str}${endStr}`;
                            // 自定义input事件，用于触发vue的更新
                            const inputEvent = new CustomEvent('input');
                            // 触发事件 -> 根据 视图更新数据
                            event.target.dispatchEvent(inputEvent)
                            // 聚焦
                            event.target.focus();
                        }
                    },
                    {
                        label: '时分秒',
                        onClick: () => {
                            const str = dayjs().format('HH:mm:ss');
                            // 组装成新的字符串
                            event.target.value = `${frontStr}${str}${endStr}`;
                            // 自定义input事件，用于触发vue的更新
                            const inputEvent = new CustomEvent('input');
                            // 触发事件 -> 根据 视图更新数据
                            event.target.dispatchEvent(inputEvent)
                            // 聚焦
                            event.target.focus();
                        }
                    }
                ]
            }
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 3, // 菜单样式 z-index
        minWidth: 230 // 主菜单最小宽度
    });
    return false;

}

// 可输入时间戳的普通input聚焦事件
export const inputFocus = (e) => {
    globalVueThis.$store.dispatch('experiment/setInput', e.target);
    // 将text实例设空
    globalVueThis.$store.dispatch('experiment/setText', null)
}

// 可输入时间戳的隐藏input聚焦事件
export const hiddenInputFocus = (e) => {
    globalVueThis.$store.dispatch('experiment/setInput', e.target);
}
// 可输入时间戳的input失焦事件
export const inputBlur = () => {
    globalVueThis.$store.dispatch('experiment/lostFocus');
}

// 可输入时间戳的input点击/键盘输入事件，用于获取光标起始位置，和终止位置
/* export const inputClickOrKey = (e) => {
    //console.log(e.target.selectionStart,e.target.selectionEnd )
    const inputInstance = globalVueThis.$store.getters.inputInstance;
    console.log(inputInstance.selectionStart)
    globalVueThis.$store.dispatch('experiment/setSelectionStart', e.target.selectionStart);
    globalVueThis.$store.dispatch('experiment/setSelectionEnd', e.target.selectionEnd);
} */

// 往input中插入时间戳
export const insertTime = (format) => {
    // 获取时间戳
    const str = dayjs().format(format);
    // 富文本实例
    const editorInstance = globalVueThis.$store.getters.editorInstance;
    if(editorInstance) {
        /*
        // quill插入的方法 
        const editorSelectionIndex = globalVueThis.$store.getters.editorSelectionIndex;
        const editorSelectionLength = globalVueThis.$store.getters.editorSelectionLength;
        if(editorSelectionLength) {
            editorInstance.quill.deleteText(editorSelectionIndex,editorSelectionLength);
        }
        editorInstance.quill.insertText(editorSelectionIndex, str);
        editorInstance.quill.focus();
        editorInstance.quill.setSelection(editorSelectionIndex + str.length, 0); */
        editorInstance.insertHtml(`<p>${str}</p>`)
        return
    }
    // 获取输入框实例
    const inputInstance = globalVueThis.$store.getters.inputInstance;
    // 光标起始索引
    const selectionStartIndex = inputInstance.selectionStart;
    // 光标终止索引
    const selectionEndStartIndex = inputInstance.selectionEnd;
    // 目前input的值
    const inputVal = inputInstance.value;
    // 光标前的字符串
    const frontStr = inputVal.substring(0, selectionStartIndex);
    // 光标后的字符串
    const endStr = inputVal.substring(selectionEndStartIndex);
    // 组装成新的字符串
    inputInstance.value = `${frontStr}${str}${endStr}`;
    // 获取插入新值后 光标的新位置
    const newIndex =  selectionEndStartIndex + str.length;
    // 获取text 实例
    const textInstance = globalVueThis.$store.getters.textInstance;
    // 如果实例存在则证明是隐藏的input
    if(textInstance) {
        const clickEvent = new CustomEvent('click');
        textInstance.dispatchEvent(clickEvent);

    }
    // 自定义input事件，用于触发vue的更新
    const inputEvent = new CustomEvent('input');
    // 触发事件 -> 根据 视图更新数据
    inputInstance.dispatchEvent(inputEvent)
    // 聚焦
    inputInstance.focus();
    // 设置光标新位置
    inputInstance.setSelectionRange(newIndex, newIndex);
}
