/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_report_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_report_a)"><path pid="1" d="M19 22H5a3 3 0 01-3-3V3a1 1 0 011-1h14a1 1 0 011 1v12h4v4a3 3 0 01-3 3zm-1-5v2a1 1 0 102 0v-2h-2zM6 7v2h8V7H6zm0 4v2h8v-2H6zm0 4v2h5v-2H6z" _fill="#4E5969"/></g>'
  }
})
