<template>
    <a-avatar v-if="url" :src="url" :size="size">
    </a-avatar>
    <a-avatar v-else :size="size" style="background: #00a870;margin-right: 8px;">
        {{name ? name[name.length - 1] : 'Null' }}
    </a-avatar>
</template>

<script>
export default {
    props: {
        name: {
          type: String,
            default: ''  
        },
        size: {
            type: String | Number,
            default: 'default'
        },
        url: {
            type: String,
            default: ''
        }
    }
}
</script>