// 实验记录接口服务
import  request from "../utils/request";

// 获取通知消息
export const getUserNotice = (data) => {
    return request({
        method: 'post',
        url: `/userNotice/pageList`,
        data
    })
}

// 搜索查询实验列表
export const queryExperimentList = (data) => {
    return request({
        method: 'post',
        url: `/workbench/queryExperiment`,
        data
    })
}

// 搜索查询项目列表
export const queryProjectList = (data) => {
    return request({
        method: 'post',
        url: `/workbench/queryproject`,
        data
    })
}

// 获取密钥
export const getPublicRSAKey = (data) => {
    return request({
        method: 'post',
        url: '/user/getPublicRsaKey',
        data
    })
}

// 上传头像
export const uploadUserPhoto = (data) => {
    return request({
        method: 'post',
        url: `/user/uploadUserPhoto`,
        data,
        timeout: 60 * 1000
    })
}

// 获取公告
export const getForceNoticeList = (data) => {
    return request({
        method: 'post',
        url: `/Notification/QueryMustReadNotic`,
        data
    })
}

// 下载附件文件
export const downLoadNoticeFile = (params) => {
    return request({
        method: 'get',
        url: `/Notification/downloadFile`,
        params,
        responseType: 'blob',
        timeout: 360000
    })
}

// 标记已读公告
export const readNotice = (params) => {
    return request({
        method: 'post',
        url: `/Notification/addlog`,
        params
    })
}


// 租户平台列表
export const getCompanyList = () => {
    return request({
        method: 'get',
        url: '/user/GetCompanyList'
    })
}

// 绑定平台
export const bindingPlatform = (params) => {
    return request({
        method: 'get',
        url: '/user/bindingPlatform',
        params
    })
}

// 更换平台
export const changePlatform = (data) => {
    return request({
        method: 'post',
        url: '/user/changePlatform',
        data
    })
} 

// 获取部门列表
export const getOrgTree = () => {
    return request({
        method: 'get',
        url: '/user/getOrgTree'
    })
}

// 修改部门
export const editMyOrg = (params) => {
    return request({
        method: 'post',
        url: '/user/editMyOrg',
        params
    })
}

export const makeReport = (params) => {
    return request({
        method: 'get',
        url: '/experiment/GetPdf'
    })
}


// 获取笼位预约记录
export const getCageLog = (data) => {
    return request({
        method: 'post',
        url: '/rackbooking/getbookinghistory',
        data
    })
}

// 获取笼位预约信息
export const getRackBookingInfo = (params) => {
    return request({
        method: 'get',
        url: '/rackbooking/getRackBookingInfo',
        params
    })
}

// 编辑笼位预约信息
export const editRackBookingInfo = (data) => {
    return request({
        method: 'post',
        url: '/rackbooking/editRackBookingInfo',
        data
    })
}

// 获取用户未脱敏信息
export const getDecryptUserInfo = () => {
    return request({
        method: 'post',
        url: '/user/getdecryptuserinfo'
    })
}

// 修改昵称
export const updateNickName = (params) => {
    return request({
        method: 'get',
        url: '/user/updateNickName',
        params
    })
}

// 搜索实验项目选择列表
export const queryExperimentInit = () => {
    return request({
        method: 'get',
        url: '/workbench/queryExperimentInit'
    })
}

// 获取通知公告附件的下载路径
export const getNotificationFileDownLoadUrl = (params) => {
    return request({
        method: 'get',
        url: '/Notification/downloadFileFromUrl',
        params
    })
}

// 获取个人云盘下载或预览的路径
export const getCloudiskFileUrl = (params) => {
    return request({
        method: 'post',
        url: '/mycloud/getFileUrl',
        params
    })
}

// 获取项目云盘下载或者预览路径
export const getProjectCloudiskFileUrl = (params) => {
    return request({
        method: 'post',
        url: '/projectcloud/getFileUrl',
        params
    })
}

