/**
 * 字段控件配置文件
*/

//const singleLineText = () => import('./singleLineText/singleLineText.vue')
import singleLineText from './singleLineText/singleLineText.vue';  // 单行文本组件
import textarea from './textarea/textarea.vue'; // 多行文本组件
import singleSelection from './singleSelection/singleSelection.vue';  // 单选下拉组件
import singleLineTextIcon from '../../../assets/component-logo/singleLineText.png'; // 单行文本图标
import textareaIcon from '../../../assets/component-logo/textarea.png'; // 多行文本图标
import singleSelectionIcon from '../../../assets/component-logo/singleSelection.png'; // 单选图标
import multiSelection from './multiSelection/multiSelection.vue'; // 多选下拉
import multiSelectionIcon from '../../../assets/component-logo/multiSelection.png'; // 多选图标
import radioButton from './radioButton/radioButton.vue';  // 单选框
import radioButtonIcon from '../../../assets/component-logo/radioButton.png';  // 单选按钮图标
import checkbox from './checkbox/checkbox.vue';   // 多选框
import checkboxIcon from '../../../assets/component-logo/checkbox.png';   // 多选框图标
import datepicker from './datepicker/datepicker.vue';   // 日期选择
import datepickerIcon from '../../../assets/component-logo/datepicker.png'; // 日期选择图标
import inputNumber from './inputNumber/inputNumber.vue';  // 数字选择组件
import inputNumberIcon from '../../../assets/component-logo/inputNumber.png';   // 数字选择图标  

const textComponentsConfig = {
  title: '字段控件',
  list: [
    {
      type: 'e-single-line-text',
      label: '单行文本',
      //icon: "icon-gallery",
      icon: singleLineTextIcon,
      component: singleLineText,
      options: {
        /* defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null,
        /* hideFullscreen: true,
        hideCollapsed: true */
      },
      model: "",
      key: "",
     /*  rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    } ,
    {
      type: 'e-textarea',
      label: '多行文本',
      icon: textareaIcon,
      component: textarea,
      options: {
       /*  defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null,
        /* hideFullscreen: true,
        hideCollapsed: true */
      },
      model: "",
      key: "",
      /* rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'e-single-selection',
      label: '单选下拉',
      icon: singleSelectionIcon,
      component: singleSelection,
      options: {
        /* defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null,
       /*  hideFullscreen: true,
        hideCollapsed: true, */
        options: [
          {
            label: '选项1'
          },
          {
            label: '选项2'
          },
          {
            label: '选项3'
          }
        ]
      },
      model: "",
      key: "",
     /*  rules: [
        {
          required: false,
          message: "必填项"
        }
      ] */
    },
    {
      type: 'e-multi-selection',
      label: '多选下拉',
      icon: multiSelectionIcon,
      component: multiSelection,
      options: {
        /* defaultValue: undefined,
        multiple: true,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [],
       /*  hideFullscreen: true,
        hideCollapsed: true, */
        options: [
          {
            label: '选项1'
          },
          {
            label: '选项2'
          },
          {
            label: '选项3'
          }
        ]
      },
      model: "",
      key: ""
    },
    {
      type: 'e-radioButton',
      label: '单选框',
      icon: radioButtonIcon,
      component: radioButton,
      options: {
       /*  defaultValue: undefined,
        multiple: true,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [],
       /*  hideFullscreen: true,
        hideCollapsed: true, */
        options: [
          {
            label: '选项1'
          },
          {
            label: '选项2'
          },
          {
            label: '选项3'
          }
        ]
      },
      model: "",
      key: ""
    },
    {
      type: 'e-checkbox',
      label: '多选框',
      icon: checkboxIcon,
      component: checkbox,
      options: {
        /* defaultValue: undefined,
        multiple: true,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: [],
       /*  hideFullscreen: true,
        hideCollapsed: true, */
        options: [
          {
            label: '选项1'
          },
          {
            label: '选项2'
          },
          {
            label: '选项3'
          }
        ]
      },
      model: "",
      key: ""
    },
    {
      type: 'e-datepicker',
      label: '日期选择',
      icon: datepickerIcon,
      component: datepicker,
      options: {
       /*  defaultValue: undefined,
        multiple: true,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null,
        /* hideFullscreen: true,
        hideCollapsed: true */
      },
      model: "",
      key: ""
    },
    {
      type: 'e-inputNumber',
      label: '数字输入',
      icon: inputNumberIcon,
      component: inputNumber,
      options: {
        /* defaultValue: undefined,
        multiple: true,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false, */
        customData: null,
        /* hideFullscreen: true,
        hideCollapsed: true */
      },
      model: "",
      key: ""
    }
  ],
  uploadFile: "",
  uploadImage: "",
  uploadFileName: "",
  uploadImageName: "",
  uploadFileData: { data: 1545 },
  uploadImageData: { data: 1545 },
  uploadFileHeaders: { data: 1545 },
  uploadImageHeaders: { data: 1545 }
}

export default textComponentsConfig;