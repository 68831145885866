<template>
    <div class="force-notice-ctn">
        <div class="force-notice-center">
            <!--  <div class="force-notice-header">
                1 / 4
            </div> -->
            <div >
                <transition-group enter-active-class="animate__animated animate__fadeInRight"
                    leave-active-class="animate__animated animate__fadeOutLeft" style="position: relative; width: 800px; margin:  auto; display: flex;">
                    <div v-for="(item, index) in forceNoticeList" :key="item.noticeId"
                        :class="[`force-notice-content`, index === 0 ? 'animate__animated animate__fadeInRight' : 'hidden-force']" >
                        <div class="force-notice">
                            <div class="notice-header"
                                style="font-size: 18px; font-weight: bold; padding-bottom: 10px; border-bottom: 1px solid #eee;">
                                重要通知,请阅读并确认!
                            </div>
                            <div class="notice-content">
                                <div class="notice-title"
                                    style="text-align: center; font-size: 18px; font-weight: bold;">
                                    {{ item.titleName }}
                                </div>
                                <div class="notice-public-date"
                                    style="text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.45);">
                                    {{ item.publicTime }}
                                </div>
                                <div style="margin-top: 32px; padding: 0px 16px; padding-bottom: 40px;"
                                    v-html="item.content">

                                </div>
                            </div>
                            <div class="file-list-ctn" v-if="item.fileLst && item.fileLst.length > 0">
                                <!-- <div class="">
                            附件：
                        </div> -->
                                <div>
                                    <div v-for="(fItem, fIndex) in item.fileLst" :key="fIndex" class="file-item"
                                        style="display: flex;">
                                        <div style="font-size: 16px; font-weight: bold;">
                                            附件{{ fIndex + 1 }}:
                                        </div>
                                        <div style="margin-left: 15px;">
                                            <!-- <a @click="handleDownloadFile(fItem)">
                                                {{ fItem.fileName }}.{{ fItem.fileExtensionName }}
                                            </a> -->
                                            <a @click="downloadFileFromUrl(fItem)">
                                                {{ fItem.fileName }}.{{ fItem.fileExtensionName }}
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="notice-footer">
                                <a-button :loading="loading" :disabled="!!timer" size="large" type="primary"
                                    @click="read(item, index)">
                                    我已阅读悉知<span v-if="timer && time >= 0"> ({{ time }}s)</span>
                                </a-button>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, ref, onMounted, computed, nextTick, onBeforeUnmount } from 'vue-demi';
import store from '../../store';
import { downLoadNoticeFile,getNotificationFileDownLoadUrl , readNotice } from '../../api/others';
import { message, Modal } from 'ant-design-vue';

export default {
    setup(props, context) {
        const count = ref([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
        onMounted(() => {
            document.documentElement.style.overflow = 'hidden';
            nextTick(() => {
                resetTimer();
            })
        })
        onBeforeMount(() => {
            document.documentElement.style.overflow = 'auto';
        })
        const handleTab = () => {
            count.value.splice(0, 1)
        }

        const timer = ref(null); 	// 倒计时控制器
        const time = ref(5);  	// 倒计时
        const loading = ref(false);	// 是否正在提交
        const read = async (record, index) => {
            loading.value = true;
            const res = await readNotice({ NoticeId: record.noticeId });
            if (res.code === 200 || res.code === 204) {
                message.success(res.message);
                store.commit('sysNotice/removeForceNoticeByIndex', index);
                // resetTimer();
            }
            /*  setTimeout(() => {
                 loading.value = false;
             }, 2000) */
            loading.value = false;
            /* document.querySelectorAll('notice-menu-item');
            noticeMenu.value.scroll({
            
            }) */
        }
        const noticeMenu = ref(null);

        const forceNoticeList = computed(() => {
            return store.state.sysNotice.forceNoticeList;
        })

        const handleDownloadFile = async (item) => {
            store.commit('updateSpin', true)
            await downLoadNoticeFile({
                FileId: item.id,
            }).then((res) => {
                if(res.type === `application/json`) {
                    const reader = new FileReader();
                    reader.onload = fileRes => {
                        const { result } = fileRes.target;  // 得到字符串
                        const resData = JSON.parse(result);
                        if(resData.message && resData.code !== 200) {
                            message.error(resData.message)
                        }
                        return ;
                    }
                    reader.readAsText(new Blob([res]), 'utf-8')
                    /* message.error(res.data.message); */
                    return ;
                }
                const blob = new Blob([res.data], {
                    type: 'application/octet-stream'
                }); //处理文档流
                const fileName = `${item.fileName}.${item.fileExtensionName}`;
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch((error) => {
                Modal.error({
                    title: '错误',
                    content: typeof error === 'string' ? error : JSON.stringify(error),
                    cancelText: ''
                });
            });
            store.commit('updateSpin', false)
        }

        const resetTimer = () => {
            timer.value && clearInterval(timer.value);
            time.value = 5;
            timer.value = setInterval(countDown, 1000);
        }

        const countDown = () => {
            if (time.value > 0 && timer.value) {
                time.value = time.value - 1;
            }
            else if (timer.value && time.value <= 0) {
                clearInterval(timer.value);
                timer.value = null;
            }
        }

        const downloadFileFromUrl = async (file) => {
            store.commit('updateSpin',true)
			const res = await getNotificationFileDownLoadUrl({
				FileId: file.id
			});
			if(res.success) {
				const fileName = `${file.fileName}.${file.fileExtensionName}`;
				const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = res.data;
				//elink.target = "_blank";
                document.body.appendChild(elink);
                elink.click();
                //URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
			}
            store.commit('updateSpin',false)
		}

        onBeforeUnmount(() => {
            timer.value && clearInterval(timer.value);
        })

        return {
            count,
            handleTab,
            read,
            noticeMenu,
            forceNoticeList,
            handleDownloadFile,
            time,
            loading,
            timer,
            resetTimer,
            countDown,
            downloadFileFromUrl
        }
    }
}
</script>

<style lang="less" scoped>
.force-notice-ctn {
    background: rgba(0, 0, 0, 0.65);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    /*  display: flex;
    justify-content: center;
    align-items: center; */
    //padding: 600px 0px;
    padding: 40px 0px 250px 0px;
    overflow-y: auto;
    overflow-x: hidden;

    .force-notice-center {
        margin: auto;
        margin-top: 40px;
        width: fit-content;
        // display: flex;

        .force-notice-header {
            color: #fff;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
        }

        .force-notice-content {
            margin-top: 20px;
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;

            .force-notice {
                width: 800px;
                background: #fff;
                border-radius: 8px;
                display: flex;
                flex-direction: column;

                .notice-content {
                    min-height: 300px;
                    flex-grow: 1;
                    padding: 16px 16px 45px 16px;
                }

                .notice-header,
                .notice-footer {
                    text-align: center;
                    padding: 16px;
                }

                .notice-footer {
                    border-top: 1px solid #eee;
                }
            }

            .notice-nav-bar {
                margin-left: 50px;

                .nav-bar-ctn {
                    height: 430px;
                    margin: 15px 0px;

                    .nav-item {
                        height: 100px;
                        width: 100px;
                        background: #fff;
                        border-radius: 4px;
                        margin: 0 auto;
                        cursor: pointer;
                        overflow: hidden;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

                        &:hover {
                            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.85);
                        }
                    }

                    .nav-item+.nav-item {
                        margin-top: 10px;
                    }
                }

                .arrow-up,
                .arrow-down {
                    width: 110px;
                    background: rgba(0, 0, 0, 0.65);
                    display: flex;
                    padding: 8px;
                    justify-content: center;
                    color: #fff;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }

            .notice-menu-item {
                cursor: pointer;

                &:hover {
                    background: #f6f6f6;
                    transition: .2s all ease-in-out;
                }
            }
        }

        .force-notice-content+.force-notice-content {
            margin-left: 20px;
        }
    }
}
</style>

<style lang="less" scoped>
ul {
    padding-left: 0;
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateY(-130px);
}

.file-list-ctn {
    display: flex;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    padding: 16px 50px 16px 32px;
    border-bottom: 1px solid #eee;

}

.file-item+.file-item {
    margin-top: 8px;
}
.hidden-force {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: .2s all ease;
}
</style>