/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fullscreen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_fullscreen_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_fullscreen_a)" style="mix-blend-mode:passthrough"><path pid="1" d="M2.879 2.879A3 3 0 015 2h3a1 1 0 010 2H5a1 1 0 00-1 1v3a1 1 0 01-2 0V5a3 3 0 01.879-2.121zM15 3a1 1 0 011-1h3a3 3 0 013 3v3a1 1 0 11-2 0V5a1 1 0 00-1-1h-3a1 1 0 01-1-1zM3 15a1 1 0 011 1v3a1 1 0 001 1h3a1 1 0 110 2H5a3 3 0 01-3-3v-3a1 1 0 011-1zm18 0a1 1 0 011 1v3a3 3 0 01-3 3h-3a1 1 0 110-2h3a1 1 0 001-1v-3a1 1 0 011-1z" fill-rule="evenodd" _fill="#4E5969"/></g>'
  }
})
