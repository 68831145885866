import { computed } from "@vue/composition-api";

// 文件服务预览地址
export const filePreviewServerUrl = `http://192.168.2.3:8012/onlinePreview?url=`;

// 根据index获取对应的职务名称
const getProjectMemberTypeText = (typeIndex) => {
    if(typeIndex == 1) {
        return '普通'
    }
    else if(typeIndex == 2) {
        return '项目助理'
    }
    else if(typeIndex == 3) {
        return '审核人'
    }
    else if(typeIndex == 4) {
        return '负责人'
    }
    else {
        return ''
    }
}

// 根据字符串获取 职务名称获取角色字符串
export const getProjectMemberTypes = (typeStr) => {
    const res = [];
    const temps = typeStr.toString().split(',');
    temps.forEach(element => {
        res.push(getProjectMemberTypeText(element));
    })
    return res.join('、');
}

// 劳务费标准类型
export const laborCostTypes = [
    {
        label: '元/天',
        value: 1
    },
    {
        label: '元/月',
        value: 2
    }
]


// 获取实验流程字符串

// 支持的图片后缀
const imageSuffix = [
    '.jpg',
    '.jpeg',
    '.png',
    '.bmp',
    '.gif',
    '.raw'
]
// excel

// 根据后缀获取文件类型
export const getFileTypeBySuffix = (suffix) => {
    if(imageSuffix.indexOf(suffix) !== -1) {
        return 'image';
    }
    if(suffix === '.xlsx') {
        return 'xlsx';
    }
    if(suffix === '.mp4') {
        return 'mp4';
    }
    if(suffix === '.mp3') {
        return 'mp3';
    }
    if(suffix === '.pdf') {
        return 'pdf';
    }
    if(suffix === '.txt') {
        return 'txt'
    }
    if(suffix === '.pptx') {
        return 'pptx'
    }
    if(['.doc', '.docx']) {
        return 'doc'
    }
    return 'undefined';
}

// 获取结论颜色
export const getConclusionColors = (conclusion) => {
    if (conclusion === 1) {
        return '#52C41A';
    }
    else if (conclusion === 2) {
        return '#FF4D4F'
    }
    else if (conclusion === 3) {
        return `rgba(0, 0, 0, 0.45)`
    }
    else {
        return `rgba(0, 0, 0, 0.85)`
    }
}

// 申诉状态类型列表
export const goodFaithAppealTypes = [
    {
        label: '申诉中',
        value: 1
    },
    {
        label: '已撤销',
        value: 2
    },
    {
        label: '驳回',
        value: 3
    },
    {
        label: '已扣分',
        value: 4
    }
]

// 获取申诉状态类型名
export const getGoodFaithAppealTypeName = (state) => {
    if(state == 1) {
        return '申诉中';
    }
    else if(state == 2) {
        return '已撤销';
    }
    else if(state == 3) {
        return '驳回';
    }
    else if(state == 4) {
        return '已扣分'
    }
    else {
        return '————'
    }
}

// 获取申诉类型对应颜色
export const getGoodFaithAppealTypeColor = (state) => {
    if(state == 1) {
        return '#1890FF';
    }
    else if(state == 2) {
        return 'rgba(0, 0, 0, 0.45)';
    }
    else if(state == 3) {
        return '#FF4D4F';
    }
    else if(state === 4) {
        return '';
    }
    else {
        return '';
    }
}

// 获取实验流程状态颜色
export const getExperimentFlowStatuColor = (statu) => {
    // 1. 编辑， 2. 申请归档，3. 归档成功
    if(statu == 1) {
        return '#2F54EB';
    }
    else if(statu == 2) {
        return '#FAAD14' ;
    }
    else if(statu == 3) {
        return '#52C41A';
    }
    else {
        return '#ffffff';
    }
}

// 实验状态
export const ExperimentConclusionArr = ['', '成功', '失败', '停止']


// 笼位预约状态选择列表
export const cageAppointStatuList = [
    {
        label: '申请中',
        value: 1
    },
    {
        label: '通过',
        value: 2
    },
    {
        label: '不通过',
        value: 3
    },
    {
        label: '过期',
        value: 4
    },
    {
        label: '取消',
        value: 5
    }
]
// 笼位预约字典
export const cageAppointStatuMap = computed(() => {
    return cageAppointStatuList.reduce((ob, current) => {
         ob[current.value] = current.label;
         return ob
    }, {})
})

// 笼位预约颜色字典
export const cageAppointStatuColorMap = {
    1: 'orange',
    2: 'green',
    3: 'red',
    4: 'gray',
    5: '',
}

// 课题组成员身份枚举
export const researchTeamIdentityMap = {
    1: 'PI',
    2: '导师',
    3: '成员'
}

// 课题组成员身份列表
export const researchTeamIdentityList = computed(() => {
    return Object.keys(researchTeamIdentityMap).map(key => {
        return {
            label: researchTeamIdentityMap[key],
            value: key
        }
    })
})

const experimentStatuEnum = {
    1: '编辑',
    2: '申请归档',
    3: '归档成功'
}

// 实验流程状态文本
export const getExperimentStatuText = (status) => {
    return experimentStatuEnum[status]
}