import Vue from 'vue';
import  {
    Base,
    //Affix,
   // Anchor,
    AutoComplete,
    Alert,
    Avatar,
    BackTop,
    Badge,
    Breadcrumb,
    Button,
    //Calendar,
    Card,
    Collapse,
    //Carousel,
    Cascader,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    FormModel,
    Icon,
    Input,
    InputNumber,
    Layout,
    //List,
    LocaleProvider,
    Menu,
    //Mentions,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Progress,
    Radio,
    //Rate,
    Row,
    Select,
    //Slider,
    Spin,
    //Statistic,
    //Steps,
    //Switch,
    Table,
    //Transfer,
    Tree,
    TreeSelect,
    Tabs,
    Tag,
    TimePicker,
    Timeline,
    Tooltip,
    Upload,
    Drawer,
    Skeleton,
    Comment,
    // ColorPicker,
    ConfigProvider,
    Empty,
    Result,
    //Descriptions,
    PageHeader,
    Space,
    message
} from 'ant-design-vue';

const components = [
    Base,
    //Affix,
   // Anchor,
    AutoComplete,
    Alert,
    Avatar,
    BackTop,
    Badge,
    Breadcrumb,
    Button,
    //Calendar,
    Card,
    Collapse,
    //Carousel,
    Cascader,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    FormModel,
    Icon,
    Input,
    InputNumber,
    Layout,
    //List,
    LocaleProvider,
    Menu,
    //Mentions,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Progress,
    Radio,
    //Rate,
    Row,
    Select,
    //Slider,
    Spin,
    //Statistic,
    //Steps,
    //Switch,
    Table,
    //Transfer,
    Tree,
    TreeSelect,
    Tabs,
    Tag,
    TimePicker,
    Timeline,
    Tooltip,
    Upload,
    Drawer,
    Skeleton,
    Comment,
    // ColorPicker,
    ConfigProvider,
    Empty,
    Result,
    //Descriptions,
    PageHeader,
    //Space,
]

for(let i = 0; i < components.length; ++i) {
    Vue.use(components[i]);
}

Vue.config.productionTip = false;

Vue.prototype.$message = message;

const moment = require('moment');
require('moment/locale/zh-cn');

moment.locale('zh-cn');