/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_star_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_star_a)"><path pid="1" d="M11.944 2.159q1.113 0 1.586.963l2.21 4.486 5.085.74q1.056.152 1.4 1.127.347.983-.41 1.72l-3.73 3.644.864 5.048q.18 1.047-.726 1.659-.455.303-1.005.303-.444 0-.842-.207l-4.432-2.334-4.432 2.335q-.397.206-.842.206-.549 0-1.006-.305-.905-.611-.725-1.657l.865-5.048-3.729-3.643q-.757-.736-.412-1.721.344-.975 1.4-1.127l5.086-.74 2.21-4.487q.476-.962 1.585-.962z" fill-rule="evenodd" _fill="#D8D8D8"/></g>'
  }
})
