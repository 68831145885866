<template>
    <div class="table-container">
        <div class="btns">
            <div class="left-btns">
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增 </a-button>
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 导入 </a-button>
            </div>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick" :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="showColumns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode === 'edit' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null" style="width: 100%;">
            
            <template v-for="(col, columIndex) in ['name', 'code', 'model','expirationDate', 'manufacturers', 'storageLocation', 'measurementAccuracyType', 'appointment', 'useRecord']" :slot="col" slot-scope="text, record, index">
                <div v-if="record.id && (['appointment', 'useRecord'].indexOf(col) !== -1)">
                    <div v-if="col === 'appointment'">
                        <a v-show="(record.id && !record.appointment || (record.appointment && record.appointment.length === 0)) && mode ==='edit'" @click="handleSelectedAppointmentClick(record) "> 选择 </a>
                        <div v-show="(record.id && !record.appointment || (record.appointment && record.appointment.length === 0)) && mode!=='edit'" > —— </div>
                        <a v-show="record.id && record.appointment && record.appointment.length" @click="handleSelectedAppointmentClick(record)">
                            <div class="divide" v-for="(item,index) in record.appointment" :key="index" style="margin-top: 8px;">
                                <span> {{ item['begin_Time'] }} </span>
                                <span v-if="item['end_Time']"> <span style="margin: 0px 8px;"> —— </span> {{item['end_Time'] }}</span>
                            </div>
                        </a>
                    </div>
                    <div v-else-if="col === 'useRecord'">
                        <a v-show="(record.id && !record.useRecord || (record.useRecord && record.useRecord.length === 0)) && mode === 'edit'" @click="handleSelectedUseRecordClick(record) "> 选择 </a>
                        <div class="divide" v-show="(record.id && !record.useRecord || (record.useRecord && record.useRecord.length === 0)) && mode !== 'edit'" > —— </div>
                        <a v-show="record.id && record.useRecord && record.useRecord.length" @click="handleSelectedUseRecordClick(record)">
                            <div class="divide" v-for="(item,index) in record.useRecord" :key="index" style="margin-top: 8px;">
                                <span> {{ item['open_Time'] }} </span>
                                <span v-if="item['close_Time']"> <span style="margin: 0px 8px;"> —— </span> {{item['close_Time'] }}</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div v-else-if="record.id && (['appointment', 'useRecord'].indexOf(col) === -1)">
                    <div class="table-cell">{{ text }}</div>
                </div>
                <div v-else-if="['expirationDate'].indexOf(col) !== -1" :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'" :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="handleTextClick(col, columIndex, index)">
                        {{ customData[index].expirationDate }}
                    </div>
                    <div v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <!--a-input autoFocus v-model="customData[index][col]"  @blur="handleBlur">
                        </a-input-->
                        <a-date-picker valueFormat="YYYY-MM-DD" autoFocus v-model="customData[index].expirationDate"
                            :ref="`${col}${index}`"  @blur="handleDatePickerBlur" @openChange="handleOpenChange"/>
                    </div>
                </div>
                <div v-else :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'" :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="($event) => handleHiddenInputClick(col, columIndex, index, $event)">
                        <div v-for="(item, index) in handleSplitText(customData[index][col])" style="margin-top: 8px; word-break: break-all;">
                            {{ item }}
                            <!-- {{ customData[index][col] }} -->
                        </div>
                    </div>
                    <div v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <a-input  :autoSize="true" autoFocus type="textarea" @contextmenu.prevent="getConextMenu" v-model="customData[index][col]" :ref="`${col}${index}`" @focus="hiddenInputFocus" @blur="handleBlur" >
                        </a-input>
                    </div>
                </div>
            </template>
            <template slot="source" slot-scope="text, record, index">
                <span :style="{ color: text === '导入' ? '#303133' : '#337ecc' }">{{ text }}</span>
            </template>
            <!-- <template slot="appointment" slot-scope="text, record, index">
                <a v-show="record.id && !record.appointment || (record.appointment && record.appointment.length === 0)" @click="handleSelectedAppointmentClick(record)"> 选择 </a>
                <a v-show="record.id && record.appointment && record.appointment.length" @click="handleSelectedAppointmentClick(record)">
                    <div v-for="(item,index) in record.appointment" :key="index" style="margin-top: 8px;">
                        <span> {{ item['begin_Time'] }} </span>
                        <span v-if="item['end_Time']"> <span style="margin: 0px 8px;"> —— </span> {{item['end_Time'] }}</span>
                    </div>
                </a>
            </template>
            <template slot="useRecord" slot-scope="text, record, index">
                <a v-show="record.id && !record.useRecord || (record.useRecord && record.useRecord.length === 0)" @click="handleSelectedUseRecordClick(record)"> 选择 </a>
                 <a v-show="record.id && record.useRecord && record.useRecord.length" @click="handleSelectedUseRecordClick(record)">
                    <div v-for="(item,index) in record.useRecord" :key="index" style="margin-top: 8px;">
                        <span> {{ item['open_Time'] }} </span>
                        <span v-if="item['close_Time']"> <span style="margin: 0px 8px;"> —— </span> {{item['close_Time'] }}</span>
                    </div>
                </a>
            </template> -->
            <!-- <template slot="date" slot-scope="text, record, index">
                <div v-show="!isInputShow(index, 2) || mode !== 'edit'" class="table-cell"
                    @click="handleTextClick('date', 2, index)">
                    {{ customData[index].date }}
                </div>
                <div v-show="isInputShow(index, 2) && mode === 'edit'">
                    <a-date-picker valueFormat="YYYY-MM-DD" autoFocus v-model="customData[index].date"
                        :ref="`date${index}`"  @blur="handleBlur" />
                </div>
            </template> -->
        </a-table>
        <ImportEquipmentModal :visible.sync="importEquipmentModalVisible" @importConfirm="handleImportConfirm" :disabledList="usedEquiments" :selectedEquitmentList="customData"></ImportEquipmentModal>
        <SelectAppointmentModal  :visible.sync="selectAppointmentModalVisible" :disabledList="selectedAppointmentItem.appointment" :selectedAppointment="selectedAppointmentItem" @importConfirm="selectedAppointmentConfirm">
        </SelectAppointmentModal>
        <SelectUsedRecordModal :visible.sync="selectUsedRecordModalVisible" :disabledList="selectedUsedRecordItem.useRecord" :selectedUsedRecordItem="selectedUsedRecordItem" @importConfirm="handleSelectedUsedRecordConfirm">
        </SelectUsedRecordModal>
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;
        .ant-btn {
            width: 58px;
        }
        .operation-btn {
            background: #eeeeee;
        }
        .left-btns {
            width: 0px;
            flex-grow: 1;
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
        word-break: break-all;
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
import ImportEquipmentModal from './components/importEquipmentModal.vue';
import SelectAppointmentModal from './components/selectAppointmentModal.vue';
import SelectUsedRecordModal from './components/selectUsedRecordModal.vue';
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    // props: {
    //   record: {
    //     type: Object,
    //     require: true
    //   },
    //   value: {
    //     type: String,
    //     default: ''
    //   }
    // },
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            columns: [
                {
                    dataIndex: "name",
                    title: "仪器名称",
                    //width: "15%",
                    colSpan: 1,
                    scopedSlots: { customRender: "name" }
                },
                {
                    dataIndex: "code",
                    title: "编号",
                    //width: "11%",
                    colSpan: 1,
                    scopedSlots: { customRender: "code" }
                },
                {
                    dataIndex: "model",
                    title: "型号",
                    //width: "13%",
                    colSpan: 1,
                    scopedSlots: { customRender: "model" }
                },
                {
                    dataIndex: "source",
                    title: "记录来源",
                    //width: "13%",
                    colSpan: 1,
                    scopedSlots: { customRender: "source" }
                },
                {
                    dataIndex: "expirationDate",
                    title: "检验有效期",
                    //width: "15%",
                    colSpan: 1,
                    scopedSlots: { customRender: "expirationDate" }
                },
                {
                    dataIndex: "manufacturers",
                    title: "厂商",
                    //width: "18%",
                    colSpan: 1,
                    scopedSlots: { customRender: "manufacturers" }
                },
                {
                    dataIndex: "storageLocation",
                    title: "仪器位置",
                    //width: "18%",
                    colSpan: 1,
                    scopedSlots: { customRender: "storageLocation" }
                },
                {
                    dataIndex: "measurementAccuracyType",
                    title: "度量精度",
                    //width: "10%",
                    colSpan: 1,
                    scopedSlots: { customRender: "measurementAccuracyType" }
                },
                {
                    dataIndex: "appointment",
                    title: "仪器预约记录",
                    colSpan: 1,
                    scopedSlots: { customRender: "appointment" }
                },
                {
                    dataIndex: "useRecord",
                    title: "上下机记录",
                    colSpan: 1,
                    scopedSlots: { customRender: "useRecord" }
                }
            ],
            isEditting: false,
            rowIndex: null,
            colIndex: null,
            selectedRowKeys: [], // 选中行的key 列表
            importEquipmentModalVisible: false,  // 导入对话框
            datePickerOpen: false,
            // 选择预约记录对话框可见变量
            selectAppointmentModalVisible: false,
            selectedAppointmentItem: {},
            selectUsedRecordModalVisible: false,
            selectedUsedRecordItem: {}

        };
    },
    methods: {
        handleChange() {
            this.customData.push({
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                key: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].key + 1,
                name: "",
                code: "",
                source: this.$store.state.userInfo.name ? this.$store.state.userInfo.name : '新增',
                model: "",
                expirationDate: "",
                manufacturers: "",
                storageLocation: "",
                measurementAccuracyType: ""
            });
        },
        // 其他隐藏组件点击事件
        handleTextClick(col, columIndex, index) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            //const that = this;
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 隐藏的输入框点击事件
        handleHiddenInputClick(col, columIndex, index, e) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            this.$store.dispatch("experiment/setText", e.target);
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 输入框隐藏事件
        isInputShow(index, columIndex) {
            return this.isEditting && this.rowIndex === index && this.colIndex === columIndex;
        },
        // 输入框失焦失焦， 恢复label形式
        handleBlur() {
            this.rowIndex = null;
            this.colIndex = null;
            this.isEditting = false;
            inputBlur();    // 时间戳不可选
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            /* this.$confirm({
                title: '是否确认删除？',
                onOk() {
                    return new Promise((resolve, reject) => {
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    }).catch(() => console.log('Oops errors!'));
                },
                onCancel() { },
            }); */
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        // 导入点击事件
        handleImportClick() {
            this.importEquipmentModalVisible = true;
        },
        // 导入确认事件
        handleImportConfirm(rows) {
           
            // 所有导入行的is
            const ids = rows.map(row => row.id);
            // 新数据
            const newData = [];
            // 
            const copy = JSON.parse(JSON.stringify(this.customData))
            for(let i = 0; i < copy.length; ++ i) {
                if(!copy[i].id) {
                    newData.push(copy[i]);
                }
                else {
                    const index = ids.indexOf(copy[i].id);
                    if(index !== -1) {
                        newData.push( JSON.parse(JSON.stringify( { ...rows[index], source:  '导入' })));
                    }
                }
            }
            //console.log({ newData })
            this.customData.splice(0,this.customData.length, ...newData);
            //console.log({ customData: this.customData })
            const oldIds = this.customData.map(row => row.id);
            const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            const addData = rows.filter(row => oldIds.indexOf(row.id) === -1);
            //console.log({ addData})
            //console.log(rows)
             for(let i = 0; i < addData.length; ++ i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(addData[i])),
                    source:  '导入'
                });
            }
           /*  for(let i = 0; i < this.customData.length; ++i) {
                this.customData[i].index = i;
            } */
            this.importEquipmentModalVisible = false;
           /*  const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for(let i = 0; i < rows.length; ++ i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(rows[i])),
                    source:'导入'
                });
            } */
        },
        // 
        handleOpenChange(status) {
            this.datePickerOpen = status;
            if(!this.datePickerOpen) {
                this.rowIndex = null;
                this.colIndex = null;
                this.isEditting = false;
                inputBlur();
            }
        },
        handleDatePickerBlur() {
            if(!this.datePickerOpen) {
                this.rowIndex = null;
                this.colIndex = null;
                this.isEditting = false;
                inputBlur();
            } 
        },
        handleSelectedAppointmentClick() {
            return
        },
        handleSelectedUseRecordClick(record) {
            return 
        },
        /**********************************              选择预约记录对话框  start                **************************/
        // 点击选择选中的仪器
        handleSelectedAppointmentClick(record){
            if(this.mode !== 'edit') {
                return 
            }
            this.selectedAppointmentItem = record;
            if(!this.selectedAppointmentItem.appointment) {
                this.selectedAppointmentItem.appointment = [];
            }
            this.selectAppointmentModalVisible = true;
        },
        // 选择预约记录确定事件
        selectedAppointmentConfirm(){
            this.selectAppointmentModalVisible = false;
        },

        /**********************************              选择预约记录对话框  end                  **************************/
        /**********************************              选择上下机记录对话框  start              **************************/
        // 点击选择选中仪器
        handleSelectedUseRecordClick(record) {
           if(this.mode !== 'edit') {
                return 
           }
           this.selectedUsedRecordItem = record;
           if(!this.selectedUsedRecordItem.useRecord) {
               this. selectedUsedRecordItem.useRecord = [];
           }
           this.selectUsedRecordModalVisible = true;
        },
        // 选择预约记录确定时间回调
        handleSelectedUsedRecordConfirm(){
            this.selectUsedRecordModalVisible = false;
        },
        /**********************************              选择上下机记录对话框  end                **************************/
        // 拆分str        
        handleSplitText(text) {
            if(!text) {
                return []
            }
            const arr = text.split('\n');
            return arr;
        }
     },
    components: { ImportEquipmentModal, SelectAppointmentModal, SelectUsedRecordModal },
    computed: {
        showColumns() {
            const showColumns = this.record.options.showColumns.filter(col => col.checked);
            const showKeys = showColumns.map(col => col.value);
            return this.columns.filter(col => {
                return col.dataIndex === 'name' || showKeys.indexOf(col.dataIndex) !== -1;
            })
        },
        // 已导入的仪器
        usedEquiments() {
            const importedList = this.customData.filter(imp => imp.id);
            return importedList.map(imp => imp.id);
        }
    }
};
</script>