/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sample-info': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<clipPath id="svgicon_sample-info_a"><rect pid="0" width="40" height="40" rx="0"/></clipPath><g _fill="#4E5969" clip-path="url(#svgicon_sample-info_a)"><path pid="1" d="M33.2 38.667H6.8c-2 0-3.667-1.667-3.667-3.667V5c0-2 1.667-3.667 3.667-3.667h26.467c2 0 3.666 1.667 3.666 3.667v30c-.066 2-1.666 3.667-3.733 3.667zM7.8 4.333A1.65 1.65 0 006.133 6v28A1.65 1.65 0 007.8 35.667h24.467A1.65 1.65 0 0033.933 34V6a1.65 1.65 0 00-1.666-1.667H7.8z"/><path pid="2" d="M15.8 18.133h-4.4c-1.267 0-2.333-1.066-2.333-2.333V11c0-1.267 1.066-2.333 2.333-2.333h4.4c1.267 0 2.333 1.066 2.333 2.333v4.8c0 1.333-1 2.333-2.333 2.333zm-4.4-7.466c-.2 0-.333.133-.333.333v4.8c0 .2.133.333.333.333h4.4c.2 0 .333-.133.333-.333V11c0-.2-.133-.333-.333-.333h-4.4zm18.533.866h-6.666c-.534 0-1-.466-1-1 0-.533.466-1 1-1h6.666c.534 0 1 .467 1 1 0 .534-.466 1-1 1zm0 5.734h-6.666c-.534 0-1-.467-1-1 0-.534.466-1 1-1h6.666c.534 0 1 .466 1 1 0 .533-.466 1-1 1zm0 8.066H10.067c-.534 0-1-.466-1-1 0-.533.466-1 1-1h19.8c.533 0 1 .467 1 1 0 .534-.4 1-.934 1zM29.933 31.333H10.067c-.534 0-1-.466-1-1 0-.533.466-1 1-1h19.8c.533 0 1 .467 1 1 0 .534-.4 1-.934 1z"/></g>'
  }
})
