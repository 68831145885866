/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><clipPath id="svgicon_trash-2_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath></defs><g style="mix-blend-mode:passthrough" clip-path="url(#svgicon_trash-2_a)"><path pid="1" d="M6.667 2A.667.667 0 006 2.667v.666h4v-.666A.667.667 0 009.333 2H6.667zm4.666 1.333v-.666a2 2 0 00-2-2H6.667a2 2 0 00-2 2v.666H2a.667.667 0 000 1.334h.667v8.666a2 2 0 002 2h6.666a2 2 0 002-2V4.667H14a.667.667 0 000-1.334h-2.667zM4 4.667v8.666a.667.667 0 00.667.667h6.666a.667.667 0 00.667-.667V4.667H4zm2.667 2c.368 0 .666.298.666.666v4a.667.667 0 01-1.333 0v-4c0-.368.298-.666.667-.666zm2 .666a.667.667 0 111.333 0v4a.667.667 0 11-1.333 0v-4z" fill-rule="evenodd" fill-opacity=".65"/></g>'
  }
})
