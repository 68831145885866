<template>
    <div class="table-container experiment-document-table">
        <div class="btns">
            <div v-if="type === 'template' && mode === `edit`" class="left-btns">
                <!-- <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增 </a-button>
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 导入 </a-button> -->
                <a-tooltip title="模板暂不支持上传文件">
                    <a-upload disabled class="upload" :showUploadList="false" v-show="mode === 'edit'" :multiple="false"
                        :customRequest="handleFileUpload">
                        <a-button disabled size="small" class="operation-btn"> 本地导入 </a-button>
                    </a-upload>
                </a-tooltip>
                <a-tooltip title="模板暂不支持导入文件">
                    <a-button disabled size="small" class="operation-btn" v-show="mode === 'edit'"
                        @click="handleImportClick"> 云盘导入
                    </a-button>
                </a-tooltip>
            </div>
            <div v-else class="left-btns">
                <!-- <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增 </a-button>
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 导入 </a-button> -->
                <a-upload class="upload" :showUploadList="false" v-show="mode === 'edit'" :multiple="false"
                    :customRequest="handleFileUpload">
                    <a-button size="small" class="operation-btn"> 本地导入 </a-button>
                </a-upload>
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 云盘导入
                </a-button>
            </div>
            <!-- <div v-if="isFileDownloading" style="margin: 0 12px;">
                ( 下载进度可在屏幕左上角查看 )
            </div> -->
            <a-button size="small" class="operation-btn" v-show="mode === 'edit' || mode === 'preview'"
                :disabled="!selectedRowKeys.length" :loading="isFileDownloading" @click="handleDownloadClick"> 下载
            </a-button>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick"
                :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="columns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode !== 'pdf' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null">
            <template v-for="(col, columIndex) in ['remark']" :slot="col" slot-scope="text, record, index, column1">
                <div :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'"
                        :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="($event) => handleHiddenInputClick(col, columIndex, index, $event)">
                        {{ customData[index][col] }}
                    </div>
                    <div v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <a-input autoFocus @contextmenu.prevent="getConextMenu" v-model="customData[index][col]"
                            :ref="`${col}${index}`" @focus="hiddenInputFocus" @blur="handleBlur">
                        </a-input>
                    </div>
                </div>
            </template>
            <template slot="fileName" slot-scope="text, record, index">
                <a-tooltip v-if="!getFileTypeSupported(record)" title="暂不支持该类型文件预览">
                    <a style="cursor: not-allowed;"> {{ record.fileName }} </a>
                </a-tooltip>
                <a-tooltip v-else @click.prevent="handlePreviewClick(record, index)" title="点击预览">
                    <a v-if="mode === 'edit'" target="_blank"> {{ record.fileName }} </a>
                    <a v-else >{{ record.fileName }}</a>
                </a-tooltip>
            </template>
            <template slot="fileSize" slot-scope="text, record, index">
                <div v-if="record.fileSize != ''">
                    {{ transferBitSize(record.fileSize) }}
                </div>
            </template>
        </a-table>
        <!-- <ImportEquipmentModal :visible.sync="importEquipmentModalVisible" @importConfirm="handleImportConfirm"></ImportEquipmentModal> -->
        <importFromCloudModal :visible.sync="importEquipmentModalVisible" @importConfirm="handleImportConfirm"
            :disabledList="allImportFileList">
        </importFromCloudModal>
        <FilePreviewModal v-if="filePreviewModalVisible" :visible.sync="filePreviewModalVisible" :fileType="filePreviewType"
            :fileUrl="filePreviewUrl" :fileName="filePreviewName"></FilePreviewModal>
        <ExcelEditModal v-if="excelEditModalVisible" :visible.sync="excelEditModalVisible" :fileUrl="filePreviewUrl"
            :fileName="filePreviewName" @onExcelEdit="onExcelEdit"></ExcelEditModal>
        <!--  <DownloadFileFloatDrawer>

        </DownloadFileFloatDrawer> -->
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;

        .ant-btn {
            min-width: 58px;
        }

        .operation-btn {
            background: #eeeeee;
        }

        .left-btns {
            width: 0px;
            flex-grow: 1;
            display: flex;

            .upload {
                margin-right: 8px;
            }
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { message, Modal } from 'ant-design-vue';
import { downloadExperimentFile, getExperimentFilePath } from '../../../../api/experiment';
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
import importFromCloudModal from './components/importFromCloudModal.vue';
import JSZip, { file } from 'jszip';
import axios from 'axios';
import FileSaver from 'file-saver';
import { getFileTypeBySuffix } from '../../../../utils/enums';

import FilePreviewModal from './components/filePreviewModal.vue';
import store from '../../../../store';
import ExcelEditModal from '../../../../components/excelEditModal.vue';
import { Base64 } from 'js-base64';
import DownloadFileFloatDrawer from '../../../../components/common/downloadFileFloatDrawer.vue';
import { transferBitSize } from '../../../../utils/utils';
import debounce from 'lodash/debounce';

export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode", "type"],
    // props: {
    //   record: {
    //     type: Object,
    //     require: true
    //   },
    //   value: {
    //     type: String,
    //     default: ''
    //   }
    // },
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            columns: [
                {
                    dataIndex: "fileName",
                    title: "名称",
                    width: "25%",
                    scopedSlots: { customRender: "fileName" }
                },
                {
                    dataIndex: "fileExtensionName",
                    title: "文件类型",
                    width: "15%",
                    scopedSlots: { customRender: "fileExtensionName" }
                },
                {
                    dataIndex: "fileSize",
                    title: "文件大小",
                    with: "15%",
                    scopedSlots: { customRender: "fileSize" }
                },
                {
                    dataIndex: "source",
                    title: "文件来源",
                    width: "15%",
                    scopedSlots: { customRender: "source" }
                },
                {
                    dataIndex: "remark",
                    title: "备注",
                    width: "25%",
                    scopedSlots: { customRender: "remark" }
                }
            ],
            isEditting: false,
            rowIndex: null,
            colIndex: null,
            selectedRowKeys: [], // 选中行的key 列表
            importEquipmentModalVisible: false,  // 导入对话框
            isFileDownloading: false,
            filePreviewModalVisible: false, // 文件预览对话框
            filePreviewUrl: '', // 文件预览路径 或 excelfile
            filePreviewType: '', // 文件预览类型
            filePreviewName: '', // 文件预览名
            excelEditModalVisible: false,   // excel在线编辑对话框
            excelItem: null,    // 正在编辑excel的项目
            excelItemIndex: -1, // 正在编辑excel的项目索引
        };
    },
    computed: {
        allImportFileList() {
            const allImports = this.customData.filter(Element => {
                return Element.fileId;
            })
            return allImports.map(Element => Element.fileId);
        }
    },
    methods: {
        transferBitSize,
        // 其他隐藏组件点击事件
        handleTextClick(col, columIndex, index) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            //const that = this;
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 隐藏的输入框点击事件
        handleHiddenInputClick(col, columIndex, index, e) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            this.$store.dispatch("experiment/setText", e.target);
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 输入框隐藏事件
        isInputShow(index, columIndex) {
            return this.isEditting && this.rowIndex === index && this.colIndex === columIndex;
        },
        // 输入框失焦失焦， 恢复label形式
        handleBlur() {
            this.rowIndex = null;
            this.colIndex = null;
            this.isEditting = false;
            inputBlur();    // 时间戳不可选
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        // 导入点击事件
        handleImportClick() {
            this.importEquipmentModalVisible = true;
        },
        beforeShutDown (evt) {
            let event = evt || window.evt;
            if (event) {
                event.returnValue = "确定要关闭窗口吗？";
            }
            return '确定要关闭窗口吗?';
        },
        // 导入确认事件
        handleImportConfirm(rows) {
            const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for (let i = 0; i < rows.length; ++i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(rows[i])),
                    isCopy: false,  // 是否已经复制
                    FileStatu: 'ready'
                });
            }
        },
        // 文件上传
        handleFileUpload({ action, file, onSuccess, onError, onProgress }) {
            const lastDotIndex = file.name.lastIndexOf('.');
            const fileName = file.name.substring(0, lastDotIndex);
            const fileType = file.name.substring(lastDotIndex, file.name.length);
            const source = store.state.userInfo.name ? store.state.userInfo.name : '';
            const fileSizeSafe = file.size / 1024 / 1024 <= 256;
            if (!fileSizeSafe) {
                message.error('文件大小不能超过256MB')
                return
            }
            this.customData.push({
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                fileName: fileName,
                fileExtensionName: fileType,
                source: source ? source : '本地上传',
                remark: null,
                File: file,
                FileName: file.name,
                FileStatu: 'ready',
                progressPercent: 0,
                fileSize: file.size ? file.size : ''
            })
            return
        },
        // 下载点击事件
        async handleDownloadClick() {
            window.onbeforeunload = () => {}
            const allFiles = this.customData.filter(Element => {
                return this.selectedRowKeys.indexOf(Element.index) !== -1;
            });
            /*  const res =  store.commit('experiment/ADD_DOWNLOAD_COMPOENTS', [this.record.key, this.record.label, allFiles.map(file => {
                 return {
                     fileName: file.FileName ? file.FileName : file.fileName + file.fileExtensionName,
                     status: 'ready',
                     fileSize: file.fileSize,
                     downloaded: 0,
                     downloadRate: '',
                     secondsLeft: '',
                     abortController: null,
                 }
             })]) */
         /*    const res = await store.dispatch('experiment/addDownloadFileList', [this.record.key, this.record.label, allFiles.map(file => {
                return {
                    fileName: file.FileName ? file.FileName : file.fileName + file.fileExtensionName,
                    status: 'ready',
                    fileSize: file.fileSize,
                    downloaded: 0,
                    downloadRate: '',
                    secondsLeft: '',
                    //abortController: null,
                }
            })]) */
            //console.log(res);
          /*   if (!res) {
                return;
            } */
            
            if (allFiles.length === 1) {
                //console.log(allFiles[0])
                this.isFileDownloading = true;
                const res = await this.handleDownloadSingleFile(allFiles[0], 0);
                this.isFileDownloading = false;
                if (res) {
                    this.selectedRowKeys = [];
                }
            }
            else {
                /* const list = [];
                for (let i = 0; i < allFiles.length; ++i) {
                    const res = await getExperimentFilePath({
                        filePath: allFiles[i].FilePath
                    });
                    if (res.code === 200) {
                        const fileOriginName = allFiles[i].FileName ? allFiles[i].FileName : allFiles[i].name + allFiles[i].type;
                        list.push({
                            path: res.data,
                            fileName: fileOriginName
                        })
                    }
                    else {
                        Modal.error({
                            title: '错误',
                            content: `获取文件路径错误，错误信息${res.message}`
                        });
                        return ;
                    }
                }
                this.handleDownloadAsZip(list); */
                this.isFileDownloading = true;
                const promises = []
                allFiles.forEach((file, fileIndex) => {
                    promises.push(new Promise(async (resolve, reject) => {
                        const res = await this.handleDownloadSingleFile(file, fileIndex);
                        resolve(res);
                    }))
                })
                await Promise.all(promises).then((values) => {
                    if (values.indexOf(false) === -1) {
                        this.selectedRowKeys = [];
                    }
                }).catch(() => {

                }).finally(() => {
                    this.isFileDownloading = false;
                    
                })

            }
            window.onbeforeunload = this.beforeShutDown
            
        },
        async handleDownloadAsZip(list) {
            if (list.length === 0) {
                return;
            }
            const zip = new JSZip();
            const promises = [];
            list.forEach(file => {
                const promise = this.getFile(file.path).then(data => {
                    const fileName = file.fileName;
                    zip.file(fileName, file.path, { binary: true })
                })
                promises.push(promise);
            });
            Promise.all(promises).then(() => {
                zip.generateAsync({ type: 'blob' }).then(content => {
                    FileSaver.saveAs(content, `文件下载.zip`);
                })
            })
        },
        async getFile(url) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url,
                    responseType: 'blob',
                    timeout: 360000
                }).then(res => {
                    resolve(res.data);
                }).catch(error => {
                    reject(error.toString());
                })
            })
        },
        async blobToObj(data) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsText(data, 'utf-8');
                reader.onload = function () {
                    try {
                        resolve(JSON.parse(reader.result))
                    } catch (error) {
                        resolve({
                            code: 200,
                            message: '获取文件信息成功'
                        })
                    }
                }
            })
        },
        // 单个文件流下载
        async handleDownloadSingleFile(file, fileIndex) {
            const fileOriginName = file.FileName ? file.FileName : file.fileName + file.fileExtensionName;
            const that = this;
            const debounceConsole = debounce((key, fileIndex, loaded) => {
                store.commit('experiment/UPDATE_DOWNLOAD_FILE_DOWNLOADED', [key, fileIndex, loaded])
            }, 1000, {
                leading: true,
                trailing: true,
                maxWait: 1000
            })
            const updateProgress = (key, fileIndex, loaded) => {
                debounceConsole(key, fileIndex, loaded)
                // store.commit('experiment/UPDATE_DOWNLOAD_FILE_DOWNLOADED', [key, fileIndex, loaded] )
            }
            if (file.code) {
                const filePath = file.isCopy === false ? `${file.bucketName}/${file.code}` : file.code;
                return this.downLoadFileFromUrl(filePath, fileOriginName, file.isCopy === true)
                //const abortController = new AbortController();
               /*  const res = await downloadExperimentFile({ filePath: filePath, fileOriginName: fileOriginName, isFromCloudDisk: file.isCopy === false }, (progressEvent) => {
                    // const percent = (progressEvent.loaded / progressEvent.total * 100).toFixed(2);
                    // console.log([that.record.key, fileIndex, progressEvent.loaded])
                    //console.log('trick')
                    //store.commit('experiment/UPDATE_DOWNLOAD_FILE_DOWNLOADED', [that.record.key, fileIndex, progressEvent.loaded] )
                    updateProgress(that.record.key, fileIndex, progressEvent.loaded)
                });
                if (res.type === 'application/json') {
                    const error = await this.blobToObj(res);
                    message.error(error.message)
                    store.commit('experiment/UPDATE_DOWNLOAD_FILE_STATUS', [this.record.key, fileIndex, 'error'])
                    return Promise.resolve(false);
                }
                else {
                    const blob = new Blob([res.data], {
                        type: 'application/octet-stream'
                    }); //处理文档流
                    //const fileName = res.headers['content-disposition'].split();
                    //const fileName = res.headers['content-disposition'].match(/=(.*)$/)[1].split(';')[0];
                    const elink = document.createElement('a');
                    elink.download = fileOriginName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                    store.commit('experiment/UPDATE_DOWNLOAD_FILE_STATUS', [this.record.key, fileIndex, 'done'])
                    return Promise.resolve(true);
                } */
            }
            else if (file.File) {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file.File);
                fileReader.onload = (e) => {
                    const blob = new Blob([e.target.result], {
                        type: 'application/octet-stream'
                    }); //处理文档流
                    //const fileName = res.headers['content-disposition'].split();
                    //const fileName = res.headers['content-disposition'].match(/=(.*)$/)[1].split(';')[0];
                    const elink = document.createElement('a');
                    elink.download = fileOriginName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                    store.commit('experiment/UPDATE_DOWNLOAD_FILE_STATUS', [this.record.key, fileIndex, 'done'])
                    return Promise.resolve(true);
                }
            }
            else {
                store.commit('experiment/UPDATE_DOWNLOAD_FILE_STATUS', [this.record.key, fileIndex, 'error'])
                message.error(`无法下载文件‘${fileOriginName}’`)
                return Promise.reject(false);
            }
        },
        /******************                 预览 start              ********************/
        // 预览点击事件
        async handlePreviewClick(file, index) {
            this.filePreviewUrl = null;
            // 获取预览文件类型
            this.filePreviewType = getFileTypeBySuffix(file.fileExtensionName);
            // 获取文件名
            const fileOriginName = file.FileName ? file.FileName : file.fileName + file.fileExtensionName;
            this.filePreviewName = fileOriginName;
            if (this.filePreviewType === 'xlsx') {
               /*  this.excelItem = file;
                this.excelItemIndex = index;
                this.excelEditModalVisible = true; */
            }
            else if (this.filePreviewType === 'pdf') {
                // 不做处理
            }
            else if (this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {

            }
            else {
                this.filePreviewModalVisible = true;
            }
            // 如果是云盘导入的文件进行预览
            if (file.bucketName && file.code && !file.isCopy) {
                const res = await getExperimentFilePath({
                    filePath: `${file.bucketName}/${file.code}`,
                    isPreview: true,
                    isFromCloudDisk: true
                });
                if (res.code === 200) {
                    // excel文件转化问file文件
                    if (this.filePreviewType === 'xlsx') {
                       /*  const xlsxRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = new File([xlsxRes.data], this.filePreviewName); */
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if (this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if (this.filePreviewType === 'txt') {
                        const txtRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = window.URL.createObjectURL(new Blob([txtRes.data], { type: 'text/plain,charset=UTF-8' }));
                    }
                    else {
                        this.filePreviewUrl = res.data;
                    }

                }
            }
            // 如果是实验上传的文件则获取该类型
            else if (file.code) {
                const res = await getExperimentFilePath({
                    filePath: file.code,
                    isPreview: true
                });
                if (res.code === 200) {
                    if (this.filePreviewType === 'xlsx') {
                        /* const xlsxRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = new File([xlsxRes.data], this.filePreviewName); */
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if (this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {
                        console.log(process.env)
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if (this.filePreviewType === 'txt') {
                        const txtRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        this.filePreviewUrl = window.URL.createObjectURL(new Blob([txtRes.data], { type: 'text/plain,charset=UTF-8' }));
                    }
                    else {
                        this.filePreviewUrl = res.data;
                    }
                }
            }
            // 如果是本地上传的文件
            else if (file.File) {
                if (this.filePreviewType === 'xlsx') {
                    this.filePreviewUrl = file.File;
                    this.excelItem = file;
                    this.excelItemIndex = index;
                    this.excelEditModalVisible = true;
                }
                else if (this.filePreviewType === 'doc' || this.filePreviewType === 'pptx') {
                    message.info({
                        content: '该类型本地文件暂不支持预览'
                    })
                }
                /* else if(this.filePreviewType === 'txt') {
                    this.readTxtFile(file.File);
                } */
                else {
                    this.filePreviewUrl = window.URL.createObjectURL(file.File);
                }
            }
            // 不知道哪来的文件
            else {
                message.error(`文件‘${fileOriginName}’无法预览`);
            }
            if ((this.filePreviewType === 'pdf') && this.filePreviewUrl) {
                let win = window.open(this.filePreviewUrl, '_blank');
                win.onload = () => {
                    setTimeout(() => {
                        win.document.title = this.filePreviewName;
                    })
                }
            }
        },
        // 获取是否支持预览类型
        getFileTypeSupported(record) {
            return getFileTypeBySuffix(record.fileExtensionName) !== 'undefined';
        },
        // 读取txt文件
        readTxtFile(file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (result) => {
                this.filePreviewUrl = result.target.result;
            }
            reader.onerror = (error) => {
                message.error(`读取文件出错，错误信息：${error}`)
            }
        },
        test(item) {
            //console.log(item)
        },
        /******************                 预览 start              ********************/
        /******************                 excel 编辑 start        ********************/
        onExcelEdit(file) {
            const source = store.state.userInfo.name ? store.state.userInfo.name : '本地上传';
            let newItem = JSON.parse(JSON.stringify(this.excelItem));
            /* if(newItem.File) {
                delete newItem.File;
            } */
            newItem.File = file;
            newItem.bucketName = undefined;
            newItem.code = undefined;
            newItem.isCopy = undefined;
            newItem.FileStatu = 'ready';
            newItem.source = source;
            newItem.fileId = undefined;
            this.customData.splice(this.excelItemIndex, 1, newItem);
            message.success('成功修改');
            this.excelEditModalVisible = false;
            //this.excelItem.
        },
        /******************                 excel 编辑 end          ********************/
        // 通过Url下载文件
        async downLoadFileFromUrl(filePath, fileName, isFromCloudDisk) {
            const res = await getExperimentFilePath({
                filePath: filePath,
                saveAsName: fileName,
                isPublicRead: false,
                isPreview: false,
                isFromCloudDisk: isFromCloudDisk
            });
            if (res.success) {
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                //elink.target = "_blank"
                elink.href = res.data;
                document.body.appendChild(elink);
                elink.click();
                //URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
                return Promise.resolve(true);
                //store.commit('experiment/UPDATE_DOWNLOAD_FILE_STATUS', [this.record.key, fileIndex, 'done'])
            }
            return Promise.resolve(false);
        }
    },
    components: {
        importFromCloudModal,
        FilePreviewModal,
        ExcelEditModal,
        DownloadFileFloatDrawer
    }
};
</script>