/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audit-experiment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g fill-rule="evenodd" _fill="#4E5969"><path pid="0" d="M0 24h24V0H0v24z" fill-opacity=".01"/><path pid="1" d="M8.953 11.981l-.016-.023q-.084-.12-.401-.464-.89-.965-1.3-1.737-.727-1.367-.727-3.082 0-2.835 1.928-4.293 1.498-1.132 3.72-1.132 2.204 0 3.627 1.153 1.782 1.443 1.782 4.272 0 1.652-.68 2.933-.388.732-1.251 1.656-.514.55-.514.631 0 .215.002.38h4.367q.62 0 1.062.432.448.44.448 1.063v3.875q0 .456-.322.778t-.778.322H4.1q-.456 0-.778-.322T3 17.645V13.77q0-.624.448-1.063.44-.432 1.061-.432h4.443l.001-.29v-.004zm2 .002v-.088q0-.544-.377-1.083-.161-.23-.57-.674Q9.29 9.362 9 8.818q-.493-.927-.493-2.143 0-1.84 1.135-2.697.963-.728 2.514-.728 3.41 0 3.41 3.425 0 1.154-.447 1.996-.264.497-.946 1.228-.468.5-.654.778-.398.593-.398 1.218 0 1.094.08 1.406.25.974 1.31.974H19v2.47H5v-2.47h4.503q1.079 0 1.36-1.044.09-.337.09-1.248zM4 20.75h16a1 1 0 010 2H4a1 1 0 010-2z"/></g>'
  }
})
