<template>
	<div id="app">
		<a-config-provider :locale="locale">
			<router-view v-if="isRouterAlive" />
		</a-config-provider>
		<ForcedNotice  v-if="isHasForcedNotice"></ForcedNotice>
	</div>
</template>
<script>
import { useDocumentVisibility } from '@vueuse/core';
import { message, Modal } from 'ant-design-vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { nextTick, ref, provide, onMounted, computed, watch } from 'vue-demi';
import ForcedNotice from './components/common/forcedNotice.vue';
import router from './router';
const whiteList = ['login', 'register', 'updatePass', 'registerIndtroduction',
	'userAgreement',
	'privacy',
	'disclaimer',
	'companyIntroduction',
	'downLoadPage'];
import store from './store';
export default {
	props: {},
	setup(props, context) {
		const locale = zhCN;

		const isRouterAlive = ref(true);

		const reload = () => {
			isRouterAlive.value = false;
			nextTick(() => {
				isRouterAlive.value = true;
			})
		};

		provide('reload', reload);

		/* watch(() => context.root.$route, (newVal) => {

		}) */

		onMounted(() => {
			const token = localStorage.getItem('Authorization');
			if (token && (context.root.$route.name && whiteList.indexOf(context.root.$route.name) === -1)) {
				store.dispatch('sysNotice/getNotice');
			}
			const loadingEl =  document.querySelector('#first-loading-ctn')
			loadingEl && loadingEl.remove();
		})
		const isHasForcedNotice = computed(() => {
			return store.state.sysNotice.isHasForcedNotice && context.root.$route.name === 'workbench';
		})

		const visibility = useDocumentVisibility();
		watch(() => visibility.value, (val) => {
			if (val === 'visible') {
				const localChangeTimeStamp = localStorage.getItem('changePlatformTimeStamp') 
				const isChange = localChangeTimeStamp  ? store.state.changePlatformTimeStamp != localChangeTimeStamp : false;
				const loginTimeStamp = localStorage.getItem('loginTimeStamp');
				if (router.currentRoute.name && whiteList.indexOf(router.currentRoute.name) !== -1) {
					return;
				}
				else if (!loginTimeStamp) {
					Modal.destroyAll();
					Modal.info({
						content: '已在其他页面退出登录，请重新登录',
						okText: '重新登录',
						onOk: () => {
							window.onbeforeunload = () => { }
							window.onload = () => { }
							localStorage.removeItem('userInfo')
							location.reload();
							router.replace({
								name: 'login'
							})
						},
					})
				}
				else if (loginTimeStamp != store.state.loginTimeStamp) {
					Modal.destroyAll();
					Modal.info({
						content: '登录状态已发生更改，当前页面已失效',
						okText: '关闭',
						onOk: () => {
							window.onbeforeunload = () => { }
							window.onload = () => { }
							localStorage.removeItem('userInfo')
							window.close();
							location.reload();
							router.replace({
								name: 'workbench'
							})
						}
					})
				}
				else if (isChange) {
					Modal.destroyAll();
					Modal.info({
						content: '已在其他页面更换平台，页面失效，请重新打开',
						okText: '关闭',
						onOk: () => {
							window.onbeforeunload = () => { };
							window.onunload = () => { }
							//localStorage.clear('userInfo')
							window.close();
							if (context.root.$route.name && context.root.$route.name !== 'workbench') {
								router.replace({
									name: 'workbench'
								})
							}
							else {
								location.reload();
							}
						}
					})
				}
			}
		})

		return {
			locale,
			whiteList,
			isRouterAlive,
			isHasForcedNotice
		}
	},
	components: { ForcedNotice }
};
</script>
<style lang="less">
#app {
	height: auto;
	min-width: 1300px;
}

html,
body {
	height: 100%;
	width: 100%;
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
	/* width: 8px !important;
	height: 12px;
	background-color: transparent;
	border-radius: 10px; */
	width                : 8px !important;
    /*高宽分别对应横竖滚动条的尺寸*/
    height               : 8px;

}

/* 滚动槽 */
::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
	border-radius: 10px;

    border-radius: 0;
    background   : #fff;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	/* border-radius: 10px;
	width: 1px;
	border: 1px solid gray;
	background: #1890FF; */
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
	border-radius        : 5px;
    box-shadow           : inset 0 0 5px rgba(0, 0, 0, 0.3);
    background           : rgba(0, 0, 0, 0.3);
}

/* ::-webkit-scrollbar-thumb:window-inactive { */
	/* background: #1890FF; */
	/* background           : rgba(0, 0, 0, 0.4); */
/* } */
</style>
