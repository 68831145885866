<!--
 * @Description: 代码预览组件
 * @Author: Damon Liu
 * @Date: 2022-06-09
 -->

<template>
    <div class="draggable-list-ctn">
        <draggable v-if="mode === 'edit'" tag="div" class="draggable-box" v-bind="{
            ghostClass: 'moving',
            animation: 180,
            handle: '.drag-list-item-label'
        }" v-model="data.list" @start="dragStart($event, data.list)" @end="dragEnd($event, data.list)">
            <transition-group tag="div" name="list" class="list-main">
                <div v-for="(item, index) in data.list" :key="item.key" class="drag-list-item"
                    @click="handleItemClick(item)">
                    <!-- <div v-show="index !== editIndex" class="drag-list-item-label">
                        <a-icon type="menu" class="drag-list-item-label-icon" />
                        <div class="drag-list-item-label-text" :title="item.label" @click="handleEditClick(index)">
                            {{ item.label }}
                        </div>
                    </div>
                    <a-input v-model="item.label" :class="[`drag-item-label-input-${index}`]" v-show="index === editIndex"
                        @blur="editIndex = null"></a-input> -->
                        <div v-show="index !== editIndex" class="drag-list-item-label">
                        <a-icon type="menu" class="drag-list-item-label-icon" />
                        <div class="drag-list-item-label-text" :title="item.label">
                            {{ item.label }}
                        </div>
                    </div>
                    <div v-show="index !== editIndex" class="drag-list-item-operation" placement="bottom">
                        <a-tooltip title="删除">
                            <a-popconfirm placement="topRight" ok-text="确认" cancel-text="取消" @confirm="handleDelete">
                                <template slot="title">
                                    是否确认删除该项？
                                </template>
                                <a-icon type="delete" @click="handleDeleteIconClick(index)" />
                            </a-popconfirm>
                        </a-tooltip>
                    </div>
                </div>
            </transition-group>
        </draggable>
        <div v-else-if="mode === 'preview'">
            <div v-for="(item, index) in data.list" :key="item.key" class="drag-list-item" @click="handleItemClick(item)">
                <div v-show="index !== editIndex" class="drag-list-item-label">
                    <a-icon type="menu" class="drag-list-item-label-icon" />
                    <div class="drag-list-item-label-text preview-label-text" :title="item.label">
                        {{ item.label }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scope>
.drag-list-item {
    padding: 5px;
    padding-right: 15px;
    display: flex;
    border-bottom: 1px solid #e4e4e4;
    align-items: center;

    &:hover {
        opacity: 0.8;
    }

    .drag-list-item-label {
        display: flex;
        align-items: center;
        width: 0px;
        flex-grow: 1;
        cursor: move;

        .drag-list-item-label-icon {
            transform: translateY(2px);
            cursor: move;
        }

        .drag-list-item-label-text {
            padding: 5px 2px;
            margin-left: 2px;
            width: 0;
            flex-grow: 1;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 1px solid transparent;
            border-radius: 4px;
            transition: .2s all ease;

            /* &:hover {
                border-color: #40a9ff !important;
            } */
        }
        .preview-label-text {
            &:hover {
                border-color: transparent !important;
            }
        }
    }

    .drag-list-item-operation {
        margin-left: 5px;

        .anticon {
            cursor: pointer;
        }
    }

    &+& {
        //margin-top: 5px;
    }
}
</style>

<script>
import draggable from "vuedraggable";
export default {
    name: 'draggableList',
    props: {
        data: {
            type: Object,
            required: true
        },
        selectItem: {
            type: Object,
            default: () => { }
        },
        mode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            editIndex: null
        }
    },
    components: {
        draggable
    },
    methods: {
        dragStart(evt, list) {
            // 拖拽结束,自动选择拖拽的控件项
            this.$emit("handleSetSelectItem", list[evt.oldIndex]);
        },
        dragEnd(evt, list) {
            /* list
            console.log(evt) */
            this.handleItemClick(list[evt.newIndex])
        },
        handleSelectItem(record) {
            // 修改选择Item
            this.$emit("handleSetSelectItem", record);
        },
        // 删除单个项
        handleDelete() {
            // 删除已选择
            const traverse = array => {
                array = array.filter((element, index) => {
                    if (["grid", "tabs", "selectInputList"].includes(element.type)) {
                        // 栅格布局
                        element.columns.forEach(item => {
                            item.list = traverse(item.list);
                        });
                    }
                    if (element.type === "card" || element.type === "batch") {
                        // 卡片布局
                        element.list = traverse(element.list);
                    }
                    if (element.type === "table") {
                        // 表格布局
                        element.trs.forEach(item => {
                            item.tds.forEach(val => {
                                val.list = traverse(val.list);
                            });
                        });
                    }
                    if (element.key !== this.selectItem.key) {
                        return true;
                    } else {
                        if (array.length === 1) {
                            this.handleSelectItem({ key: "" });
                        } else if (array.length - 1 > index) {
                            this.handleSelectItem(array[index + 1]);
                        } else {
                            this.handleSelectItem(array[index - 1]);
                        }
                        return false;
                    }
                });
                return array;
            };

            this.data.list = traverse(this.data.list);
        },
        // 点击删除按钮
        handleDeleteIconClick(index) {
            // 点击项设为selectItem
            this.$emit("handleSetSelectItem", this.data.list[index])
        },
        handleEditClick(index) {
            this.editIndex = index;
            this.$nextTick(() => {
                const el = document.querySelector(`.drag-item-label-input-${index}`);
                //console.log(el)
                el && el.focus();
            })
        },

        handleItemClick(item) {
            this.$emit("handleListLocated", item)
        }
    }
}
</script>