<template>
<a-modal :width="600" :visible="visible" :title="referenceInfo ? '修改文献信息' : '新增文献'" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
    <a-form-model ref="formRef" class="form" :model="formData" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }"  >
        <a-form-model-item label="文献类型" :rules="[{ required: true, message: '请选择' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="category" >
            <a-select v-model="formData.category">
                <a-select-option value="期刊">
                    期刊
                </a-select-option>
                <a-select-option value="论文">
                    论文
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item label="作者" :rules="[{ required: true, whitespace: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="auther" >
            <a-input v-model="formData.auther" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item  v-if="formData.category === '论文'" label="论文名称" :rules="[{ required: true, whitespace: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="name" >
            <a-input v-model="formData.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item  v-if="formData.category === '论文'" label="学校" :rules="[{ required: true, whitespace: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="school">
            <a-input v-model="formData.school" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.category === '期刊'" label="期刊名称" :rules="[{ required: true, whitespace: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="periodical" >
            <a-input v-model="formData.periodical" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="年份" :rules="[{ required: true, whitespace: true,  message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="year" >
            <a-input v-model="formData.year" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.category === '期刊'" label="期数" :rules="[{ required: true, whitespace: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="issue" >
            <a-input v-model="formData.issue" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.category === '期刊'" label="起止页码" :rules="[{ required: true, whitespace: true, message: '请输入' }]" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }" prop="page" >
            <a-input v-model="formData.page" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.category" label="附件"  :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
            <div v-if="formData.fileName" class="fileName">
                <a-tooltip v-if="!getFileTypeSupported(formData)" title="暂不支持该类型文件预览">
                    <a style="cursor: not-allowed;"> {{ formData.fileName }}.{{ formData.fileExtensionName }} </a>
                </a-tooltip>
                <a-tooltip v-else @click.prevent="handlePreviewClick(formData)" title="点击预览">
                   <!--  <a v-if="mode === 'edit'"  target="_blank"> {{ formData.fileName }} </a> -->
                    <a :href="`/file${formData.code}`"> {{ formData.fileName }}{{ formData.fileExtensionName }}</a>
                </a-tooltip>
            </div>
            <div class="file-ctn">
                <a-upload  class="upload" :showUploadList="false"  :multiple="false"
                        :customRequest="handleFileUpload">
                        <a-button  size="small" class="operation-btn"> 本地导入 </a-button>
                </a-upload>
                <a-button size="small" class="operation-btn" style="margin-left: 16px;"
                    @click="handleImportClick"> 云盘导入
                </a-button>
            </div>
        </a-form-model-item>
    </a-form-model>
    <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
        <FilePreviewModal v-if="filePreviewModalVisible" :visible.sync="filePreviewModalVisible"
            :fileType="filePreviewType" :fileUrl="filePreviewUrl" :fileName="filePreviewName"></FilePreviewModal>
        <importFromCloudModal :visible.sync="importFromCloudModalVisible" @importConfirm="handleImportConfirm"
            :disabledList="allImportFileList" selectType="radio">
        </importFromCloudModal>
</a-modal>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { getFileTypeBySuffix } from '../../../../../utils/enums';
import { getExperimentFilePath } from '../../../../../api/experiment';
import store from '../../../../../store';
import FilePreviewModal from '../../experimentDocumentTable/components/filePreviewModal.vue';
import { message } from 'ant-design-vue';
import importFromCloudModal from '../../experimentDocumentTable/components/importFromCloudModal.vue';
import { Base64 } from 'js-base64';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        referenceInfo: {
            type: Object,
            default: null
        }
    },
    components: {
        FilePreviewModal,
        importFromCloudModal
    },
    setup(props, context) {
        
        // 表单实例
        const formRef = ref(null);
        // 表单数据
        const formData = ref({
            category: undefined,
            name: null,
            auther: null,
            periodical: null,
            year: null,
            issue: null,
            page: null,
            school: null
        });
        watch(() => props.visible, (newVal) => {
            // 对话框打开
            if(newVal) {
                formRef.value && formRef.value.resetFields();
                // 存在选择的对象，则是编辑模式
                if(props.referenceInfo) {
                    formData.value =cloneDeep(props.referenceInfo);
                }
                else {
                    formData.value = {
                        category: undefined,
                        name: null,
                        auther: null,
                        periodical: null,
                        year: null,
                        issue: null,
                        page: null,
                        school: null
                    }
                }
            }
        })
        // 处理取消关闭
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 处理确认
        const handleConfirm = () => {
            formRef.value && formRef.value.validate().then(() => {
                if(formData.value.category === '期刊') {
                    formData.value.school = null;
                    formData.value.name = null;
                }
                else {
                    formData.value.periodical = null;
                    formData.value.issue = null;
                    formData.value.page = null;
                }
                if(props.referenceInfo) {
                    context.emit('onModify', formData.value);
                }
                else {
                    context.emit('onAdd', formData.value);
                }
                
                context.emit('update:visible', false);
            }).catch(e => e);
            return 
        }
        /******************                 预览 start              ********************/
        const filePreviewUrl = ref(null);   // 文件预览路径
        const filePreviewType = ref(''); // 文件预览类型
        const filePreviewName =  ref(''); // 文件预览名
        const filePreviewModalVisible = ref(false); // 文件预览窗口


        // 预览点击事件
        const handlePreviewClick = async (file, index) => {
            filePreviewUrl.value = null;
            // 获取预览文件类型
            filePreviewType.value = getFileTypeBySuffix(file.fileExtensionName);
            // 获取文件名
            const fileOriginName = file.FileName ? file.FileName : file.fileName + file.fileExtensionName;
            filePreviewName.value = fileOriginName;
            /* if (filePreviewType.value === 'xlsx' && this.mode === 'edit') {
                this.excelItem = file;
                this.excelItemIndex = index;
                this.excelEditModalVisible = true;
            }
            else  */if(filePreviewType.value === 'pdf') {
                // 不做处理
            }
            else if(filePreviewType.value === 'doc' || filePreviewType.value === 'pptx') {

            }
            else {
                filePreviewModalVisible.value = true;
            }
            // 如果是云盘导入的文件进行预览
            if (file.bucketName && file.code && !file.isCopy) {
                const res = await getExperimentFilePath({
                    filePath: `${file.bucketName}/${file.code}`,
                    isPreview: true
                });
                if (res.code === 200) {
                    // excel文件转化问file文件
                    if (filePreviewType.value === 'xlsx') {
                        const xlsxRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        filePreviewUrl.value = new File([xlsxRes.data], filePreviewName.value);
                    }
                    else if(filePreviewType.value === 'doc' || filePreviewType.value === 'pptx') {
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if(filePreviewType.value === 'txt') {
                        const txtRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        filePreviewUrl.value = window.URL.createObjectURL(new Blob([txtRes.data], { type: 'text/plain,charset=UTF-8' }));
                    }
                    else {
                        filePreviewUrl.value = res.data;
                    }

                }
            }
            // 如果是实验上传的文件则获取该类型
            else if (file.code) {
                const res = await getExperimentFilePath({
                    filePath: file.code,
                    isPreview: true
                });
                if (res.code === 200) {
                    if (filePreviewType.value === 'xlsx') {
                        const xlsxRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        filePreviewUrl.value = new File([xlsxRes.data], filePreviewName.value);
                    }
                    else if(filePreviewType.value === 'doc' || filePreviewType.value === 'pptx') {
                        console.log(process.env)
                        window.open(process.env.VUE_APP_FILE_PREVIEW_SERVER_URL + encodeURIComponent(Base64.encode(res.data)) + '&officePreviewType=pdf')
                    }
                    else if(filePreviewType.value === 'txt') {
                        const txtRes = await axios({
                            method: "get",
                            url: res.data,
                            responseType: "blob",
                            timeout: 360000
                        }).catch(error => {
                            console.log(error)
                        });
                        filePreviewUrl.value = window.URL.createObjectURL(new Blob([txtRes.data], { type: 'text/plain,charset=UTF-8' }));
                    }
                    else {
                        filePreviewUrl.value = res.data;
                    }
                }
            }
            // 如果是本地上传的文件
            else if (file.File) {
                if (filePreviewType.value === 'xlsx') {
                    filePreviewUrl.value = file.File;
                }
                else if(filePreviewType.value === 'doc' || filePreviewType.value === 'pptx') {
                    message.info({
                        content: '该类型本地文件暂不支持预览'
                    })
                }
                /* else if(filePreviewType.value === 'txt') {
                    this.readTxtFile(file.File);
                } */
                else {
                    filePreviewUrl.value = window.URL.createObjectURL(file.File);
                }
            }
            // 不知道哪来的文件
            else {
                message.error(`文件‘${fileOriginName}’无法预览`);
            }
            if((filePreviewType.value === 'pdf') && filePreviewUrl.value) {
                let win =  window.open(filePreviewUrl.value, '_blank');
                win.onload = () => {
                    setTimeout(() => {
                        win.document.title = filePreviewName.value;
                    })
                }
            }
        }
        // 获取是否支持预览类型
        const getFileTypeSupported = (record) => {
            return getFileTypeBySuffix(record.fileExtensionName) !== 'undefined';
        }
        // 读取txt文件
        const readTxtFile = (file) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (result) => {
                filePreviewUrl.value = result.target.result;
            }
            reader.onerror = (error) => {
                message.error(`读取文件出错，错误信息：${error}`)
            }
        }
        /******************                 预览 start              ********************/

        /******************                 本地上传文件 start       *******************/
         // 文件上传
        const handleFileUpload = ({ action, file, onSuccess, onError, onProgress }) => {
            const lastDotIndex = file.name.lastIndexOf('.');
            const fileName = file.name.substring(0, lastDotIndex);
            const fileType = file.name.substring(lastDotIndex, file.name.length);
            const source = store.state.userInfo.name ? store.state.userInfo.name : '';
            const fileSizeSafe = file.size / 1024 / 1024 <= 256;
            if(!fileSizeSafe) {
                message.error('文件大小不能超过256MB')
                return 
            }
            formData.value = {
                category: formData.value.category,
                name: formData.value.name,
                auther: formData.value.auther,
                periodical: formData.value.periodical,
                year: formData.value.year,
                issue: formData.value.issue,
                page: formData.value.page,
                school: formData.value.school,
                index: formData.value.index,
                fileName: fileName,
                fileExtensionName: fileType,
                source: source ? source : '本地上传',
                FileName: file.name,
                FileStatu: 'ready',
                File: file,
                progressPercent: 0,
            }
           /*  this.customData.push({
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                fileName: fileName,
                fileExtensionName: fileType,
                source: source ? source : '本地上传',
                remark: null,
                File: file,
                FileName: file.name,
                FileStatu: 'ready'
            }) */
            return
        }
        /******************                 本地上传文件 end       *******************/
        /******************                 导入操作 start         ******************/
        const importFromCloudModalVisible = ref(false);  // 导入对话框

        // 已经导入
        const allImportFileList = computed(() =>  {
            return [formData.value.fileId]
        })

        // 云盘导入
        const handleImportClick = () => {
            importFromCloudModalVisible.value = true;
        }

        // 导入确认事件
        const handleImportConfirm = (rows) => {
           /*  const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for (let i = 0; i < rows.length; ++i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(rows[i])),
                    isCopy: false,  // 是否已经复制
                    FileStatu: 'ready'
                });
            } */
            console.log({ 
                old: formData.value
            })
            const row = rows[0];
            formData.value = {
                ...row,
                category: formData.value.category,
                name: formData.value.name,
                auther: formData.value.auther,
                periodical: formData.value.periodical,
                year: formData.value.year,
                issue: formData.value.issue,
                page: formData.value.page,
                school: formData.value.school,
                index: formData.value.index,
                isCopy: false,  // 是否已经复制
                FileStatu: 'ready'
            }
            console.log({ 
                new: formData.value
            })
        }

        return {
            formRef,
            formData,
            handleCancel,
            handleConfirm,
            getFileTypeSupported,
            handlePreviewClick,
            filePreviewUrl,
            filePreviewType,
            filePreviewName,
            handleFileUpload,
            filePreviewModalVisible,
            importFromCloudModalVisible,
            allImportFileList,
            handleImportClick,
            handleImportConfirm
        }
    }
}
</script>

<style lang="less" scoped>
.form {
    margin: 0px 74px;
    .amount-item {
        display: flex;
    }
    .file-ctn {
        .ant-btn + .ant-btn {
            margin-left: 15px;
        }
        .ant-btn {
            min-width: 58px;
        }

        .operation-btn {
            background: #eeeeee;
        }
    }
}
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>