/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'regular-comment': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<clipPath id="svgicon_regular-comment_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath><g clip-path="url(#svgicon_regular-comment_a)"><path pid="1" d="M14.24 11.395c0 .58-.473 1.052-1.055 1.052h-4.32c-.29 0-.737.113-.989.248l-3.27 1.752c-.515.276-.933.03-.933-.56v-.91a.529.529 0 00-.533-.53h-.523a1.057 1.057 0 01-1.057-1.052V2.932c0-.581.474-1.052 1.06-1.052h10.56c.586 0 1.06.475 1.06 1.052v8.463zM3.673 11.39c.584 0 1.057.476 1.057 1.054v.27c0 .29.205.404.455.256l2.222-1.311c.251-.149.69-.27.985-.27h4.262c.292 0 .53-.234.53-.528V3.465a.53.53 0 00-.525-.528H3.141a.525.525 0 00-.524.528v7.396c0 .292.234.529.533.529h.523zM4.73 8.748c0-.292.24-.528.53-.528h5.28a.528.528 0 110 1.057H5.26a.528.528 0 01-.53-.529zm0-3.17c0-.292.24-.528.53-.528h5.28a.528.528 0 110 1.057H5.26a.528.528 0 01-.53-.529z" _fill="#4E5969"/></g>'
  }
})
