<template>
    <div>
        <div class="download-file-float-drawer-ctn">
            <div v-if=" !drawerVisible && Object.keys(experimentDownloadFileList()).length > 0" class="animate__animated animate__bounceIn float-btn"
                @click="drawerVisible = true">
                <div>
                    
                    <div v-if="status.value === 'ready'">
                        <a-icon type="loading"  style="font-size: 12px;" />
                    </div>
                    <div v-else-if="status.value === 'downloading'">
                        <a-icon type="download" style="font-size: 12px;"/>
                    </div>
                    <div v-else-if="status.value == 'done'">
                        <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" style="font-size: 12px;"/>
                    </div>
                    <div v-if="status.value === 'ready' || status.value === 'downloading'" style="margin-top: 0px;">
                        {{ status.text }}
                    </div>
                    <div v-else-if="status.value == 'done'" style="margin-top: 0px;">
                        {{ status.text }}
                    </div>
                    <div v-if="status.value === 'no'">
                        {{  status.text  }}
                    </div>
                </div>

            </div>
        </div>
        <a-drawer :visible.sync="drawerVisible" title="下载任务" @close="onClose" :width="330" :bodyStyle="{ 
            padding: '0'
        }">
            <div v-for="(component,index) in experimentDownloadFileList()" :key="index">
                <div style="border-bottom: 1px solid #ebebeb; border-top: 1px solid #ebebeb; padding: 6px 12px; display: flex;">
                    <div class="gap" style="height: 16px; width: 4px; border-radius: 2px; margin-top: 3px;">

                    </div>

                    <div style="flex: 1; width: 0; margin-left: 6px;">
                        <div style="font-weight: bold; font-size: 14px; color: rgba(0, 0, 0, 0.85);">
                            {{ component.name }}
                        </div>
                        <div style="margin-top: 4px; color: rgba(0, 0, 0, 0.65); word-break: break-all; font-size: 12px;">
                            {{ index }}
                        </div>
                    </div>
                </div>
                <div style="padding: 16px 0px 16px 16px; background: #f7f7f7;">
                    <div v-for="(item, index) in component.list" :key="index" class="file-item">
                        <div class="file-index">
                            {{  index + 1  }}. 
                        </div>
                        <div class="file-content">
                            <div class="file-name">
                                {{ item.fileName }}
                            </div>
                            <div class="file-status" style="color: #999999;  margin-top: 4px;">
                                <div v-if="item.status == 'ready'" >
                                    准备下载
                                </div>
                                <div v-else-if="item.status === 'downloading' && item.downloaded && item.fileSize" style="padding-right: 12px;">
                                    <div>
                                         <a-progress :percent=" Number((item.downloaded / item.fileSize * 100).toFixed(2))" />
                                    </div>
                                   <div>
                                        <span>{{ transferBitSize(item.downloaded)  }}</span> / <span> {{ transferBitSize(item.fileSize)  }} </span>
                                        
                                   </div>
                                   <div style="margin-top: 4px;">
                                        <span > {{  transferBitSize(item.downloadRate)  }}/s </span>
                                        <span v-if="item.secondsLeft" style="margin-left: 8px;"> 预计剩余时间 {{  transferSecondToOther(item.secondsLeft)  }}  </span>
                                   </div>
                                </div>
                                <div v-else-if="item.status === 'done'">
                                    <div>
                                        <span> {{ transferBitSize(item.fileSize)  }} </span>
                                    </div>
                                    <div style="margin-top: 5px;">
                                        <a-icon type="check-circle" theme="filled" style="color: #52c41a; "/> 已完成
                                    </div>
                                    
                                </div>
                                <div v-else-if="item.status === 'error'">
                                    <a-icon type="close-circle" theme="filled" style="color: #ff4d4f; "/> 下载出错
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <!--  <div>
                {{experimentDownloadFileList() }}
            </div> -->
            <!-- <div v-for="(item, index) in downLoadList" :key="index" class="file-item">
                <div class="file-index">
                    {{  index + 1  }}. 
                </div>
                <div class="file-content">
                    <div class="file-name">
                        {{ item.fileName }}
                    </div>
                    <div class="file-status" style="color: #999999; font-size: 12px; margin-top: 4px;">
                        <div v-if="item.status == 'ready'" >
                            准备下载
                        </div>
                        <div v-else-if="item.status === 'downloading' && item.progressPercent">
                            <a-progress :percent="item.progressPercent" />
                        </div>
                        <div v-else-if="item.status === 'done'">
                            <a-icon type="check-circle" theme="filled" style="color: #52c41a;"/> 已完成
                        </div>
                        
                    </div>
                </div>
            </div>
            <div v-if="downLoadList.length === 0">
                <a-empty></a-empty>
            </div> -->
        </a-drawer>
    </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api';
import { mapState } from 'vuex';
import store from '../../store';
import { transferBitSize, transferSecondToOther } from '../../utils/utils';

export default {
    props: {
      /*   downLoadList: {
            default: () => ([])
        }, */
        mode: {
            default: 'percent',
        }
    },
    setup(props, context) {
        const downloadList = computed(() => {
            return store.state.experiment.downloadFileList;
        })
        onMounted(() => {
           /*  setInterval(() => {
                store.commit('experiment/UPDATE_DOWNLOAD_FILE_DOWNLOADED', ['experiment-document-table_1684822352160', 0, 1000000] )
            }, 500) */
        })

        const drawerVisible = ref(false);

        const status = computed(() => {
            const allStatus = [];
            Object.values(store.state.experiment.downloadFileList).forEach(component => {
                component.list && allStatus.splice(allStatus.length, 0, ...component.list.map(item => item.status))
            });
            if (allStatus.length === 0) {
                return {
                    text: '无下载项',
                    value: 'no'
                }
            }
           
            if (allStatus.indexOf('downloading') != -1) {
                return {
                    text: '下载中',
                    value: 'downloading'
                }
            }
            if (allStatus.indexOf('ready') == -1) {
                return {
                    text: '已完成',
                    value: 'done'
                }
            }
            else {
                return {
                    text: '准备下载',
                    value: 'ready'
                }
            }
            return{
                    text: '准备下载',
                    value: 'ready'
                }
        })

        const onClose = () => {
            drawerVisible.value = false;
        }
        return {
            ...mapState({
                experimentDownloadFileList: (state) => state.experiment.downloadFileList
            }),
            drawerVisible,
            status,
            onClose,
            downloadList,
            transferBitSize,
            transferSecondToOther
        
        }
    }
}
</script>

<style lang="less" scoped>
.float-btn {
    position: fixed;
    top: 3px;
    left: 100px;
    width: 55px;
    height: 55px;
    background: #fff;
    z-index: 100;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: @srims-primary-box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
    color: #666666;
    text-align: center;
}
.file-item {
    display: flex;
    padding: 12px;
    border: 1px solid #ebebeb;
    background: #fff;
    &:hover {
        background: #fcfcfc;
    }
    .file-index {
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
        margin-right: 8px;
        //min-width: 22px;
    }
    .file-content {
        width: 0px;
        flex-grow: 1;
        .file-name {
            word-break: break-all;
        }
    }
}
.gap {
    background: @srims-primary-color;
}
</style>