/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumbs-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><clipPath id="svgicon_thumbs-up_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath></defs><g clip-path="url(#svgicon_thumbs-up_a)"><path pid="1" d="M15.182 5.147A3.33 3.33 0 0012.667 4h-2.66l.224-1.36a2.025 2.025 0 00-3.814-1.225L5.333 3.61V14H12.2a3.35 3.35 0 003.3-2.867l.47-3.333a3.33 3.33 0 00-.788-2.653z" _fill="#8590A6"/><path pid="2" d="M0 7.333v3.334A3.337 3.337 0 003.333 14H4V4h-.667A3.337 3.337 0 000 7.333z"/></g>'
  }
})
