<template>
    <a-modal :width="800" :visible="visible" title="实验材料" :bodyStyle="{ padding: '16px' }" @cancel="handleCancel">
        <div class="search-ctn">
            <a-input style="width: 220px;" v-model="searchValue" placeholder="请输入关键字" ></a-input>
            <a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
            <a-button class="reset-btn" @click="searchValue = ''"> 重置 </a-button>
        </div>
        <a-table size="small" :columns="columns" :data-source="tableData" :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" rowKey="id">
            <template slot="structuralFormula" slot-scope="text, record, index">
                <ImageUploader v-if="record.structuralFormula" :imgUrl="`data:image/jpeg;base64,${record.structuralFormula}`" mode="preview">
                </ImageUploader>
            </template>
        </a-table>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="total"
                show-less-items />
        </div>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.search-ctn {
    margin-bottom: 24px;

    .search-btn {
        margin-left: 24px;
    }

    .reset-btn {
        background: #eeeeee;
        margin-left: 16px;
    }
}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

/deep/ .ant-table-thead {
    background: #F0F5FF;
}

/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px;
}

.default-pagination {
    text-align: center;
    margin-top: 24px;
}
</style>

<script>
const columns = [
    {
        dataIndex: "name",
        title: "名称",
        width: "17%",
        scopedSlots: { customRender: "name" }
    },
    {
        dataIndex: "density",
        title: "密度",
        width: "13%",
        scopedSlots: { customRender: "density" }
    },
    {
        dataIndex: "casNumber",
        title: "CAS号",
        width: "17%",
        scopedSlots: { customRender: "cas" }
    },
    {
        dataIndex: "specification",
        title: "规格",
        width: "12%",
        scopedSlots: { customRender: "spec" }
    },
    {
        dataIndex: "structuralFormula",
        title: "结构式",
        width: "18%",
        scopedSlots: { customRender: "structuralFormula" }
    },
    {
        dataIndex: "storageLocation",
        title: "存放位置",
        width: "20%",
        scopedSlots: { customRender: "location" }
    }
]
import { ref, watch } from '@vue/composition-api';
import ImageUploader from '../../../../../components/imageUploader.vue';
import { getExperimentMaterials } from '../../../../../api/experiment';
export default {
    props: ["visible"],
    setup(props, context) {
        const formData = ref({});
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const formRef = ref(null);
        // 已选择的key值
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.id);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if (allKeys.indexOf(element.id) === -1) {
                    selectedRows.value.push(element);
                }
            });
            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        };
        const tableData = ref([
          /*   {
                key: 100,
                name: '乙酸乙酯',
                density: `3g/ml`,
                cas: `KT23423`,
                spec: '一盒',
                structuralFormula: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                location: '资环院二楼'
            },
            {
                key: 101,
                name: '乙酸乙酯',
                density: `3g/ml`,
                cas: `KT23423`,
                spec: '一盒',
                structuralFormula: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                location: '资环院二楼'
            },
            {
                key: 102,
                name: '乙酸乙酯',
                density: `3g/ml`,
                cas: `KT23423`,
                spec: '一盒',
                structuralFormula: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                location: '资环院二楼'
            } */

        ]);
        // 当前页
        const current = ref(1);
        // 总页数
        const total = ref(1);
        // 搜索值
        const searchValue = ref('');
        // 获取列表
        const getList = async() => {
            const res = await getExperimentMaterials({
                searchValue: searchValue.value,
                pageNo: current.value,
                pageSize: 10
            });
            if(res.code === 200) {
                tableData.value = res.data.rows;
                total.value = res.data.totalRows;
                //console.log(`data:image/jpeg;base64,${res.data.rows[0].structuralFormula}`)
            }
        }
        const handlePageChange = (page, pageSize) => {
            // 将已选行中取消选择的脏数据去除
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
            //tableData.value = JSON.parse(JSON.stringify(eqiupmentList[page - 1]));
            getList();
        };
        const handleConfirm = () => {
            selectedRows.value = selectedRows.value.filter((element) => {
                return selectedRowKeys.value.indexOf(element.id) !== -1;
            });
            if (selectedRows.value.length !== 0) {
                context.emit("importConfirm", selectedRows.value);
            }
            context.emit("update:visible", false);
        };
        const handleSearch = () => {
            current.value = 1;
            getList();
        }
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                current.value = 1;
                //name.value = '';
                getList();
                selectedRowKeys.value = [];
                selectedRows.value = [];
            }
        })
        return {
            formData,
            formRef,
            handleCancel,
            columns,
            selectedRowKeys,
            onSelectChange,
            tableData,
            current,
            handlePageChange,
            handleConfirm,
            total,
            searchValue,
            handleSearch
        };
    },
    components: { ImageUploader }
}
</script>