import KFormDesign from "../../components/k-form-design/packages/index";
import customComponentsConfig from './customComponents';
import textComponentsConfig from './textComponents';
import '../../components/k-form-design/styles/form-design.less';

KFormDesign.setFormDesignConfig(customComponentsConfig);    // 设置系统模块组件

KFormDesign.setTextComponents(textComponentsConfig);    // 设置字段控件组件


export default KFormDesign;