/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'test-tube': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<clipPath id="svgicon_test-tube_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath><g clip-path="url(#svgicon_test-tube_a)"><path pid="1" d="M11.556.889H4.444a.593.593 0 000 1.185h.593V12.15a2.963 2.963 0 105.926 0V2.074h.593a.593.593 0 000-1.185zM8 13.928a1.778 1.778 0 01-1.778-1.778V6.223h3.556v5.927c0 .982-.796 1.778-1.778 1.778zm1.778-8.89H6.222V2.074h3.556v2.964z" _fill="#4E5969"/></g>'
  }
})
