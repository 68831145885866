/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fullscreen-exit': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M144 612h140c69.986 0 126.853 56.167 127.983 125.883L412 740v140c0 17.496-14.042 31.713-31.47 31.996L380 912c-17.496 0-31.713-14.042-31.996-31.47L348 880V740l-.009-1.058c-.56-34.51-28.432-62.38-62.943-62.934L284 676H144c-17.496 0-31.713-14.042-31.996-31.47L112 644c0-17.496 14.042-31.713 31.47-31.996L144 612zm736.5 0c17.397 0 31.5 14.103 31.5 31.5S897.897 675 880.5 675l-40.5-.001V676H740c-34.993 0-63.426 28.084-63.991 62.942L676 740v140c0 17.496-14.042 31.713-31.47 31.996L644 912c-17.673 0-32-14.327-32-32V740c0-70.692 57.308-128 128-128h140.5zM380 112c17.673 0 32 14.327 32 32v140c0 70.692-57.308 128-128 128H144c-17.673 0-32-14.327-32-32 0-17.673 14.327-32 32-32h140c34.993 0 63.426-28.084 63.991-62.942L348 284V144c0-17.496 14.042-31.713 31.47-31.996L380 112zm264 0c17.496 0 31.713 14.042 31.996 31.47l.004.53v140l.009 1.058c.56 34.51 28.432 62.38 62.943 62.934L740 348h140c17.673 0 32 14.327 32 32 0 17.496-14.042 31.713-31.47 31.996L880 412H740c-69.986 0-126.853-56.167-127.983-125.883L612 284V144c0-17.496 14.042-31.713 31.47-31.996L644 112z" _fill="#300"/>'
  }
})
