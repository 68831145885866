/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-4-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><clipPath id="svgicon_settings-4-fill_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath></defs><g clip-path="url(#svgicon_settings-4-fill_a)"><path pid="1" d="M2.89 2.285A7.659 7.659 0 015.604.715 3.061 3.061 0 008 1.866c.932.001 1.814-.423 2.395-1.151a7.658 7.658 0 012.716 1.57 3.061 3.061 0 00.2 2.648 3.061 3.061 0 002.196 1.499 7.703 7.703 0 010 3.136c-.89.136-1.71.659-2.195 1.498a3.062 3.062 0 00-.201 2.65 7.66 7.66 0 01-2.716 1.569A3.061 3.061 0 008 14.134c-.932-.001-1.814.423-2.395 1.151a7.658 7.658 0 01-2.716-1.57 3.061 3.061 0 00-.2-2.648A3.061 3.061 0 00.494 9.57a7.7 7.7 0 010-3.136 3.061 3.061 0 002.195-1.5c.466-.805.54-1.78.2-2.647v-.001zm6.26 7.707a2.3 2.3 0 10-2.274-4 2.3 2.3 0 002.274 4z"/></g>'
  }
})
