<template>
    <div class="table-container">
        <div class="btns">
            <div class="left-btns">
                <!-- <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增 </a-button> -->
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleAddSampleButtonClick"> 添加样品 </a-button>
            </div>
            <a-button size="small" v-show="mode === 'edit'" :disabled="!(selectedRowKeys.length == 1)" @click="handleModifySampleButtonClick"> 编辑 </a-button>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick" :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="columns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode === 'edit' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null">
        </a-table>
        <addOrModifySampleModal :visible.sync="addOrModifySampleModalVisible" :sampleInfo="edittingSample" @onAdd="handleAddSample" @onModify="handleModifySample"></addOrModifySampleModal>
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;
        .ant-btn {
            min-width: 58px;
        }
        .operation-btn {
            background: #eeeeee;
        }
        .left-btns {
            width: 0px;
            flex-grow: 1;
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
import addOrModifySampleModal from './components/addOrModifySampleModal.vue';

export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    // props: {
    //   record: {
    //     type: Object,
    //     require: true
    //   },
    //   value: {
    //     type: String,
    //     default: ''
    //   }
    // },
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            columns: [
                {
                    dataIndex: "name",
                    title: "样品名称",
                    width: "15%",
                    scopedSlots: { customRender: "name" }
                },
                {
                    dataIndex: "project",
                    title: "所属项目",
                    width: "15%",
                    scopedSlots: { customRender: "project" }
                },
                {
                    dataIndex: "category",
                    title: "分类",
                    width: "13%",
                    scopedSlots: { customRender: "category" }
                },
                {
                    dataIndex: "amount",
                    title: "数量",
                    width: "9%",
                    scopedSlots: { customRender: "amount" }
                },
                {
                    dataIndex: "unit",
                    title: "单位",
                    width: "7%",
                    scopedSlots: { customRender: "unit" }
                },
                {
                    dataIndex: "code",
                    title: "批号",
                    width: "15%",
                    scopedSlots: { customRender: "code" }
                },
                {
                    dataIndex: "remainingUsage",
                    title: "剩余可用量",
                    width: "12%",
                    scopedSlots: { customRender: "remainingUsage" }
                },
                {
                    dataIndex: "remainingUsageUnit",
                    title: "剩余可用量单位",
                    width: "12%",
                    scopedSlots: { customRender: "remainingUsageUnit" }
                }
            ],
            selectedRowKeys: [], // 选中行的key 列表
            addOrModifySampleModalVisible: false,    // 新增或修改样品对话框可见变量
            edittingSample: null    // 正在编辑的样品 
        };
    },
    methods: {
        handleChange() {
            this.customData.push({
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                key: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].key + 1,
                name: "",
                code: "",
                model: "",
                date: "",
                company: "",
                location: "",
                precision: ""
            });
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            /* this.$confirm({
                title: '是否确认删除？',
                onOk() {
                    return new Promise((resolve, reject) => {
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    }).catch(() => console.log('Oops errors!'));
                },
                onCancel() { },
            }); */
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        // 导入点击事件
        handleImportClick() {
            this.importExperimentMaterialModalVisible = true;
        },
        // 导入确认事件
        handleImportConfirm(rows) {
            const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for(let i = 0; i < rows.length; ++ i) {
                this.customData.push({
                    index: index + i + 1,
                    ...JSON.parse(JSON.stringify(rows[i]))
                });
            }
        },
        // 添加样品点击
        handleAddSampleButtonClick() {
            this.edittingSample = null;
            this.addOrModifySampleModalVisible = true;
        },
        // 添加样品回调
        handleAddSample(sample) {
            this.customData.push({
                ...JSON.parse(JSON.stringify(sample)),
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1
            })
        },
        // 修改样品按钮点击
        handleModifySampleButtonClick() {
             const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) !== -1;
            });
            this.edittingSample = temp[0]
            this.addOrModifySampleModalVisible = true;
        },
        // 修改样品回调
        handleModifySample(sample) {
            this.customData[this.customData.indexOf(this.edittingSample)] = JSON.parse(JSON.stringify(sample));
        }
    },
    components: { addOrModifySampleModal }
};
</script>