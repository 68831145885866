/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g style="mix-blend-mode:passthrough"><path pid="0" d="M6.134 1.96q-.917.917-.917 2.214v1.043h2.087V4.174q0-.432.306-.738t.738-.306h11.478q.432 0 .738.306t.306.738v11.478q0 .432-.306.738t-.738.306h-1.043v2.087h1.043q1.297 0 2.214-.917.916-.917.916-2.214V4.174q0-1.297-.916-2.214-.917-.917-2.214-.917H8.348q-1.297 0-2.214.917z" fill-rule="evenodd" _fill="#4E5969"/><path pid="1" d="M1.96 6.134q-.917.917-.917 2.214v11.478q0 1.297.917 2.214.917.916 2.214.916h11.478q1.297 0 2.214-.916.917-.917.917-2.214V8.348q0-1.297-.917-2.214t-2.214-.917H4.174q-1.297 0-2.214.917z" fill-rule="evenodd" _fill="#4E5969"/></g>'
  }
})
