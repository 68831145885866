/*
 * author kcz
 * date 2019-11-20
 */
import KFormPreview from "./index.vue";

KFormPreview.install = function(Vue) {
  Vue.component(KFormPreview.name, KFormPreview);
};

export default KFormPreview;
