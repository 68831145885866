import { getForceNoticeList } from "../../api/others";

const state = {
    // 是否已经加载过接口
    isLoad: false,
    // 是否存在强制阅读的通知
    isHasForcedNotice: localStorage.getItem('isHasForcedNotice') || false,
    // 强制通知列表
    forceNoticeList: []
}

const mutations = {
    setIsLoad: (state, val) => {
        state.isLoad = val;
    },
    setIsHasForcedNotice: (state, val) => {
        state.isHasForcedNotice = val;
    },
    setForceNoticeList: (state, val) => {
        state.forceNoticeList.splice(0, state.forceNoticeList.length, ...val);
        if(state.forceNoticeList.length === 0) {
            state.isHasForcedNotice = false;
        }
    },
    removeForceNoticeByIndex: (state, index) => {
        state.forceNoticeList.splice(index, 1);
        if(state.forceNoticeList.length === 0) {
            state.isHasForcedNotice = false;
        }
    }
}

const actions = {
    getNotice: async ({ commit }) => {
        if(!state.isLoad) {
            const forceRes = await getForceNoticeList({});
            if(forceRes.code === 200) {
                commit('setIsLoad', true);
                if(forceRes.data && forceRes.data.length > 0) {
                    commit('setIsHasForcedNotice', true);
                    commit('setForceNoticeList', forceRes.data);
                }
            }
		}
    } 
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}