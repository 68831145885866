/**
 * axios 封装，用于统一拦截请求，拦截错误并且处理，可用于Token插入
 * 
 */
import { Modal, message } from 'ant-design-vue';
import axios from 'axios';
import store from '../store';
import globalVueThis from '../main';
import { useThrottleFn } from '@vueuse/core';

// 401 错误重定向，使用节流避免重复提示和执行
export const handle401 = useThrottleFn((msg) => {
    /* message.error(msg); */
    localStorage.clear();
    Modal.destroyAll();
    if(globalVueThis.$route.name && (['login'].indexOf(globalVueThis.$route.name) ==  -1)) {
        Modal.info({
            title: msg,
            okText: '重新登录',
            onOk: () => {
                globalVueThis.$router.replace({
                    name: 'login'
                })
            }
        })
    }
}, 3000)

// 创建一个axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 配置基本路径
    timeout: 10000, // 请求超时阈值
});

// 请求拦截，用于发出请求时对请求设置进行添加修改，如用于上Token，加密等
service.interceptors.request.use(
    config => {
        // 可在这判断是否需要携带Token
        /**
         * if(tokne) {
         *  config.header['X-token'] = ...
         * }
         */
        //store.commit('updateSpin',true)
        const token = localStorage.getItem('Authorization');
        const xToken = localStorage.getItem('X-Authorization');
        if (token) {
            // @ts-ignore
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        if (xToken) {
            config.headers['X-Authorization'] = 'Bearer ' + xToken;
        }
        return config
    },
    // 请求错误
    error => {
        store.commit('updateSpin', false)
        // do something with request error
        //console.log(error) // for debug
        message.error(error)
        return Promise.reject(error)
    }
)

// 响应拦截，用于获取响应后对响应体统一处理，如用于解密，对于响应码处理，错误处理。
service.interceptors.response.use(
    // 获取响应
    response => {
        if (response.headers['access-token'] && response.headers['access-token'] != `invalid_token`) {
            localStorage.setItem('Authorization', response.headers['access-token']);
            localStorage.setItem('pro__Access-Token', JSON.stringify({ value: localStorage.getItem('Authorization'), expire: null }));
        }
        if (response.headers['x-access-token']) {
            localStorage.setItem('X-Authorization', response.headers['x-access-token']);
            localStorage.setItem('pro__X-Access-Token', JSON.stringify({ value: localStorage.getItem('X-Authorization'), expire: null }))
        }
        //流文件
        if (response.headers['content-type'] === 'application/octet-stream') {
            return response
        }
        //store.commit('updateSpin',false)
        const res = response.data;
        /**
         * // 错误码响应拦截
         * if(res.code === 5000) {
         *  // 处理
         * }
         */
        if (res.code === undefined) {
            return response.data
        }
        if (res.code === 401) {
            handle401(res.message || '登录失效，请重新登录');
            return response.data;
        }
        if (res.code !== 200 && res.code !== 204) {
            message.error((typeof res.message === 'object' ? JSON.stringify(res.message) : res.message) || '请求失败')
            //localStorage.setItem('isAutoLogin','false')
        }
        if (res.code === 401) {

        }
        return response.data
    },
    // 错误响应
    error => {
        store.commit('updateSpin', false)
        message.error(`服务器链接超时！错误：${error}`)
        return Promise.reject(error)
    }
)

export default service;