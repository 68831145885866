<template>
<div v-if="mode === 'edit'" class="datepicker-ctn">
     <a-date-picker valueFormat="YYYY-MM-DD" autoFocus :value="customData" @change="handleDateChange"/>
</div>
<div v-else  class="datepicker-ctn">
     <a-date-picker valueFormat="YYYY-MM-DD"  :value="customData" :open="false" />
</div>
</template>

<style lang="less" scoped>
.datepicker-ctn {
    padding: 10px;
    padding-bottom: 5px;
    min-height: 50px;
}
</style>

<script>
export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode"],
    setup(props,context) {
        const handleDateChange = (val) => {
            context.emit('customDataChange',val);     
        }
        return {
            handleDateChange
        }
    }
}
</script>