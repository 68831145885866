/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_time_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_time_a)"><path pid="1" d="M13 11V6h-2v7h6v-2h-4zm5.364 6.364L12 23.728l-6.364-6.364a9 9 0 1112.728 0z" _fill="#4E5969"/></g>'
  }
})
