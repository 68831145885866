/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bbs-sort-arrow-up': {
    width: 6.651,
    height: 4.27,
    viewBox: '0 0 6.651 4.27',
    data: '<path pid="0" d="M.081 3.744L3.097.105a.317.317 0 01.472 0l3.002 3.639a.316.316 0 01-.236.526H.316a.316.316 0 01-.235-.526z" _fill="#BFBFBF"/>'
  }
})
