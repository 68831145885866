/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open-record': {
    width: 14.667,
    height: 14.667,
    viewBox: '0 0 14.667 14.667',
    data: '<path pid="0" d="M7.333 1.333a6 6 0 100 12 6 6 0 000-12zM0 7.333a7.333 7.333 0 1114.667 0A7.333 7.333 0 010 7.333zm7.333-4.666c.369 0 .667.298.667.666v3.588l2.298 1.15a.667.667 0 11-.596 1.192L7.035 7.93a.667.667 0 01-.368-.597v-4c0-.368.298-.666.666-.666z" fill-rule="evenodd" _fill="#2F54EB"/>'
  }
})
