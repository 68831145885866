<template>
    <div v-if="mode === 'edit'">
        <a-spin :spinning="loading" tip="正在加载中...">
            <ckeditor :value="customData" editor-url="/ckeditor/ckeditor.js" @namespaceloaded="onNamespaceLoaded"
                @ready="onEditorReady" :read-only="mode !== 'edit'" @input="onEditorInput" :throttle="500"
                @focus="onEditorFocus" @blur="onEditorBlur"></ckeditor>
            <div style="width: 100%; height: 300px" v-if="loading"></div>
        </a-spin>
        <SelectTemplateModal :visible.sync="selectTemplateModalVisible" @importConfirm="importConfirm"></SelectTemplateModal>
    </div>
    <div v-else style="overflow: auto; " class="preview-content">
        <div v-html="customData">

        </div>
    </div>
</template>

<script>
import { message } from 'ant-design-vue';
import CKEditor from 'ckeditor4-vue';
import { computed, ref, onMounted, onBeforeUpdate, onUpdated } from 'vue-demi';
import router from '../../../../router';
import { getFileUrl, uploadFileInExperiment } from "../../../../api/experiment";
import store from '../../../../store';
import { format } from 'path';
import dayjs from 'dayjs';
import SelectTemplateModal from './components/selectTemplateModal.vue';
export default {
    props: {
        mode: {
            required: true,
            default: "preview"
        },
        record: {
            required: true,
            default: () => {
                return {

                }
            }
        },
        customData: {
            required: true,
            default: ""
        },

    },
    components: {
    ckeditor: CKEditor.component,
    SelectTemplateModal
},
    setup(props, context) {
        const editor = ref(null);
        const loading = ref(true);
        const fileUploadPath = ref('');
        const onNamespaceLoaded = (CKEDITOR) => {
            const projectId = router.currentRoute.query.projectId;
            const id = router.currentRoute.query.id;
            //fileUploadPath.value = `/api/experiment/uploadFile?projectId=${projectId}&experimentId=${id}`;
          
            fileUploadPath.value = `/api/experiment/getFileUrl`
            CKEDITOR.config.height = 400;
            CKEDITOR.config.filebrowserImageUploadUrl = fileUploadPath.value
            CKEDITOR.dom.documentFragment.referrerPolicy = 'no-referrer';
            CKEDITOR.config.extraPlugins = ['uploadimage', 'itemplate'];
           // CKEDITOR.config.removePlugins = 'image2'
            CKEDITOR.config.allowedContent = true;
           
            /*  CKEDITOR.config.bodyClass = 'document-editor';
             CKEDITOR.config.contentsCss= [
             '/ckeditor/pastefromword.css'
             ] */
        }
        const onEditorReady = (editorInsatnce) => {
            editor.value = editorInsatnce;
           // editorInsatnce.plugins.clipboard.preventDefaultDropOnElement(editorInsatnce.document);
            // console.log(editorInsatnce.getMenuItem('tablerow').getItems())
            setContextMenu(editorInsatnce)
            setInsertTemplateCommand(editorInsatnce)
            /*  editorInsatnce.addMenuGroup('timeStamp')
             editorInsatnce.addCommand('add YYYY-MM-DD  HH:mm:ss', {
                 exec: function (editor) {
                     // 业务代码
                     editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD  HH:mm:ss')}</p>`)
                 }
             })
             editorInsatnce.addMenuItem('timeStamp', {
                 label: '年月日 时分秒',
                 icon: null,
                 command: 'add YYYY-MM-DD  HH:mm:ss',
                 group: 'timeStamp'
             }) */

            // setContextMenu(editorInsatnce);
            /* editorInsatnce.config.image2_captionedClass = 'image-captioned';
            editor.value = editorInsatnce;
            loading.value = false;
            const projectId = router.currentRoute.query.projectId;
            const id = router.currentRoute.query.id;
            // 如果路由存在项目和实验id，则可以正常上传
            if (projectId && id) {
                fileUploadPath.value = `/api/experiment/uploadFile?projectId=${projectId}&experimentId=${id}`;
                editorInsatnce.config.filebrowserImageUploadUrl = fileUploadPath.value
                editorInsatnce.on('fileUploadRequest', function (evt) {
                    console.log(evt)
                    const fileLoader = evt.data.fileLoader;
                    const re = /image\/
                    if (re.test(fileLoader.file)) {
                        const xhr = fileLoader.xhr;
                        const formData = new FormData();
                        formData.append("file", fileLoader.file);
                        xhr.setRequestHeader('Authorization', 'Bearer '+  localStorage.getItem('Authorization'));
                        xhr.setRequestHeader('X-Authorization', 'Bearer '+  localStorage.getItem('Authorization'))
                        xhr.open('POST', fileUploadPath, true);
                        fileLoader.xhr.send(formData);
                        evt.stop();
                    }
                    else {
                        context.root.$message.error('仅支持上传图片')
                        alert('仅支持上传图片')
                        //fileLoader.update()
                        //evt.abort();
                        evt.stop();
                        evt.cancel();
                    }
                    
                }, null, null, 4)
                editor.value.on('fileUploadResponse', function(evt) {
                    evt.stop();
                    const data = evt.data;
                    console.log(data);
                    console.log(evt)
                }) 
            }
            else {

            }*/
            /* editorInsatnce.format_tags = 'img';
            editorInsatnce.config.format_img = { element: 'img',  attributes: { referrerPolicy: "no-referrer" } }  */
            editorInsatnce.document.referrerPolicy = 'no-referrer'
           // editorInsatnce.config.image2_captionedClass = 'image-captioned'
            editorInsatnce.on('fileUploadRequest', async function (evt) {
                evt.stop();
                const re = /image\*/;
                const fileLoader = evt.data.fileLoader;
                if (fileLoader.file.type.indexOf('image') !== -1) {
                    const isSizeLimit = (fileLoader.file.size / 1024 / 1024) <= 256;
                    if (isSizeLimit) {
                        const projectId = router.currentRoute.query.projectId;
                        const id = router.currentRoute.query.id;
                        const formData = new FormData();
                        if (fileLoader.file instanceof Blob) {
                            fileLoader.file = new File([fileLoader.file], `${(new Date()).getTime()}.${fileLoader.file.type.substring(fileLoader.file.type.indexOf('/') + 1).toLowerCase()}`, { type: fileLoader.file.type, lastModified: Date.now() })
                        }
                        formData.append("file", fileLoader.file);
                        const uploadRes = await uploadFileInExperiment({
                            projectId: projectId,
                            experimentId: id,
                            isPublicRead: true
                        }, formData);
                        if (uploadRes.code === 200) {
                            const xhr = fileLoader.xhr;
                            const path = `${projectId}/${id}/${uploadRes.data.fileName}`;
                            xhr.open('POST', `${fileUploadPath.value}?filePath=${encodeURI(path)}&isPublicRead=true&isPreview=false`, true);
                            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('Authorization'));
                            xhr.setRequestHeader('X-Authorization', 'Bearer ' + localStorage.getItem('X-Authorization'))
                            fileLoader.xhr.send();
                        }
                        else {
                            evt.data.fileLoader.message = uploadRes.message;
                            evt.data.fileLoader.fire('error');
                        }
                        /*   const xhr = fileLoader.xhr;
                          const formData = new FormData();
                          if(fileLoader.file instanceof Blob) {
                              fileLoader.file = new File([fileLoader.file], `${(new Date()).getTime()}.${fileLoader.file.type.substring(fileLoader.file.type.indexOf('/') + 1).toLowerCase()}`,  { type: fileLoader.file.type ,lastModified: Date.now()} )
                          }
                          formData.append("file", fileLoader.file);
                          xhr.open('POST', fileUploadPath.value, true);
                          xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('Authorization'));
                          xhr.setRequestHeader('X-Authorization', 'Bearer ' + localStorage.getItem('X-Authorization'))
                          fileLoader.xhr.send(formData); */
                    }
                    else {
                        evt.data.fileLoader.message = '图片大小不能超过256m';
                        evt.data.fileLoader.fire('error');
                        evt.cancel();
                    }
                }
                else {
                    evt.data.fileLoader.message = '仅允许上传图片';
                    evt.data.fileLoader.fire('error');
                    evt.cancel();
                }
            })
            editorInsatnce.on('fileUploadResponse', function (evt) {
                evt.stop();
                const projectId = router.currentRoute.query.projectId;
                const id = router.currentRoute.query.id;
                const data = evt.data;
                const responseData = JSON.parse(data.fileLoader.xhr.responseText);
                if (responseData.success) {
                    //const path = `${projectId}/${id}/${responseData.data}`;
                    //url.value = `https://saims.zkshare.com/file${path}`
                    data.url = responseData.data;
                }
                else {
                    data.message = responseData.message;
                    evt.cancel();
                }
            })
          /*   editorInsatnce.on('drop', function(evt) {
                console.log(evt)
                evt.stop();
            })
            editorInsatnce.on('drag', function(evt) {
                evt.stop();
            }) */
            /* editorInsatnce.on('paste', function(evt) {
                console.log(evt)
                if(evt.data && evt.data.method == 'drop') {
                    console.log(evt)
                    evt.cancel();
                }
            }) */
            editorInsatnce.on('drop', function(evt) {
                evt.cancel()
            },null, null, 1000)
            editorInsatnce.on('dragstart', function(evt) {
                evt.cancel()
            },null, null, 1000)
            editorInsatnce.on('dragend', function(evt) {
                evt.cancel()
            },null, null, 1000)
            loading.value = false;
        }

        const getImageUrl = async (path) => {
            const pathRes = await getFileUrl({
                filePath: path
            })
            if (pathRes.code === 200) {
                data.url = pathRes.data;
                console.log(data)
            }
            else {
                data.message = pathRes.message;
                evt.cancel();
            }
        }
        const url = ref('https://saims.zkshare.com/file341229342462021/341229420802117/3515176645345921217.png')

        const value = ref('')

        const onEditorInput = (value) => {
            context.emit('update:customData', value)
        }

        const onEditorFocus = () => {
            store.dispatch('experiment/setEditor', editor.value);
        }

        const onEditorBlur = () => {
            // store.dispatch('experiment/setEditor', null);
        }
        const getDateStamp = (format) => {
            return dayjs().format(format);
        }
        // 设置右键菜单时间戳选项
        const setContextMenu = (editorInsatnce) => {
            // 年月日 时分秒 时间戳
            editorInsatnce.addCommand('add YYYY-MM-DD  HH:mm:ss', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD  HH:mm:ss')}</p>`)
                }
            })
            editorInsatnce.addCommand('add YYYY-MM-DD  HH:mm', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD  HH:mm')}</p>`)
                }
            })
            editorInsatnce.addCommand('add YYYY-MM-DD', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD')}</p>`)
                }
            })
            editorInsatnce.addCommand('add HH:mm:ss', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('HH:mm:ss')}</p>`)
                }
            })
            editorInsatnce.addMenuGroup('timeStamp')

            editorInsatnce.addMenuItems({
                timeStamp_1: {
                    label: '年月日 时分秒',
                    command: 'add YYYY-MM-DD  HH:mm:ss',
                    group: 'timeStamp'
                },
                timeStamp_2: {
                    label: '年月日 时分',
                    command: 'add YYYY-MM-DD  HH:mm',
                    group: 'timeStamp'
                },
                timeStamp_3: {
                    label: '年月日',
                    command: 'add YYYY-MM-DD',
                    group: 'timeStamp'
                },
                timeStamp_4: {
                    label: '时分秒',
                    command: 'add HH:mm:ss',
                    group: 'timeStamp'
                }
            })

            editorInsatnce.addMenuItem('timeStamp', {
                label: '时间戳',
                group: 'timeStamp',
                getItems: function () {
                    return {
                        timeStamp_1: 2,
                        timeStamp_2: 2,
                        timeStamp_3: 2,
                        timeStamp_4: 2
                    }

                }
            })


            editorInsatnce.contextMenu.addListener(function (element) {
                // 如果是在图片上右键，不显示该右键菜单
                console.log(element.$.localName)
                if (element.$.localName !== "img") {
                    return {
                        timeStamp: 2
                    }
                }
            })
        }
        // 模板选择对话框可见变量
        const selectTemplateModalVisible = ref(false);

        // 模板选择回调事件
        const insertTemplateConfirm = (template) => {
            editor.value.insertHtml(`
            <br/>
            ${template}
            <br/>
            `)
            selectTemplateModalVisible.value = false;
        }

        // 添加插入模板可见变量
        const setInsertTemplateCommand = (editorInsatnce) => {
            editorInsatnce.addCommand('insertTemplate', {
                exec: function (editor) {
                    selectTemplateModalVisible.value = true;
                }
            })
        }

        // 插入模板
        const importConfirm = ( templateContent) => {
            editor.value.insertHtml(`<br/> ${templateContent} <br/>`);
            selectTemplateModalVisible.value = false;
        }
        return {
            onEditorReady,
            loading,
            onNamespaceLoaded,
            value,
            url,
            onEditorInput,
            onEditorFocus,
            onEditorBlur,
            selectTemplateModalVisible,
            insertTemplateConfirm,
            setInsertTemplateCommand,
            importConfirm
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .ant-message {
    z-index: 11000 !important;
}
.preview-content {
    padding: 10px 16px;
    /deep/ img, table {
        max-width: 100%;
    }
}

/deep/ .cke_button__itemplate_label {
    display: inline-block !important;
}
</style>