/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-1-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><clipPath id="svgicon_chat-1-fill_a"><rect pid="0" width="16" height="16" rx="0"/></clipPath></defs><g clip-path="url(#svgicon_chat-1-fill_a)"><path pid="1" d="M6.48.59h3.04a6.08 6.08 0 110 12.16v2.66C5.72 13.89.4 11.61.4 6.67A6.08 6.08 0 016.48.59z"/></g>'
  }
})
