/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<clipPath id="svgicon_undo_a"><rect pid="0" width="24" height="24" rx="0"/></clipPath><g clip-path="url(#svgicon_undo_a)"><path pid="1" d="M8 7v4L2 6l6-5v4h5a8 8 0 110 16H4v-2h9a6 6 0 000-12H8z" _fill="#4E5969"/></g>'
  }
})
