<template>
    <div class="table-container">
        <div class="btns">
            <div v-if="type === `template` && mode === 'edit'" class="left-btns">
                <a-tooltip class="operation-btn" title="模板无法进行新增">
                    <a-button disabled size="small" v-show="mode === 'edit'"> + 新增 </a-button>
                </a-tooltip>
                <a-tooltip style="margin-left: 15px;" class="operation-btn" title="模板无法进行导入">
                    <a-button disabled size="small" v-show="mode === 'edit'"> 导入 </a-button>
                </a-tooltip>
            </div>
            <div v-else class="left-btns">
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleChange"> + 新增
                </a-button>
                <a-button size="small" class="operation-btn" v-show="mode === 'edit'" @click="handleImportClick"> 导入
                </a-button>
            </div>
            <a-button size="small" type="danger" v-show="mode === 'edit'" @click="handleDeleteButtonClick"
                :disabled="!selectedRowKeys.length"> 删除
            </a-button>
        </div>
        <a-table rowKey="index" size="small" :columns="columns" :data-source="customData" :pagination="false" bordered
            :row-selection="mode === 'edit' ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : null">
            <template
                v-for="(col, columIndex) in ['name', 'density', 'usage', 'casNumber', 'specification', 'structuralFormula', 'storageLocation']"
                :slot="col" slot-scope="text, record, index">
                <div v-if="['date'].indexOf(col) !== -1" :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'"
                        :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="handleTextClick(col, columIndex, index)">
                        {{ customData[index].date }}
                    </div>
                    <div v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <!--a-input autoFocus v-model="customData[index][col]"  @blur="handleBlur">
                        </a-input-->
                        <a-date-picker valueFormat="YYYY-MM-DD" autoFocus v-model="customData[index].date"
                            :ref="`${col}${index}`" @blur="handleBlur" />
                    </div>
                </div>
                <div v-if="['structuralFormula'].indexOf(col) !== -1" :key="col">
                    <imageUploader :imgUrl.sync="record.structuralFormula" :record.sync="record" :mode="mode">
                    </imageUploader>
                </div>
                <div v-else :key="col">
                    <div v-show="!isInputShow(index, columIndex) || mode !== 'edit'"
                        :class="[`table-cell`, mode === 'edit' ? 'edit-cell' : '']"
                        @click="($event) => handleHiddenInputClick(col, columIndex, index, $event)">
                        {{ customData[index][col] }}
                    </div>
                    <div v-show="isInputShow(index, columIndex) && mode === 'edit'">
                        <a-input autoFocus @contextmenu.prevent="getConextMenu" v-model="customData[index][col]"
                            :ref="`${col}${index}`" @focus="hiddenInputFocus" @blur="handleBlur">
                        </a-input>
                    </div>
                </div>
            </template>
            <!-- <template slot="date" slot-scope="text, record, index">
                <div v-show="!isInputShow(index, 2) || mode !== 'edit'" class="table-cell"
                    @click="handleTextClick('date', 2, index)">
                    {{ customData[index].date }}
                </div>
                <div v-show="isInputShow(index, 2) && mode === 'edit'">
                    <a-date-picker valueFormat="YYYY-MM-DD" autoFocus v-model="customData[index].date"
                        :ref="`date${index}`"  @blur="handleBlur" />
                </div>
            </template> -->
        </a-table>
        <ImportExperimentMaterialModal :visible.sync="importExperimentMaterialModalVisible"
            @importConfirm="handleImportConfirm"></ImportExperimentMaterialModal>
    </div>
</template>

<style lang="less" scoped>
.table-container {
    background: #FFFFFF;

    .btns {
        padding: 0px 8px 10px 8px;
        display: flex;

        .ant-btn {
            width: 58px;
        }

        .operation-btn {
            background: #eeeeee;
        }

        .left-btns {
            width: 0px;
            flex-grow: 1;
        }

        .ant-btn+.ant-btn {
            margin-left: 15px;
        }
    }

    .table-cell {
        border: 1px solid transparent;
        min-height: 32px;
        border-radius: 4px;
        cursor: text;
        padding: 7px 12px;
    }

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }

}
</style>

<script>
import { getConextMenu, hiddenInputFocus, inputBlur } from '../../utils';
import imageUploader from '../../../../components/imageUploader.vue';
import ImportExperimentMaterialModal from './components/importExperimentMaterialModal.vue';


export default {
    props: ["record", "value", "disabled", "dynamicData", "customData", "mode", "type"],
    // props: {
    //   record: {
    //     type: Object,
    //     require: true
    //   },
    //   value: {
    //     type: String,
    //     default: ''
    //   }
    // },
    data: () => {
        return {
            getConextMenu,
            hiddenInputFocus,
            columns: [
                {
                    dataIndex: "name",
                    title: "名称",
                    width: "15%",
                    scopedSlots: { customRender: "name" }
                },
                {
                    dataIndex: "density",
                    title: "密度",
                    width: "11%",
                    scopedSlots: { customRender: "density" }
                },
                {
                    dataIndex: "usage",
                    title: "用量",
                    width: "13%",
                    scopedSlots: { customRender: "usage" }
                },
                {
                    dataIndex: "casNumber",
                    title: "CAS号",
                    width: "15%",
                    scopedSlots: { customRender: "casNumber" }
                },
                {
                    dataIndex: "specification",
                    title: "规格",
                    width: "10%",
                    scopedSlots: { customRender: "specification" }
                },
                {
                    dataIndex: "structuralFormula",
                    title: "结构式",
                    width: "15%",
                    scopedSlots: { customRender: "structuralFormula" }
                },
                {
                    dataIndex: "storageLocation",
                    title: "存放位置",
                    width: "18%",
                    scopedSlots: { customRender: "storageLocation" }
                }
            ],
            isEditting: false,
            rowIndex: null,
            colIndex: null,
            selectedRowKeys: [], // 选中行的key 列表
            importExperimentMaterialModalVisible: false  // 导入对话框
        };
    },
    methods: {
        handleChange() {
            this.customData.push({
                index: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index + 1,
                key: this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].key + 1,
                name: "",
                density: "",
                dosage: "",
                cas: "",
                spec: "",
                location: "",
                structuralFormula: ""
            });
        },
        // 其他隐藏组件点击事件
        handleTextClick(col, columIndex, index) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            //const that = this;
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 隐藏的输入框点击事件
        handleHiddenInputClick(col, columIndex, index, e) {
            this.isEditting = true;
            this.rowIndex = index;
            this.colIndex = columIndex;
            this.$store.dispatch("experiment/setText", e.target);
            this.$nextTick(() => {
                const ref = this.$refs[`${col}${index}`];
                ref.length && ref[0].focus();
            });
        },
        // 输入框隐藏事件
        isInputShow(index, columIndex) {
            return this.isEditting && this.rowIndex === index && this.colIndex === columIndex;
        },
        // 输入框失焦失焦， 恢复label形式
        handleBlur() {
            this.rowIndex = null;
            this.colIndex = null;
            this.isEditting = false;
            inputBlur();    // 时间戳不可选
        },
        // 选择改变
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        // 确认删除点击
        handleDeleteButtonClick() {
            /* this.$confirm({
                title: '是否确认删除？',
                onOk() {
                    return new Promise((resolve, reject) => {
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    }).catch(() => console.log('Oops errors!'));
                },
                onCancel() { },
            }); */
            const temp = this.customData.filter((Element) => {
                return this.selectedRowKeys.indexOf(Element.index) === -1;
            });
            this.$emit("customDataChange", temp);
            this.selectedRowKeys = [];
        },
        // 导入点击事件
        handleImportClick() {
            this.importExperimentMaterialModalVisible = true;
        },
        // 导入确认事件
        handleImportConfirm(rows) {
            const index = this.customData.length === 0 ? 0 : this.customData[this.customData.length - 1].index;
            for (let i = 0; i < rows.length; ++i) {
                if (rows[i].structuralFormula) {
                    const file = this.base64ToBlob(rows[i].structuralFormula, rows[i].id + '.jpeg');
                    console.log(window.URL.createObjectURL(file))
                    console.log(file)
                    this.customData.push({
                        index: index + i + 1,
                        ...JSON.parse(JSON.stringify(rows[i]), rows[i].id),
                        File: file,
                        FileName: file.name,
                        FileStatu: 'ready',
                        structuralFormula: window.URL.createObjectURL(file),
                        progressPercent: 0,
                    });
                }
                else {
                    this.customData.push({
                        index: index + i + 1,
                        ...JSON.parse(JSON.stringify(rows[i]), rows[i].id),
                    });
                }

            }
        },
        // base64转blob
        base64ToBlob(base64Data ,fileName) {
            //let arr = base64Data.split(','),
            //fileType = arr[0].match(/:(.*?);/)[1],
            let bstr = window.atob(base64Data);
            let l = bstr.length;
            let u8Arr = new Uint8Array(l);
            while (l--) {
                u8Arr[l] = bstr.charCodeAt(l);
            }
            return new File([u8Arr], fileName, {
                type: 'image/jpeg',
                lastModified: new Date()
            });
        },
        // blob转file
        blobToFile(newBlob, fileName) {
            newBlob.lastModifiedDate = new Date();
            newBlob.name = fileName;
            return newBlob;
        },
    },
    components: { imageUploader, ImportExperimentMaterialModal }
};
</script>